import { get, set } from 'lodash';

const validator = 
  (schema: any) => 
    (values: any) =>
      schema.validate(
        values,
        { abortEarly: false }
      ).catch((error: Error) => {
        const errors = {};
        get(error, 'inner', []).forEach(({
          message,
          path
        }: {
          message: string,
          path: string
        }): void => {
          set(errors, path, message);
        });
        throw errors;
      });

export default validator;
