import { useEffect, useContext, useState } from 'react';
import { useMutation, useLazyQuery } from 'react-apollo';
// import validator from 'validator';
import { get, capitalize, join, toUpper } from 'lodash';
import { loader } from 'graphql.macro';
import moment from 'moment';
import json5 from 'json5';

// Hooks
import { EFOContext } from '../../../../hooks';

// Config
import { SERVER_ERRORS_HANDLER, ERROR_CONNECTION, MESSAGES_MAP } from 'config';

// Types
import { SIGN_IN_TYPE, SESSION_TYPE } from 'services/session';

// GraphQL
const EFOQuery = loader('src/graphql/queries/efo.gql');
const EFOMutation = loader('src/graphql/mutations/efo.gql');

type USE_STEP_PROPS_TYPE = {
  session?: SESSION_TYPE,
  formValues?: object,
  signIn: SIGN_IN_TYPE,
  reset: () => void,
  initialize: (data: object) => void,
};

export const useStep = ({
  session,
  formValues,
  signIn,
  reset,
  initialize,
}: USE_STEP_PROPS_TYPE) => {
  const [ editableData, setEditableData] = useState({});

  const {
    id,
    isNew,
    loading,
    step,
    values,
    finished,
    limit,
    error,
    fail,
    handleValues,
    handleBack,
    handleNext,
    handleError,
    handleFail,
    handleLoading,
    role,
  } = useContext(EFOContext);

  const [
    handleEFOMutation,
    EFOMutationResult
  ] = useMutation(
    EFOMutation,
    {
      fetchPolicy: 'no-cache',
      onError: (error: any) => SERVER_ERRORS_HANDLER(error, (error?: any) => handleFail(!!error))
    }
  );

  const { loading: EFOMutationLoading, data: EFOMutationData, error: EFOMutationError } = EFOMutationResult;

  const [
    handleEFOQuery,
    EFOQueryResult
  ] = useLazyQuery(
    EFOQuery,
    {
      fetchPolicy: 'no-cache',
      onError: (error: any) => SERVER_ERRORS_HANDLER(error, (error?: any) => handleFail(!!error))
    }
  );

  const { loading: EFOQueryLoading, data: EFOQueryData, error: EFOQueryError } = EFOQueryResult;
  console.log('EFOQueryError', EFOQueryError)
  
  useEffect(() => {
    editableData && initialize({
      ...editableData,
      specialists: get(editableData, 'specialists', []).map((item: object, index: number) => ({
        ...item,
        value: get(item, 'id'),
        title: `${get(item, 'surname')} ${toUpper(get(item, 'name', '')).substr(0, 1)}. ${toUpper(get(item, 'patronymic', '')).substr(0, 1)}. (${get(item, 'position', 'не указано') || 'не указано'})`,
        // title: `${get(item, `surname`)} ${capitalize(get(item, `name`).substr(0,1))}. ${capitalize(get(item, `patronymic`).substr(0,1))}.`
      })),
      client: {
        ...get(editableData, 'client', {}),
        value: get(editableData, 'client.id'),
        title: `${get(editableData, 'client.surname', '')} ${toUpper(get(editableData, 'client.name', '')).substr(0, 1)}. ${toUpper(get(editableData, 'client.patronymic', '')).substr(0, 1)}.`
      },
      other_children: json5.parse(get(editableData, 'other_children') || '[]'),
      other_significant_people: json5.parse(get(editableData, 'other_significant_people') || '[]'),
      educational_institutions: json5.parse(get(editableData, 'educational_institutions') || '[]'),
      contacts_log: json5.parse(get(editableData, 'contacts_log') || '[]'),
      other_specialists: json5.parse(get(editableData, 'other_specialists')  || '[]'),
      other_organisations_evaluation_review: json5.parse(get(editableData, 'other_organisations_evaluation_review') || '[]'),
      // ...(get(editableData, 'client.birthday') && {
      //   client_fullname: `${get(editableData, 'client.surname', '')} ${toUpper(get(editableData, 'client.name', '')).substr(0, 1)}. ${toUpper(get(editableData, 'client.patronymic', '')).substr(0, 1)}.`,
      //   client_birthday: get(editableData, 'client.birthday'),
      //   client_address: get(editableData, 'client.address'),
      //   client_phone: get(editableData, 'client.phone'),
      //   client_registration_address: get(editableData, 'client.registration_address'),
      // })
      // login: get(editableData, 'phone') || get(editableData, 'email'),
      // birthday: moment(get(editableData, 'birthday', '01-01-1900'), 'YYYY-MM-DD'),
      // role: get(editableData, 'roles[0].name', role !== 'efo' ? role : 'client'),
    });
  }, [editableData]);

  useEffect(() => {
    !isNew ? handleEFOQuery({ variables: { id } }) : reset();
  }, [id]);

  useEffect(() => {
    handleLoading(!!EFOMutationLoading || !!EFOQueryLoading);
  }, [EFOMutationLoading, EFOQueryLoading]);

  useEffect(() => {
    if (!EFOQueryData || loading) {
      return;
    }
    const EFO = get(EFOQueryData, 'efo.data[0]');
    const isError = get(EFOQueryData, 'efo.__typename') === 'Error';
    const message = get(EFOQueryData, 'efo.message');

    const dataError = isError && get(MESSAGES_MAP, `${message}`, message);
    const currentErrors = [
      ...(EFOQueryError ? [ERROR_CONNECTION] : []),
      ...(dataError ? [dataError] : []),
    ];
    currentErrors.length <= 0
      ? setEditableData(EFO)
      : handleError(join(currentErrors, '\n'));
  }, [loading, EFOQueryData, EFOQueryError]);

  useEffect(() => {
    if (!EFOMutationData || loading) {
      return;
    }
    const isError = get(EFOMutationData, 'EFO.__typename') === 'Error';
    const message = get(EFOMutationData, 'EFO.message');

    const dataError = isError && get(MESSAGES_MAP, `${message}`, message);
    const currentErrors = [
      ...(EFOMutationError ? [ERROR_CONNECTION] : []),
      ...(dataError ? [dataError] : []),
    ];
    currentErrors.length <= 0
      ? handleNext({ errors: false })
      : handleError(join(currentErrors, '\n'));
  }, [loading, EFOMutationData, EFOMutationError]);

  const handleSubmit = (event: React.SyntheticEvent) => {
    // get(event, 'preventDefault') && event.preventDefault();
    console.log('check')

    const organisation_name = capitalize(get(formValues, 'organisation_name', get(values, 'organisation_name', '')));
    const start_evaluation = get(formValues, 'start_evaluation', get(values, 'start_evaluation', ''));
    const end_evaluation = get(formValues, 'end_evaluation', get(values, 'end_evaluation', ''));
    const specialists = get(formValues, 'specialists', get(values, 'specialists', [])).map((item: object) => get(item, 'id'));
    const client_id = get(formValues, 'client.id', get(values, 'client.id', ''));

    const first_parent_fullname = get(formValues, 'first_parent_fullname', get(values, 'first_parent_fullname', ''));
    const first_parent_birthday = get(formValues, 'first_parent_birthday', get(values, 'first_parent_birthday', ''));
    const first_parent_address = get(formValues, 'first_parent_address', get(values, 'first_parent_address', ''));
    const first_parent_phone = get(formValues, 'first_parent_phone', get(values, 'first_parent_phone', ''));
    const first_parent_registration_address = get(formValues, 'first_parent_registration_address', get(values, 'first_parent_registration_address', ''));

    const second_parent_fullname = get(formValues, 'second_parent_fullname', get(values, 'second_parent_fullname', ''));
    const second_parent_birthday = get(formValues, 'second_parent_birthday', get(values, 'second_parent_birthday', ''));
    const second_parent_address = get(formValues, 'second_parent_address', get(values, 'second_parent_address', ''));
    const second_parent_phone = get(formValues, 'second_parent_phone', get(values, 'second_parent_phone', ''));
    const second_parent_registration_address = get(formValues, 'second_parent_registration_address', get(values, 'second_parent_registration_address', ''));

    const life_circumstances = get(formValues, 'life_circumstances', get(values, 'life_circumstances', ''));

    const stepValues = {
      ...(isNew ? { new: true } : { id }),
      organisation_name,
      start_evaluation: start_evaluation && moment(start_evaluation, 'DD-MM-YYYY').format('YYYY-MM-DD'),
      end_evaluation: end_evaluation && moment(end_evaluation, 'DD-MM-YYYY').format('YYYY-MM-DD'),
      specialists: {
        sync: specialists
      },
      client_id,
      first_parent_fullname,
      first_parent_birthday: first_parent_birthday && moment(first_parent_birthday, 'DD-MM-YYYY').format('YYYY-MM-DD'),
      first_parent_address,
      first_parent_phone,
      first_parent_registration_address,
      second_parent_fullname,
      second_parent_birthday: second_parent_birthday && moment(second_parent_birthday, 'DD-MM-YYYY').format('YYYY-MM-DD'),
      second_parent_address,
      second_parent_phone,
      second_parent_registration_address,
      life_circumstances
    };
    handleValues({
      ...stepValues,
    });
    return handleEFOMutation({ variables: { ...stepValues } });
  };

  return {
    loading,
    step,
    finished,
    limit,
    error,
    fail,
    handleBack,
    handleNext,
    handleError,
    handleFail,
    handleLoading,
    handleSubmit,
    role,
  };
};

export default useStep;
