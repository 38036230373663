// Libraries
import React from 'react';
import {useSelector} from "react-redux";
import {Redirect} from "react-router-dom";

// Components
import { Page } from 'components/Page';

// Styles
import styles from './Main.module.scss';

// Types
import {RootStore} from "types";

const Main = (): React.ReactElement<typeof Page> => {
  const token = useSelector<RootStore>((state) => state.session.token);

  return (
    <Page
      classes={{
        root: styles.Root,
        container: styles.Container
      }}
    >
      {
        token ? null : (
          <Redirect to="/auth/sign-in" />
        )
      }
    </Page>
  )
}

// Exports
export default Main;
