// Types
import { Question } from "types";

export const questions: Question[] = [
  {
    statement: 'Я счастлив быть родителем.'
  },
  {
    statement: 'Нет ничего или почти ничего, что я бы не сделал для своих детей, если бы в этом была необходимость.'
  },
  {
    statement: 'Воспитание ребенка (детей) иногда требует больше времени и сил, чем у меня есть.'
  },
  {
    statement: 'Иногда я переживаю, достаточно ли я делаю для своих детей.'
  },
  {
    statement: 'Я получаю удовольствие от совместного времяпрепровождения со своими детьми.'
  },
  {
    statement: 'Мои дети – важный источник любви.'
  },
  {
    statement: 'Наличие детей дает мне более четкий и оптимистичный взгляд на будущее.'
  },
  {
    statement: 'Основной источник стресса в моей жизни – это мои дети.'
  },
  {
    statement: 'Из-за детей у меня мало времени и возможности им распоряжаться.'
  },
  {
    statement: 'Дети – это финансовое бремя.'
  },
  {
    statement: 'Из-за детей мне трудно совмещать разные обязанности.'
  },
  {
    statement: 'Я часто испытываю неловкость и стресс из-за поведения своих детей.'
  },
  {
    statement: 'Если бы мне пришлось сделать это снова, возможно, я решил бы не иметь детей.'
  },
  {
    statement: 'Я чувствую себя перегруженным из-за того, что я исполняю обязанности родителя.'
  },
  {
    statement: 'Иметь детей означает слишком мало выбора и слишком мало контроля над своей жизнью.'
  },
  {
    statement: 'Я удовлетворен как родитель.'
  },
  {
    statement: 'Мои дети меня радуют.'
  },
];
