// Libraries
import { v4 as uuidv4 } from 'uuid';

export const CLIENTS_STATUSES = [
  {
    id: uuidv4(),
    name: 'родитель',
    value: 'parent'
  },
  {
    id: uuidv4(),
    name: 'доверенное лицо',
    value: 'confidant'
  }
];

export const GENDERS = [
  {
    id: uuidv4(),
    name: 'мужской',
    value: 'male'
  },
  {
    id: uuidv4(),
    name: 'женский',
    value: 'female'
  }
];

export const ROLES = [
  {
    id: 1,
    title: 'Клиент',
    value: 'client'
  },
  {
    id: 2,
    title: 'Специалист',
    value: 'specialist'
  },
  {
    id: 3,
    title: 'Администратор',
    value: 'administrator'
  },
  {
    id: 4,
    title: 'Другие лица',
    value: 'others'
  }
];

const INITIAL_STATE = {
  genders: GENDERS,
  roles: ROLES,
  clientsStatuses: CLIENTS_STATUSES
};

export default INITIAL_STATE;
