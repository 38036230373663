// Libraries
import React, { Fragment } from 'react';
import { v4 as uuidv4 } from 'uuid';

// Components
import {H1, H5, Subtitle} from "components/Typography";
import {Paper} from "@material-ui/core";
import {Input, Select} from "components/Form";
import AnalysisFields from "../../AnalysisFields/AnalysisFields";

// Styles
import styles from '../styles.module.scss';

type FAMILY_PROPS_TYPE = {
  error: any,
};

const DATA = ({ error }: FAMILY_PROPS_TYPE): React.ReactElement<typeof Fragment> => (
  <div className={styles.FormSegment}>
    <H1
      className={styles.Title}
    >
      Отношения в семье и обществе
    </H1>
    <H5
      className={styles.H5}
    >
      Потребности ребенка
    </H5>
    <Paper className={styles.Paper}>
      <div className={styles.QuestionContainer}>
        <Subtitle>Стабильно ли социальное окружение ребенка? (ограниченное число знакомых взрослых)</Subtitle>
        <Select
          className={styles.QuestionContainerSelect}
          name="social_environment"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="social_environment_description"
          multiline
          rowsMax={4}
          label="Комментарий"
          placeholder="Комментарий"
        />
      </div>
      <div className={styles.QuestionContainer}>
        <Subtitle>Чувствует ли ребенок себя свободно в присутствии членов семьи?</Subtitle>
        <Select
          className={styles.QuestionContainerSelect}
          name="sense_freedom"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="sense_freedom_description"
          multiline
          rowsMax={4}
          label="Комментарий"
          placeholder="Комментарий"
        />
      </div>
      <div className={styles.QuestionContainer}>
        <Subtitle>Водят ли ребенка в гости к членам семьи, живущим отдельно?</Subtitle>
        <Select
          className={styles.QuestionContainerSelect}
          name="taken_visit"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="taken_visit_description"
          multiline
          rowsMax={4}
          label="Комментарий"
          placeholder="Комментарий"
        />
      </div>
      <div className={styles.QuestionContainer}>
        <Subtitle>Принимает ли ребенок участие в уходе за домашними животными?</Subtitle>
        <Select
          className={styles.QuestionContainerSelect}
          name="pet_care"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="pet_care_description"
          multiline
          rowsMax={4}
          label="Комментарий"
          placeholder="Комментарий"
        />
      </div>
      <div className={styles.QuestionContainer}>
        <Subtitle>Является ли ребенок свидетелем или вовлекается в семейные конфликты/насилие?</Subtitle>
        <Select
          className={styles.QuestionContainerSelect}
          name="family_conflicts"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="family_conflicts_description"
          multiline
          rowsMax={4}
          label="Комментарий"
          placeholder="Комментарий"
        />
      </div>
      <div className={styles.QuestionContainer}>
        <Subtitle>Есть ли взрослый, которому ребенок доверяет, рассказывая о своих чувствах?</Subtitle>
        <Select
          className={styles.QuestionContainerSelect}
          name="talking_feelings"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="talking_feelings_description"
          multiline
          rowsMax={4}
          label="Комментарий"
          placeholder="Комментарий"
        />
      </div>
    </Paper>
    <H5
      className={styles.H5}
    >
      Возможности родителей/лиц, их заменяющих
    </H5>
    <Paper className={styles.Paper}>
      <div className={styles.QuestionContainer}>
        <Subtitle>Играют ли родители с ребенком?</Subtitle>
        <Select
          className={styles.QuestionContainerSelect}
          name="game_with_child"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="game_with_child_description"
          multiline
          rowsMax={4}
          label="Комментарий"
          placeholder="Комментарий"
        />
      </div>
      <div className={styles.QuestionContainer}>
        <Subtitle>Достаточны ли телесные контакты ребенка с родителями?</Subtitle>
        <Select
          className={styles.QuestionContainerSelect}
          name="skin_contact"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="skin_contact_description"
          multiline
          rowsMax={4}
          label="Комментарий"
          placeholder="Комментарий"
        />
      </div>
      <div className={styles.QuestionContainer}>
        <Subtitle>Есть ли у семьи поддержка социальной сети? (родственники, друзья, социальные службы и др.)</Subtitle>
        <Select
          className={styles.QuestionContainerSelect}
          name="social_network_support"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="social_network_support_description"
          multiline
          rowsMax={4}
          label="Комментарий"
          placeholder="Комментарий"
        />
      </div>
      <div className={styles.QuestionContainer}>
        <Subtitle>Был ли у родителей положительный опыт детства, на который они ориентируются при воспитании?</Subtitle>
        <Select
          className={styles.QuestionContainerSelect}
          name="positive_childhood_experience"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="positive_childhood_experience_description"
          multiline
          rowsMax={4}
          label="Учитывайте наличие у родителей опыта жестокого обращения в детстве, воспитания в интернатном
          учреждении, наличие у родителя психического расстройства, регулярные ссоры в семье и пр."
          placeholder="Учитывайте наличие у родителей опыта жестокого обращения в детстве, воспитания
          в интернатном учреждении, наличие у родителя психического расстройства, регулярные ссоры в семье и пр."
        />
      </div>
      <div className={styles.QuestionContainer}>
        <Subtitle>Была ли в семье травмирующая потеря или кризис за последние полгода?</Subtitle>
        <Select
          className={styles.QuestionContainerSelect}
          name="traumatic_loss"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="traumatic_loss_description"
          multiline
          rowsMax={4}
          label="Если ДА: справилась ли семья, требуется ли поддержка?"
          placeholder="Если ДА: справилась ли семья, требуется ли поддержка?"
        />
      </div>
      <div className={styles.QuestionContainer}>
        <Subtitle>Согласованный ли подход к воспитанию и дисциплине у взрослых, осуществляющих уход за ребенком?</Subtitle>
        <Select
          className={styles.QuestionContainerSelect}
          name="consistent_approach"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="consistent_approach_description"
          multiline
          rowsMax={4}
          label="Комментарий"
          placeholder="Комментарий"
        />
      </div>
      <div className={styles.QuestionContainer}>
        <Subtitle>Есть ли между членами семьи доверительные отношения?</Subtitle>
        <Select
          className={styles.QuestionContainerSelect}
          name="trusting_relationship"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="trusting_relationship_description"
          multiline
          rowsMax={4}
          label="Комментарий"
          placeholder="Комментарий"
        />
      </div>
      <div className={styles.QuestionContainer}>
        <Subtitle>Есть ли у семьи общие интересы, увлечения?</Subtitle>
        <Select
          className={styles.QuestionContainerSelect}
          name="common_interests_family"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="common_interests_family_description"
          multiline
          rowsMax={4}
          label="Комментарий"
          placeholder="Комментарий"
        />
      </div>
      <div className={styles.QuestionContainer}>
        <Subtitle>Умеют ли родители использовать ресурсы семьи и общества?</Subtitle>
        <Select
          className={styles.QuestionContainerSelect}
          name="use_family_resources"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="use_family_resources_description"
          multiline
          rowsMax={4}
          label="Комментарий"
          placeholder="Комментарий"
        />
      </div>
      <div className={styles.QuestionContainer}>
        <Subtitle>Поддерживает ли семья взаимоотношения с соседями?</Subtitle>
        <Select
          className={styles.QuestionContainerSelect}
          name="relationships_with_neighbors"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="relationships_with_neighbors_description"
          multiline
          rowsMax={4}
          label="Комментарий"
          placeholder="Комментарий"
        />
      </div>
    </Paper>
    <AnalysisFields title="Отношения в семье и в обществе" name="community_relationships" />
  </div>
);

const FAMILY = {
  id: uuidv4(),
  name: 'Отношения в семье',
  data: DATA,
};

export default FAMILY;
