// Libraries
import React from 'react';

// Components
import { Page } from 'components/Page';
import { H1 } from 'components/Typography';
// Styles
import styles from './NotFound.module.scss';

const NotFound = (): React.ReactElement<typeof Page> => (
  <Page
    classes={{
      root: styles.Root,
      container: styles.Container
    }}
  >
    <H1>
      Страница не найдена, попробуйте воспользоваться меню или поиском.
    </H1>
  </Page>
);

// Exports
export default NotFound;
