// Libraries
import * as yup from 'yup';

import { asyncValidator } from 'components/Form';

// Config
import { ERROR_FIELD_REQUIRED, ERROR_PASSWORD_MIN, ERROR_FIELD_NUMBER, ERROR_PASSWORD_EQUAL } from 'config';

export const validate = (values: object) => ({});

export const asyncValidate = asyncValidator(
  yup.object().shape({
    password: yup
      .string()
      .min(3, ERROR_PASSWORD_MIN)
      .required(ERROR_FIELD_REQUIRED),
    // .max(32)
    confirmation: yup
      .string()
      .min(3, ERROR_PASSWORD_MIN)
      .oneOf([yup.ref('password'), null], ERROR_PASSWORD_EQUAL)
      .required(ERROR_FIELD_REQUIRED),
    // .max(32)
    pin: yup.number().typeError(ERROR_FIELD_NUMBER).required(ERROR_FIELD_REQUIRED)
  })
);
