// Libraries
import * as React from 'react';

// Components
import Link from 'components/Link';

// Styles
import styles from './Timer.scss';

// Hooks
import { useTimer } from './hooks';

type TIMER_PROPS_TYPE = {
  className?: string,
  onRefetch: Function,
  title?: string
};

const Timer = ({
  className,
  onRefetch,
  title = 'Отправить код еще раз'
}: TIMER_PROPS_TYPE) => {
  const { refetchTime, handleRefetch } = useTimer({
    onRefetch
  });

  const status = localStorage.getItem('blockStatus');

  return (
    <div className={className}>
      {status && status === 'too_many_attempts' ? (
        <span>Запросить код повторно можно через 15 минут</span>
      ) : refetchTime <= 0 ? (
        <Link className={styles.Link} onClick={handleRefetch}>
          {title}
        </Link>
      ) : (
        <span>{`Запросить код повторно можно через ${refetchTime} сек`}</span>
      )}
    </div>
  );
};

// Exports
export default Timer;
