import { useEffect, useContext } from 'react';
import { useMutation } from 'react-apollo';
import validator from 'validator';
import { get, replace, join } from 'lodash';
import { loader } from 'graphql.macro';

// Services
import { SIGN_IN_TYPE } from 'services/session';

// Hooks
import { SignInContext } from '../../../../hooks';

// Config
import { SERVER_ERRORS_HANDLER, ERROR_CONNECTION, MESSAGES_MAP } from 'config';

// GraphQL
const signInQuery = loader('src/graphql/mutations/signIn.gql');

type USE_STEP_PROPS_TYPE = {
  formValues?: object,
  signIn: SIGN_IN_TYPE,
};

export const useStep = ({
  formValues,
  signIn
}: USE_STEP_PROPS_TYPE) => {

  const usedSignInContext = useContext(SignInContext);
  const {
    loading,
    values,
    handleNext,
    handleError,
    handleFail,
    handleLoading,
    handleValues
  } = usedSignInContext;

  const [
    handleSignInQuery,
    signInQueryResult
  ] = useMutation(
    signInQuery,
    {
      fetchPolicy: 'no-cache',
      onError: (error: any) => SERVER_ERRORS_HANDLER(error, (error?: any) => handleFail(!!error))
    }
  );

  const { loading: signInQueryLoading, data: signInQueryData, error: signInQueryError } = signInQueryResult;

  useEffect(() => {
    handleLoading(!!signInQueryLoading);
  }, [signInQueryLoading]);

  useEffect(() => {
    if (!signInQueryData || loading) {
      return;
    }
    const user = get(signInQueryData, 'signIn.user');
    const token = get(signInQueryData, 'signIn.token');
    const isError = get(signInQueryData, 'signIn.__typename') === 'Error';
    const message = get(signInQueryData, 'signIn.message');
    const remember = get(
      formValues,
      'remember',
      get(values, 'remember', false)
    );

    if (token) { 
      signIn({ ...user, token, ...(remember ? { expire: 0 } : {}) });
    }

    const dataError = isError && get(MESSAGES_MAP, `${message}`, message);
    const currentErrors = [
      ...(signInQueryError ? [ERROR_CONNECTION] : []),
      ...(dataError ? [dataError] : []),
    ];

    currentErrors.length <= 0
      ? handleNext({ error: false })
      : handleError(join(currentErrors, '\n'));

  }, [loading, signInQueryData, signInQueryError]);

  const handleSubmit = (event: React.SyntheticEvent) => {
    get(event, 'preventDefault') && event.preventDefault();
    const login = replace(
      get(formValues, 'login', get(values, 'login')),
      / /g,
      ''
    );
    const password = get(formValues, 'password', get(values, 'password', ''));
    const remember = get(formValues, 'remember', get(values, 'remember', false));
    const isPhone =
      login && validator.isMobilePhone(login, 'ru-RU', { strictMode: false });
    
    const stepValues = {
      [isPhone ? 'phone' : 'email'] : login,
      password,
      remember
    };
    handleValues({ ...stepValues, login });
    return handleSignInQuery({ variables: { ...stepValues } });
  };

  return {
    ...usedSignInContext,
    handleSubmit
  };
};

export default useStep;
