// Libraries

export const PATHS_MAP = {
  '/users': {
    role: 'user',
    title: 'Пользователь',
  },
  '/administrators': {
    role: 'administrator',
    title: 'Администратор',
  },
  '/specialists': {
    role: 'specialist',
    title: 'Специалист',
  },
  '/clients': {
    role: 'client',
    title: 'Клиент',
  },
};
