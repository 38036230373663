// Libraries
import React from 'react';

// Styles
import styles from './Wizard.module.scss';

// Components
import Step0 from './components/Step0';
import Finish from './components/Finish';
import Fail from './components/Fail';

// Hooks
import { EFOContext } from '../../hooks';

const STEPS = [Step0];

const Wizard = () => {
  const { step, finished, fail } = React.useContext(EFOContext);
  const Step = STEPS[step];
  return (
    <div className={styles.Root}>
      {
        fail ?
          <Fail /> :
          finished ?
            <Finish /> :
            Step && <Step />
      }
    </div>
  );
};

// Exports
export default Wizard;
