import { useState, useEffect } from 'react';
import { get, isFunction } from 'lodash';
import Cookies from 'js-cookie';

const REFETCH_LIMIT = 60;
const TICK = 1000;

const timeCalc = (start: number): number => {
  const result = Math.ceil(REFETCH_LIMIT - (Date.now() - start) * 0.001);
  return result > 0 ? result : 0;
};

export const useTimer = ({ onRefetch }: { onRefetch?: Function}) => {
  const [startTime, setStartTime] = useState(
    parseInt(`${Cookies.get('pin-time')}`, 10)
  );
  const [refetchTime, setRefetchTime] = useState(timeCalc(startTime));

  useEffect(() => {
    const refetchIntervalId = setInterval(() => {
      const incrTime = timeCalc(startTime);
      if (incrTime <= 0) {
        clearInterval(refetchIntervalId);
        setRefetchTime(0);
      } else {
        setRefetchTime(incrTime);
      }
    }, TICK);
    return () => {
      clearInterval(refetchIntervalId);
    };
  }, [startTime]);

  const handleRefetch = (e: React.SyntheticEvent) => {
    get(e, 'preventDefault') && e.preventDefault();
    setRefetchTime(REFETCH_LIMIT);
    const pinTime = Date.now();
    Cookies.set('pin-time', `${pinTime}`);
    setStartTime(pinTime);
    isFunction(onRefetch) && onRefetch();
  };

  return {
    refetchTime,
    handleRefetch
  };
};

export default useTimer;
