// Libraries
import * as React from 'react';
import { Link } from 'react-router-dom';

import { H6 } from 'components/Typography';

// Config
import { APP_NAME } from 'config';

// Styles
import styles from './Logo.module.scss';

const Logo = (): React.ReactElement<React.ReactNode> => {
  return (
    <Link to="/" className={styles.Root} replace>
      <H6>{APP_NAME}</H6>
    </Link>
  )
};

// Exports
export default Logo;
