// Libraries
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { get } from 'lodash';
import classNames from 'classnames';
import { v4 as uuidv4 } from 'uuid';
import { useHistory } from 'react-router-dom';

// Components
import AppBar from '@material-ui/core/AppBar';
import Container from '@material-ui/core/Container';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';

import Logo from '../Logo';

// Hooks
import { PageContext } from '../../hooks';

// Styles
import styles from './Header.module.scss';

// Types
type HEADER_PROPS_TYPE = {
  className?: String,
  token?: string,
  name?: string,
  surname?: string,
  patronymic?: string
};

const SIGN_MENU = {
  signed: [
    {
      id: uuidv4(),
      title: 'Профиль',
      link: '/auth/profile'
    },
    {
      id: uuidv4(),
      title: 'Выход',
      link: '/auth/sign-out'
    }
  ],
  unsigned: [
    {
      id: uuidv4(),
      title: 'Вход',
      link: '/auth/sign-in'
    },
    {
      id: uuidv4(),
      title: 'Регистрация',
      link: '/auth/sign-up'
    }
  ]
};

const Header = ({ className, token, surname, name, patronymic }: HEADER_PROPS_TYPE): React.ReactElement<typeof AppBar> => {
  const history = useHistory();
  const { menuIsOpen, setMenuIsOpen } = React.useContext(PageContext);
  const signMenuRef = React.useRef();
  const [ signMenuIsOpen, setSignMenuIsOpen ] = React.useState(false);
  const handleSignMenuOpen = () => {
    setSignMenuIsOpen(true);
  };
  const handleSignMenuClose = () => {
    setSignMenuIsOpen(false);
  };
  return (
    <AppBar
      position="fixed"
      className={classNames(
        className,
        styles.Root,
      )}
    >
      <Container maxWidth="lg">
        <Toolbar 
          classes={{
            root: styles.Toolbar
          }}
          disableGutters
        >
          <IconButton
            edge="start"
            color="inherit"
            className={styles.Menu}
            onClick={() => setMenuIsOpen(!menuIsOpen)}
          >
            {menuIsOpen ? <CloseIcon /> : <MenuIcon />}
          </IconButton>
          <Logo />
          <div
            className={styles.UserBlock}
            onClick={handleSignMenuOpen}
          >
            {
              (name || surname || patronymic) && (
                <>
                  <span>{ surname }</span>
                  <span>.{ name?.charAt(0) }.</span>
                  <span>{ patronymic?.charAt(0) }.</span>
                </>
              )
            }
            <IconButton
              edge="end"
              color="inherit"
              innerRef={signMenuRef}
            >
              <AccountCircle />
            </IconButton>
          </div>
        </Toolbar>
      </Container>
      <Menu
        anchorEl={signMenuRef?.current}
        open={signMenuIsOpen}
        onClose={handleSignMenuClose}
      >
        {
          SIGN_MENU[token ? 'signed' : 'unsigned'].map(({
            id,
            title,
            link
          }) => (
            <MenuItem key={id} onClick={() => history.replace(link)}>{title}</MenuItem>
          ))
        }
      </Menu>
    </AppBar>
  )
};

const mapStateToProps = (state: object): object => ({
  token: get(state, 'session.token'),
  name: get(state, 'session.name'),
  surname: get(state, 'session.surname'),
  patronymic: get(state, 'session.patronymic')
});

// Exports
export default compose<HEADER_PROPS_TYPE, any>(connect(mapStateToProps, null))(Header);
