import { get } from 'lodash';

export const APP_NAME = 'Партнерство каждому ребенку';
export const APP_VERSION = 1;

export const PRODUCTION = 'kidsjob.p4ec.ru';
export const BETA = 'kidsjob-front-git-main-dnateam.vercel.app';
export const DEVELOP = 'kidsjob.p4ec.ru';

const API = {
  [PRODUCTION]: 'https://api.p4ec.ru/',
  [BETA]: 'https://api.p4ec.ru/',
  [DEVELOP]: 'https://api.p4ec.ru/'
};

export const HOST = window.location.host;

export const API_URI = get(API, `['${HOST}']`) || get(API, `['${DEVELOP}']`);
