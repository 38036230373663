import { useEffect, useContext, useState } from 'react';
import { useMutation } from 'react-apollo';
import { get, join } from 'lodash';
import { loader } from 'graphql.macro';

// Hooks
import { ProfileContext } from '../../../../hooks';

// Data
import { ATTEMPTS_LIMIT } from '../../../../../data';

// Config
import {
  SERVER_ERRORS_HANDLER,
  ERROR_CONNECTION,
  ERROR_PIN_REFETCH,
  ERROR_PIN_RETRY,
  MESSAGES_MAP
} from 'config';

// Types
import { SIGN_IN_TYPE } from 'services/session';

// GraphQL
const profileQuery = loader('src/graphql/mutations/profile.gql');

type USE_STEP_PROPS_TYPE = {
  formValues?: object,
  signIn: SIGN_IN_TYPE,
};

export const useStep = ({
  formValues,
  signIn
}: USE_STEP_PROPS_TYPE) => {
  const [attempts, setAttempts] = useState(ATTEMPTS_LIMIT);

  const {
    loading,
    step,
    values,
    finished,
    limit,
    error,
    fail,
    handleValues,
    handleBack,
    handleNext,
    handleError,
    handleFail,
    handleLoading
  } = useContext(ProfileContext);

  const [handleProfileQueryChange, profileQueryResult] = useMutation(
    profileQuery,
    {
      fetchPolicy: 'no-cache',
      onError: (error: any) => SERVER_ERRORS_HANDLER(error, (error?: any) => handleFail(!!error))
    }
  );
  const {
    loading: profileQueryLoading,
    data: profileQueryData,
    error: profileQueryError,
  } = profileQueryResult;

  useEffect(() => {
    handleLoading(!!profileQueryLoading);
  }, [profileQueryLoading]);

  useEffect(() => {
    if (!profileQueryData) {
      return;
    }
    const user = get(profileQueryData, 'profile.user');
    const token = get(profileQueryData, 'profile.token');
    const isError = get(profileQueryData, 'profile.__typename') === 'Error';
    const message = get(profileQueryData, 'profile.message');

    if (token) { 
      signIn({ ...user, token });
    }

    const dataError = isError && get(MESSAGES_MAP, `${message}`, message);
    const currentErrors = [
      ...(profileQueryError ? [ERROR_CONNECTION] : []),
      ...(dataError ? [dataError] : []),
      ...(!token && step === 1
        ? [attempts <= 1 ? ERROR_PIN_REFETCH : ERROR_PIN_RETRY]
        : [])
    ];
    currentErrors.length <= 0
      ? handleNext({ errors: false })
      : handleError(join(currentErrors, '\n'));

  }, [profileQueryData, profileQueryError]);

  const handleSubmit = (event: React.SyntheticEvent) => {
    const pin = parseInt(get(formValues, 'pin'), 10);
    const stepValues = {
      ...values,
      pin,
    };
    handleValues(stepValues);
    return handleProfileQueryChange({
      variables: { ...stepValues },
    });
  };

  const handleRefetch = () => {
    handleProfileQueryChange({
      variables: { ...values },
    });
    setAttempts(ATTEMPTS_LIMIT);
  };

  return {
    loading,
    step,
    finished,
    limit,
    error,
    fail,
    handleBack,
    handleNext,
    handleError,
    handleFail,
    handleLoading,
    handleSubmit,
    handleRefetch,
    attempts
  };
};

export default useStep;
