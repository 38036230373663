// Libraries
import React, { Fragment } from 'react';
import { v4 as uuidv4 } from 'uuid';

// Components
import {Caption, H1, H5, Subtitle} from "components/Typography";
import {Paper} from "@material-ui/core";
import AnalysisFields from "../../AnalysisFields/AnalysisFields";
import {Input, Select} from "components/Form";

// Styles
import styles from '../styles.module.scss';

type BEHAVIOR_PROPS_TYPE = {
  error: any,
};

const DATA = ({ error }: BEHAVIOR_PROPS_TYPE): React.ReactElement<typeof Fragment> => (
  <div className={styles.FormSegment}>
    <H1
      className={styles.Title}
    >
      Поведение
    </H1>
    <Caption>
      (включая ограничения и дисциплину)
    </Caption>
    <H5
      className={styles.H5}
    >
      Потребности ребенка
    </H5>
    <Paper className={styles.Paper}>
      <div className={styles.QuestionContainer}>
        <Subtitle>Соответствует ли поведение ребенка его возрасту?</Subtitle>
        <Select
          className={styles.QuestionContainerSelect}
          name="child_behavior"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="child_behavior_description"
          multiline
          rowsMax={4}
          label="Комментарий"
          placeholder="Комментарий"
        />
      </div>
      <div className={styles.QuestionContainer}>
        <Subtitle>Адекватно ли ведет себя ребенок с другими людьми?</Subtitle>
        <Select
          className={styles.QuestionContainerSelect}
          name="adequate_child"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="adequate_child_description"
          multiline
          rowsMax={4}
          label="Комментарий"
          placeholder="Комментарий"
        />
      </div>
      <div className={styles.QuestionContainer}>
        <Subtitle>Справляется ли ребенок с соответствующими его возрасту заданиями самостоятельно?</Subtitle>
        <Select
          className={styles.QuestionContainerSelect}
          name="self_reliance"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="self_reliance_description"
          multiline
          rowsMax={4}
          label="Комментарий"
          placeholder="Комментарий"
        />
      </div>
      <div className={styles.QuestionContainer}>
        <Subtitle>Есть ли у ребенка особые поведенческие трудности?</Subtitle>
        <Select
          className={styles.QuestionContainerSelect}
          name="behavioral_difficulties"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="behavioral_difficulties"
          multiline
          rowsMax={4}
          label="Если ДА, укажите что именно"
          placeholder="Если ДА, укажите что именно"
        />
      </div>
    </Paper>
    <H5
      className={styles.H5}
    >
      Возможности родителей/лиц, их заменяющих
    </H5>
    <Paper className={styles.Paper}>
      <div className={styles.QuestionContainer}>
        <Subtitle>Есть ли в семье четкие правила и ограничения относительно поведения?</Subtitle>
        <Select
          className={styles.QuestionContainerSelect}
          name="clear_rules"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="clear_rules_description"
          multiline
          rowsMax={4}
          label="Комментарий"
          placeholder="Комментарий"
        />
      </div>
      <div className={styles.QuestionContainer}>
        <Subtitle>Состоят ли родители на контроле у органов опеки, полиции, КДН? Если ЛРП/ОРП на других детей?</Subtitle>
        <Select
          className={styles.QuestionContainerSelect}
          name="control_parents"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="control_parents_description"
          multiline
          rowsMax={4}
          label="Комментарий"
          placeholder="Комментарий"
        />
      </div>
      <div className={styles.QuestionContainer}>
        <Subtitle>Адекватно ли родители регулируют поведение ребенка?</Subtitle>
        <Select
          className={styles.QuestionContainerSelect}
          name="adequate_response"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="adequate_response_description"
          multiline
          rowsMax={4}
          label="Комментарий"
          placeholder="Комментарий"
        />
      </div>
      <div className={styles.QuestionContainer}>
        <Subtitle>Есть ли у родителей судимость?</Subtitle>
        <Select
          className={styles.QuestionContainerSelect}
          name="parental_conviction"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="parental_conviction_description"
          multiline
          rowsMax={4}
          label="Комментарий"
          placeholder="Комментарий"
        />
      </div>
      <div className={styles.QuestionContainer}>
        <Subtitle>Всегда ли взрослые знают, где находится ребенок?</Subtitle>
        <Select
          className={styles.QuestionContainerSelect}
          name="where_is_child"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="where_is_child_description"
          multiline
          rowsMax={4}
          label="Комментарий"
          placeholder="Комментарий"
        />
      </div>
      <div className={styles.QuestionContainer}>
        <Subtitle>Знают ли родители причину сложного поведения ребенка?</Subtitle>
        <Select
          className={styles.QuestionContainerSelect}
          name="difficult_behavior_child"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="difficult_behavior_child_description"
          multiline
          rowsMax={4}
          label="Комментарий"
          placeholder="Комментарий"
        />
      </div>
    </Paper>
    <AnalysisFields title="Поведение" name="behavior" />
  </div>
);

const BEHAVIOR = {
  id: uuidv4(),
  name: 'Поведение',
  data: DATA,
};

export default BEHAVIOR;
