// Libraries
import React from "react";

// Components
import {H6, Subtitle} from "components/Typography";
import {Paper} from "@material-ui/core";
import {Input, Select} from "components/Form";
import Legend from "../Legend/Legend";

// Styles
import styles from "../Step0/styles.module.scss";

interface AnalysisFieldsI {
  readonly title: string;
  readonly name: string;
}

const AnalysisFields = ({ title, name }: AnalysisFieldsI): JSX.Element => (
  <>
    <H6
      className={styles.H5}
    >
      { title }
    </H6>
    <Paper className={styles.Paper}>
      <Subtitle className={styles.Subtitle}>Сильные стороны</Subtitle>
      <Input
        className={styles.Input}
        name={`${name}_strength_side`}
        multiline
        rowsMax={6}
        fullWidth
      />
      <Subtitle className={styles.Subtitle}>Слабые стороны</Subtitle>
      <Input
        className={styles.Input}
        name={`${name}_weak_side`}
        multiline
        rowsMax={6}
        fullWidth
      />
      <Subtitle className={styles.Subtitle}>Выводы: *</Subtitle>
      <Input
        className={styles.Input}
        name={`${name}_conclusion`}
        multiline
        rowsMax={6}
        fullWidth
      />
      <Subtitle className={styles.Subtitle}>Уровень функционирования семьи по разделу «{ title }»: **</Subtitle>
      <Select
        name={`${name}_analysis`}
        className={styles.Input}
      />
    </Paper>
    <Legend />
  </>
)

export default AnalysisFields;
