// Libraries
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { get } from 'lodash';
import classNames from 'classnames';

// Components
import BaseDrawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import CloseIcon from '@material-ui/icons/Close';

import Link from 'components/Link';

// Hooks
import withWidth from '@material-ui/core/withWidth';

import { PageContext } from '../../hooks';

// Services
import { ACTION_TYPE, SESSION_TYPE } from 'services/session';

// Styles
import styles from './Drawer.module.scss';

// Data
import MENU, { ITEM_TYPE } from './data';

type DRAWER_PROPS_TYPE = {
  className?: string,
  classes?: {
    root?: string,
    paper?: string,
  },
  width: string,
  session?: SESSION_TYPE,
}

const Drawer = ({ className, classes, width, session }: DRAWER_PROPS_TYPE): React.ReactElement<typeof BaseDrawer> => {
  const { menuIsOpen, setMenuIsOpen } = React.useContext(PageContext);
  const handleClose = () => setMenuIsOpen && setMenuIsOpen(false);
  const role = get(session, 'roles[0].name');
  const special = get(MENU, role);
  const COMPOSED_MENU = [
    ...(special ? [special] : []),
    MENU.cardsAndQuestionnaires,
  ];
  return (
    <BaseDrawer
      className={classNames(
        className,
        classes?.root,
        styles.Root
      )}
      variant={['lg', 'xl'].indexOf(width) >= 0 ? 'permanent' : 'temporary'}
      classes={{
        paper: styles.Paper,
      }}
      open={menuIsOpen}
      onClose={handleClose}
    >
      <Toolbar 
        classes={{
          root: styles.Toolbar
        }}
      >
        <IconButton
          edge="start"
          className={styles.Close}
          color="inherit"
          aria-label="menu"
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </Toolbar>
      <div className={styles.Container}>
        {
          COMPOSED_MENU.map((group, index) => (
            <List
              className={styles.List}
              key={group.id}
            >
              {
                group.items.map((item: ITEM_TYPE) => (
                  <ListItem
                    className={styles.ListItem}
                    button
                    component={Link}
                    key={item.id}
                    href={item.link}
                  >
                    <ListItemIcon>
                      <item.icon className={styles.ListItemIcon} />
                    </ListItemIcon>
                    <ListItemText className={styles.ListItemText} primary={item.name} />
                  </ListItem>
                ))
              }
              <Divider />
            </List>
          ))
        }
      </div>
    </BaseDrawer>
  )
}


const mapStateToProps = (state: object): object => ({
  session: get(state, 'session')
});

const mapDispatchToProps = (dispatch: (action: ACTION_TYPE) => any) => ({
  // Services
});

// Exports
export default withWidth()(compose<DRAWER_PROPS_TYPE, any>(connect(mapStateToProps, mapDispatchToProps))(Drawer));

