// Libraries
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { get } from 'lodash';
import { reduxForm } from 'redux-form';

// Services
import { signIn, SESSION_TYPE, SIGN_IN_TYPE, ACTION_TYPE } from 'services/session';

// Components
import Button from 'components/Button';
import BaseForm, { Input } from 'components/Form';

// Styles
import styles from './Restore.module.scss';

// Hooks
import { useStep } from './hooks';

// Data
import { validate, asyncValidate } from './validators';

// Types
type FORM_PROPS_TYPE = {
  valid?: boolean,
  asyncValidating?: boolean | string,
  anyTouched?: boolean,
  handleSubmit: Function,
  formValues?: object,
  signIn: SIGN_IN_TYPE
};

const Form = ({
  valid,
  handleSubmit: formSubmit,
  formValues,
  signIn,
}: FORM_PROPS_TYPE): React.ReactElement<typeof BaseForm> => {
  const { error, handleSubmit } = useStep({
    formValues,
    signIn
  });
  return (
    <BaseForm
      className={styles.Form}
      onSubmit={formSubmit(handleSubmit)}
      name="reset"
      autoComplete="off"
    >
      <Input
        className={styles.Input}
        key="pin"
        label="Код"
        name="pin"
        placeholder="Код"
        autoComplete="one-time-code"
        error={error}
        required
      />
      <Input
        className={styles.Input}
        key="password"
        label="Новый пароль"
        name="password"
        placeholder="Новый пароль"
        type="password"
        error={error}
        required
      />
      <Input
        className={styles.Input}
        key="confirmation"
        label="Повторить пароль"
        name="confirmation"
        placeholder="Повторить пароль"
        type="password"
        error={error}
        required
      />
      <Button
        className={styles.Button}
        type="submit"
        disabled={!(valid)}
      >
        Войти
      </Button>
    </BaseForm>
  );
};

const resetForm = reduxForm<object, any>({
  form: 'reset',
  // destroyOnUnmount: false,
  asyncValidate,
  validate,
  touchOnChange: true,
})(Form);

const mapStateToProps = (state: object): object => ({
  formValues: get(state, 'form.reset.values'),
});

const mapDispatchToProps = (dispatch: (action: ACTION_TYPE) => any) => ({
  // Services
  signIn: (profile: SESSION_TYPE) => dispatch(signIn(profile))
});

// Exports
export default compose(connect(mapStateToProps, mapDispatchToProps))(resetForm);
