// Libraries
import React, { Fragment } from 'react';
import { v4 as uuidv4 } from 'uuid';

// Components
import {Caption, H5, H1, Subtitle} from "components/Typography";
import { Paper } from '@material-ui/core';
import {Input, Select} from "components/Form";
import AnalysisFields from "../../AnalysisFields/AnalysisFields";

// Styles
import styles from '../styles.module.scss';

type IDENTITY_PROPS_TYPE = {
  error: any,
};

const DATA = ({ error }: IDENTITY_PROPS_TYPE): React.ReactElement<typeof Fragment> => (
  <div className={styles.FormSegment}>
    <H1
      className={styles.Title}
    >
      Идентичность
    </H1>
    <Caption>
      (включая представление о себе, ощущение своей принадлежности к семье, обществу, и др.)
    </Caption>
    <H5
      className={styles.H5}
    >
      Потребности ребенка
    </H5>
    <Paper className={styles.Paper}>
      <div className={styles.QuestionContainer}>
        <Subtitle>Проявляет ли ребенок нарушение привязанности?</Subtitle>
        <Select
          className={styles.QuestionContainerSelect}
          name="attachment_disorder"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="attachment_disorder_description"
          multiline
          rowsMax={4}
          label="Комментарий"
          placeholder="Комментарий"
        />
      </div>
      <div className={styles.QuestionContainer}>
        <Subtitle>Гордится ли ребенок своими достижениями?</Subtitle>
        <Select
          className={styles.QuestionContainerSelect}
          name="achievements"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="achievements_description"
          multiline
          rowsMax={4}
          label="Комментарий"
          placeholder="Комментарий"
        />
      </div>
      <div className={styles.QuestionContainer}>
        <Subtitle>Осознает ли ребенок, к какому полу он принадлежит?</Subtitle>
        <Select
          className={styles.QuestionContainerSelect}
          name="sex_child"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="sex_child_description"
          multiline
          rowsMax={4}
          label="Есть ли несоответствие между полом ребенка и его поведением?"
          placeholder="Есть ли несоответствие между полом ребенка и его поведением?"
        />
      </div>
      <div className={styles.QuestionContainer}>
        <Subtitle>Есть ли у ребенка привязанность к кому-либо из членов семьи?</Subtitle>
        <Select
          className={styles.QuestionContainerSelect}
          name="affection"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="affection_description"
          multiline
          rowsMax={4}
          label="Комментарий"
          placeholder="Комментарий"
        />
      </div>
      <div className={styles.QuestionContainer}>
        <Subtitle>Знает ли ребенок историю семьи и родственников, проживающих отдельно от семьи?</Subtitle>
        <Select
          className={styles.QuestionContainerSelect}
          name="history_family"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="history_family_description"
          multiline
          rowsMax={4}
          label="Комментарий"
          placeholder="Комментарий"
        />
      </div>
    </Paper>
    <H5
      className={styles.H5}
    >
      Возможности родителей/лиц, их заменяющих
    </H5>
    <Paper className={styles.Paper}>
      <div className={styles.QuestionContainer}>
        <Subtitle>Обращаются ли с ребенком уважительно? Всегда ли к ребенку обращаются по имени?</Subtitle>
        <Select
          className={styles.QuestionContainerSelect}
          name="respectful_communication"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="respectful_communication_description"
          multiline
          rowsMax={4}
          label="Комментарий"
          placeholder="Комментарий"
        />
      </div>
      <div className={styles.QuestionContainer}>
        <Subtitle>Считаются ли с мнением ребенка?</Subtitle>
        <Select
          className={styles.QuestionContainerSelect}
          name="opinion"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="opinion_description"
          multiline
          rowsMax={4}
          label="Комментарий"
          placeholder="Комментарий"
        />
      </div>
      <div className={styles.QuestionContainer}>
        <Subtitle>Есть ли в семье свои традиции?</Subtitle>
        <Select
          className={styles.QuestionContainerSelect}
          name="traditional"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="traditional_description"
          multiline
          rowsMax={4}
          label="Комментарий"
          placeholder="Комментарий"
        />
      </div>
      <div className={styles.QuestionContainer}>
        <Subtitle>Определены ли роли в семье? Есть ли в семье главный?</Subtitle>
        <Select
          className={styles.QuestionContainerSelect}
          name="roles_in_family"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="roles_in_family_description"
          multiline
          rowsMax={4}
          label="Комментарий"
          placeholder="Комментарий"
        />
      </div>
      <div className={styles.QuestionContainer}>
        <Subtitle>Религиозна ли семья?</Subtitle>
        <Select
          className={styles.QuestionContainerSelect}
          name="religion"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="religion_description"
          multiline
          rowsMax={4}
          label="Комментарий"
          placeholder="Комментарий"
        />
      </div>
      <div className={styles.QuestionContainer}>
        <Subtitle>Осознаются ли членами семьи права и обязанности родителей?</Subtitle>
        <Select
          className={styles.QuestionContainerSelect}
          name="parental_responsibilities"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="parental_responsibilities_description"
          multiline
          rowsMax={4}
          label="Комментарий"
          placeholder="Комментарий"
        />
      </div>
    </Paper>
    <AnalysisFields title="Идентичность" name="identity" />
  </div>
);

const IDENTITY = {
  id: uuidv4(),
  name: 'Идентичность',
  data: DATA,
};

export default IDENTITY;
