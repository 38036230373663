// Libraries
import React from 'react';
import classNames from 'classnames';

// Components
import { Radio, FormControlLabel } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import BaseRadioGroup from '@material-ui/core/RadioGroup';
import { Field as BaseField } from 'redux-form';

// Styles
import styles from './Checkbox.module.scss';

interface RADIO_GROUP_PROPS_TYPE {
  className?: string,
  classes?: {
    root?: string,
    checkbox?: string
  },
  input?: any,
  name?: string,
  label?: React.ReactNode,
  validate?: Function[],
  meta?: {
    touched?: boolean,
    error?: string,
    invalid?: boolean
  },
  required?: boolean,
  options?: { id?: string | number, name?: string, value?: any}[],
  disabled?: boolean,
}

const RadioGroup = ({
  className,
  classes,
  input,
  label,
  meta,
  options,
  disabled,
}: RADIO_GROUP_PROPS_TYPE): React.ReactElement<typeof FormControl> => (
  <FormControl
    classes={{
      root: styles.Root
    }}
    component="fieldset"
  >
    <FormLabel component="legend">{label}</FormLabel>
    <BaseRadioGroup
      aria-label="gender"
      name="gender"
      value={input.value}
    >
      {
        options && options.map(({ id, name, value }) => (
          <FormControlLabel
            key={id}
            className={classNames(
              className,
              classes?.root,
              styles.Root,
              {
                [styles.RootIsError]: meta?.touched && meta?.invalid
              }
            )}
            value={value}
            label={name}
            disabled={disabled}
            control={(
              <Radio
                className={classNames(
                  classes?.checkbox,
                  styles.Checkbox
                )}
                checked={input.value === value}
                onChange={input.onChange}
                // onClick={() => input.onChange(!input.value)}
                // data-checked={input.value}
                disableRipple
              />
            )}
          />
        ))
      }
    </BaseRadioGroup>
  </FormControl>
);

const Field = ({
  name,
  validate,
  classes,
  ...props
}: RADIO_GROUP_PROPS_TYPE): React.ReactElement<typeof BaseField> => (
  <BaseField
    name={name}
    component={RadioGroup}
    validate={validate}
    {...props}
  />
);

export default Field;
