import React from 'react';
import classNames from 'classnames';
import BaseTypography from '@material-ui/core/Typography';

// Styles
import styles from './Typography.module.scss';

const VARIANT = {
  // Body
  CAPTION: 'caption',
  P: 'body1',
  // Headers
  H1: 'h1',
  H2: 'h2',
  H3: 'h3',
  H4: 'h4',
  H5: 'h5',
  H6: 'h6',
  SUBTITLE: 'subtitle1',
};

export type TYPOGRAPHY_PROPS_TYPE = {
  children?: any,
  className?: string,
  component?: string,
  variant?: any
};

const Typography = ({
  children,
  className,
  variant = VARIANT.P,
  ...props
}: TYPOGRAPHY_PROPS_TYPE): React.ReactElement<typeof BaseTypography> => (
  <BaseTypography
    {...props}
    variant={variant}
    className={classNames(className, styles.Root, {
      // Body
      [styles.RootVariantCaption]: variant === VARIANT.CAPTION,
      [styles.RootVariantP]: variant === VARIANT.P,
      // Headers
      [styles.RootVariantH1]: variant === VARIANT.H1,
      [styles.RootVariantH2]: variant === VARIANT.H2,
      [styles.RootVariantH3]: variant === VARIANT.H3,
      [styles.RootVariantH4]: variant === VARIANT.H4,
      [styles.RootVariantH5]: variant === VARIANT.H5,
      [styles.RootVariantH6]: variant === VARIANT.H6,
      [styles.RootVariantSubtitle]: variant === VARIANT.SUBTITLE,
    })}
  >
    {children}
  </BaseTypography>
);

// Exports
export default Typography;

export const Subtitle = (props: TYPOGRAPHY_PROPS_TYPE): React.ReactElement<typeof Typography> => (
  <Typography variant={VARIANT.SUBTITLE} {...props} />
);

export const Caption = (props: TYPOGRAPHY_PROPS_TYPE): React.ReactElement<typeof Typography> => (
  <Typography variant={VARIANT.CAPTION} {...props} />
);

export const P = (props: TYPOGRAPHY_PROPS_TYPE): React.ReactElement<typeof Typography> => (
  <Typography component="p" variant={VARIANT.P} {...props} />
);

export const H1 = (props: TYPOGRAPHY_PROPS_TYPE): React.ReactElement<typeof Typography> => (
  <Typography component="h1" variant={VARIANT.H1} {...props} />
);

export const H2 = (props: TYPOGRAPHY_PROPS_TYPE): React.ReactElement<typeof Typography> => (
  <Typography component="h2" variant={VARIANT.H2} {...props} />
);

export const H3 = (props: TYPOGRAPHY_PROPS_TYPE): React.ReactElement<typeof Typography> => (
  <Typography component="h3" variant={VARIANT.H3} {...props} />
);

export const H4 = (props: TYPOGRAPHY_PROPS_TYPE): React.ReactElement<typeof Typography> => (
  <Typography component="h4" variant={VARIANT.H4} {...props} />
);

export const H5 = (props: TYPOGRAPHY_PROPS_TYPE): React.ReactElement<typeof Typography> => (
  <Typography component="h5" variant={VARIANT.H5} {...props} />
);

export const H6 = (props: TYPOGRAPHY_PROPS_TYPE): React.ReactElement<typeof Typography> => (
  <Typography component="h6" variant={VARIANT.H6} {...props} />
);
