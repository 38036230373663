// Libraries
import React from 'react';

// Components
import { Page } from 'components/Page';
import { H1 } from 'components/Typography';
// Styles
import styles from './Network.module.scss';

const Network = (): React.ReactElement<typeof Page> => (
  <Page
    classes={{
      root: styles.Root,
      container: styles.Container
    }}
  >
    <H1>
      Соединение с сервером прервано. Проверьте наличие связи, либо повторите попытку позже.
    </H1>
  </Page>
);

// Exports
export default Network;
