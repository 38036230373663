import React from 'react';
import { useMutation } from 'react-apollo';
import { get, join } from 'lodash';
import { loader } from 'graphql.macro';

// Config
import {
  ERROR_CONNECTION,
  MESSAGES_MAP
} from 'config';

// Types
import { ACTION_TYPE } from 'services/session';

// GraphQL
const signOutQuery = loader('src/graphql/mutations/signOut.gql');

type USE_SIGN_OUT_CONTEXT_TYPE = {
  loading: boolean,
  error: string | boolean
};
export const SignOutContext = React.createContext<USE_SIGN_OUT_CONTEXT_TYPE>({
  loading: false,
  error: false,
});

export type USE_SIGN_OUT_PROPS_TYPE = {
  token?: string,
  signOut: () => ACTION_TYPE,
};

export const useSignOut = ({
  token,
  signOut,
}: USE_SIGN_OUT_PROPS_TYPE) => {

  const [error, setError] = React.useState<string | boolean>(false);
  const [handleSignOutQuery, { loading, data, error: signOutQueryError }] = useMutation(signOutQuery, { fetchPolicy: 'no-cache' });

  React.useEffect((): any => {
    !!token && handleSignOutQuery({ variables: { token } });
  },[token]);

  React.useEffect((): any => {
    if (!data || loading) {
      return;
    }
    const isError = get(data, 'signOut.__typename') === 'Error';
    const message = get(data, 'signOut.message');

    const dataError = isError && get(MESSAGES_MAP, `${message}`, message);
    const currentErrors = [
      ...(signOutQueryError ? [ERROR_CONNECTION] : []),
      ...(dataError ? [dataError] : []),
    ];
    currentErrors.length > 0 ? setError(join(currentErrors, '\n')) : signOut();

  }, [loading]);

  return {
    loading,
    error,
  };
};

export default useSignOut;
