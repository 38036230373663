// Libraries
import React from 'react';
import { Field as BaseField } from 'redux-form';
import classNames from 'classnames';
import moment from "moment";
import MomentUtils from "@date-io/moment";
import "moment/locale/ru";
import { get } from 'lodash';

// import DateFnsUtils from '@date-io/date-fns';
// import { ru } from "date-fns/locale";

// Components
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

// Styles
import styles from './DatePicker.module.scss';

moment.locale('ru');

interface DATEPICKER_PROPS_TYPE {
  className?: string,
  classes?: {
    root?: string,
    checkbox?: string
  },
  input?: any,
  type?: string,
  name?: string,
  label?: React.ReactNode,
  validate?: Function[],
  meta?: {
    touched?: boolean,
    error?: string,
    invalid?: boolean
  },
  required?: boolean,
  format?: string,
  placeholder?: string,
  disableFuture?: boolean,
  clearable?: boolean,
  error?: string,
  maxDate?: Date,
  disabled?: boolean,
  fullWidth?: boolean,
}

export const DatePicker = ({
  className,
  classes,
  input,
  label,
  format = 'DD.MM.YYYY',
  meta,
  required,
  placeholder,
  disableFuture,
  clearable = false,
  error,
  maxDate,
  disabled,
  fullWidth,
  ...props
}: DATEPICKER_PROPS_TYPE): React.ReactElement<typeof KeyboardDatePicker> => {
  const value = get(input, 'value') || '';
  const ISO = moment(value, 'YYYY-MM-DD');
  const RU = moment(value, 'DD.MM.YYYY');
  const RU_IS_VALID = RU.isValid();
  const ISO_IS_VALID = ISO.isValid();
  const formated = (RU_IS_VALID && RU) || (ISO_IS_VALID && ISO) || null;

  return (
    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
      <KeyboardDatePicker
        className={classNames(
          className,
          classes?.root,
          styles.Root,
          {
            [styles.RootIsRequired]: required,
            [styles.RootIsError]: meta?.touched && meta?.invalid
          }
        )}
        {...input}
        disabled={disabled}
        value={formated}
        defaultValue={moment().format('DD.MM.YYYY')}
        maxDate={maxDate}
        error={error || (meta?.touched && meta?.invalid)}
        placeholder={placeholder}
        helperText={error || (meta?.touched && meta?.error)}
        label={label}
        format={format}
        disableFuture={disableFuture}
        clearable={clearable}
        cancelLabel="Отмена"
        okLabel="Ок"
        clearLabel="Очистить"
        fullWidth={fullWidth}
      />
    </MuiPickersUtilsProvider>
  );
}

const Field = ({
  name,
  validate,
  classes,
  type = 'date',
  ...props
}: DATEPICKER_PROPS_TYPE): React.ReactElement<typeof BaseField> => {
  return (
    <BaseField
      name={name}
      component={DatePicker}
      validate={validate}
      type={type}
      // normalize={(value: Date) => {
      //   console.log('normalize value', value);
      //   return moment(value, 'DD.MM.YYYY').format('DD.MM.YYYY');
      // }}
      {...props}
    />
  );
}

export default Field;
