// Libraries
import React from 'react';
import {get} from 'lodash';
import { useHistory } from "react-router-dom";
import {useLazyQuery} from "react-apollo";
import {loader} from "graphql.macro";

// Types
import { SESSION_TYPE } from 'services/session';

// Data
import { COLUMNS, GET_ACTIONS_CELL } from './data';

export type LIST_PROPS_TYPE = {
  session?: SESSION_TYPE,
  url: string
};

// GraphQL
const questionaryTypesQuery = loader('src/graphql/queries/questionaryTypes.gql');

export const useList = ({
  session,
  url
}: LIST_PROPS_TYPE) => {
  const history = useHistory();

  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState<boolean>(false);

  const token = get(session, 'token');

  const loadQuestinaries = () => {
    handlequestionaryTypesQuery();
  };

  React.useEffect((): any => {
    !!token && loadQuestinaries();
  },[token]);

  const columns = React.useMemo(
    () => COLUMNS,
    []
  );

  const [handlequestionaryTypesQuery, {
    loading: questionaryTypesQueryLoading,
    data: questionaryTypesQueryData
  }] = useLazyQuery(questionaryTypesQuery, {
    fetchPolicy: 'no-cache'
  });

  React.useEffect(() => {
    setLoading(!!questionaryTypesQueryLoading);
  }, [questionaryTypesQueryLoading]);

  React.useEffect((): any => {
    if (!questionaryTypesQueryData || questionaryTypesQueryLoading) {
      return;
    }

    const normalizedData = get(questionaryTypesQueryData, 'questionaryTypes.data', []).map((item: any) => ({
      name: item.title,
      path: item.id,
      actions: GET_ACTIONS_CELL({
        ...item,
        onView: () => history.push(`${url}/${item.id}`),
        onAdd: () => history.push(`${url}/${item.id}/new`),
      }),
    }));

    setData(normalizedData);
  }, [questionaryTypesQueryData, questionaryTypesQueryLoading]);

  return {
    loading,
    data,
    setData,
    columns,
  };
};

export default useList;
