// Libraries
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { get } from 'lodash';
import classNames from 'classnames';

// Components
import Page from 'components/Page';
import Title from './components/Title';

import Informer from './components/Informer';
import Wizard from './components/Wizard';
import Additional from './components/Additional';

// Styles
import styles from './SignUp.module.scss';

// Services
import { SESSION_TYPE, ACTION_TYPE } from 'services/session';

// Hooks
import { useSignUp, SignUpContext } from './hooks';

type SIGN_UP_PROPS_TYPE = {
  session?: SESSION_TYPE,
};

const SignUp = ({
  session
}: SIGN_UP_PROPS_TYPE) => {
  const usedSignUp = useSignUp({ limit: 2, session });
  const { loading } = usedSignUp;

  return (
    <>
      <Page
        classes={{
          root: classNames(styles.Root)
        }}
        loading={loading}
      >
        
        <div className={styles.Wrapper}>
          <SignUpContext.Provider value={usedSignUp}>
            <Title className={styles.Title} />
            <div className={styles.Actions}>
              <Informer className={styles.Informer} />
              <Wizard />
            </div>
            <Additional className={styles.Additional} />
          </SignUpContext.Provider>
        </div>
      </Page>
    </>
  );
};

// Exports
const mapStateToProps = (state: object): object => ({
  session: get(state, 'session')
});

const mapDispatchToProps = (dispatch: (action: ACTION_TYPE) => any) => ({
  // Services
});

// Exports
export default compose<SIGN_UP_PROPS_TYPE, any>(connect(mapStateToProps, mapDispatchToProps))(SignUp);
