// Libraries
import React from 'react';
import classNames from 'classnames';

// Components
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

// Styles
import styles from './Loader.module.scss';

// Types
export type LOADER_PROPS_TYPES = {
  className?: string,
  classes?: {
    root?: string,
    progress?: string
  },
  value?: number,
  size?: number,
};

export const Loader = ({
  className,
  classes = {},
  value,
  size,
}: LOADER_PROPS_TYPES): React.ReactElement<typeof Backdrop> => {
  return (
    <Backdrop
      className={classNames(
        className,
        classes?.root,
        styles.Root
      )}
      open={true}
    >
      <CircularProgress
        className={classNames(
          className,
          classes?.progress,
          styles.Progress
        )}
        value={value}
        size={size}
      />
    </Backdrop>
  );
};

export default Loader;
