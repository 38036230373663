// Libraries
import React from 'react';
import classNames from 'classnames';
import { VariableSizeList, ListChildComponentProps } from 'react-window';
import { get } from 'lodash';

// Components
import ListSubheader from '@material-ui/core/ListSubheader';
import BaseAutocomplete, { AutocompleteRenderGroupParams } from '@material-ui/lab/Autocomplete';

import { Input } from 'components/Form/components/Input';
import { P } from 'components/Typography';

// Styles
import styles from './Autocomplete.module.scss';

// Hooks
import { useAutocomplete } from './hooks';

const LISTBOX_PADDING = 8; // px

const renderRow = (props: ListChildComponentProps) => {
  const { data, index, style } = props;
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: (style.top as number) + LISTBOX_PADDING,
    },
  });
};

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

const useResetCache = (data: any) => {
  const ref = React.useRef<VariableSizeList>(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
};

// Adapter for react-window
const ListboxComponent = React.forwardRef<HTMLDivElement>(function ListboxComponent(props, ref) {
  const { children, ...other } = props;
  const itemData = React.Children.toArray(children);
  const itemCount = itemData.length;
  const itemSize = 36;

  const getChildSize = (child: React.ReactNode) => itemSize;

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

const renderGroup = (params: AutocompleteRenderGroupParams) => [
  <ListSubheader key={params.key} component="div">
    {params.group}
  </ListSubheader>,
  params.children,
];

export type VIRTUALIZED_PROPS_TYPES = {
  classes?: {
    root?: string
  },
  className?: string,
  label?: string,
  placeholder?: string,
  disabled?: boolean,
  required?: boolean,
  mask?: any,
  maxLength?: number,
  limitTags?: number,
  multiple?: boolean,
  disableListWrap?: boolean,
  noOptionsText?: string,
  fullWidth?: boolean,
  error?: string | boolean,
  options?: any,
  src?: string,
  params?: object,
  value?: any,
  onChange?: any,
  onBlur?: any,
  onFocus?: any,
  autoSelect?: boolean,
};

const Autocomplete = ({
  classes,
  className,
  label,
  placeholder,
  disabled,
  required,
  mask,
  maxLength,
  limitTags = 1,
  multiple,
  disableListWrap,
  noOptionsText = 'Нет вариантов',
  fullWidth,
  autoSelect,
  // onChange,
  onBlur,
  onFocus,
  error,
  ...props
}: VIRTUALIZED_PROPS_TYPES): React.ReactElement<typeof BaseAutocomplete> => {
  const usedAutocomplete = useAutocomplete(props);
  const {
    options = [],
    loading,
    value,
    handleChange,
  } = usedAutocomplete;
  // console.log('options, find', options, find(options, { id: value.id}));
  // console.log('value', value);
  return (
    <BaseAutocomplete
      className={classNames(
        className,
        classes?.root,
        styles.Root
      )}
      // freeSolo
      limitTags={limitTags}
      // autoSelect
      filterSelectedOptions
      // blurOnSelect
      fullWidth={fullWidth}
      multiple={multiple}
      disabled={disabled}
      disableListWrap={disableListWrap}
      noOptionsText={noOptionsText}
      ListboxComponent={ListboxComponent as React.ComponentType<React.HTMLAttributes<HTMLElement>>}
      renderGroup={renderGroup}
      // groupBy={(option: any) => get(option,'title[0]','').toUpperCase()}
      // placeholder={placeholder}
      loading={loading}
      value={value}
      // defaultValue={find(options, { id: value.id})}
      // onClose={handleChange}
      // onBlur={handleBlur}
      // onBlur={onBlur}
      // onFocus={onFocus}
      onChange={handleChange}
      renderInput={(inputProps: object) =>
        <Input
          mask={mask}
          maxLength={maxLength}
          label={label}
          placeholder={placeholder}
          // onBlur={onBlur}
          // onFocus={onFocus}
          // {...inputProps}
          input={inputProps}
          error={error}
        />
      }
      getOptionSelected={(option: any, value: any) => {
        return get(option, 'id') === get(value, 'id');
      }}
      getOptionLabel={(option: any) => option?.title || ''}
      renderOption={(option: any) => <P>{option?.name || option?.title}</P>}
      options={options}
    />
  );
};

export default Autocomplete; 
