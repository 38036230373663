// Libraries
import { isEmpty, keys, merge } from 'lodash';
import url from 'url';

export const isExternal = (string: string): boolean => {
  const { host } = url.parse(string);
  return !!host;
};

type inputType = {
  base: string,
  hash: string,
  path: string | Array<string>,
  query: {
    [key in number | string]: number | string | Array<number> | Array<string>
  }
};

export const resolve: Function = (
  input?: string | Array<string> | inputType
): string => {
  const data: inputType = {
    base: '/',
    hash: '',
    path: [],
    query: {}
  };

  const { base, hash, path, query }: inputType = merge(
    data,
    Array.isArray(input) || typeof input === 'string' ?
      { path: ([] as Array<string>).concat(input) } :
      input
  );

  let url: string = [base].concat(path).join('/');

  if (!isEmpty(query)) {
    const queries: Array<string> = keys(query).map(
      (key: number | string): string =>
        `${key}=${([] as Array<any>).concat(query[key]).join(',')}`
    );

    if (queries.length > 1) {
      url = `${url}?${queries.join('&')}`;
    }
  }

  if (hash) {
    url = `${url}#${hash}`;
  }

  return url.replace(url.match(/^https?:/) ? /([^:])\/\/+/g : /()\/+/g, '$1/');
};
