// Libraries
import * as yup from 'yup';
import { get } from 'lodash';

import { asyncValidator } from 'components/Form';

// Validations
import { VALIDATE_LOGIN } from 'helpers/validations';

// Config
import { ERROR_FIELD_REQUIRED } from 'config';

export const validate = (values: object) => {
  const login = VALIDATE_LOGIN(get(values, 'login', ''));
  return {
    ...(login && { login })
  };
};

export const asyncValidate = asyncValidator(
  yup.object().shape({
    login: yup.string().required(ERROR_FIELD_REQUIRED),
  })
);
