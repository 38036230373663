// Libraries
import React from 'react';
import classNames from 'classnames';
import {useSelector} from "react-redux";

// Components
import { Box } from '@material-ui/core';
import Container from '@material-ui/core/Container';

import Loader from 'components/Loader';

import Header from './components/Header';
import Footer from './components/Footer';
import Drawer from './components/Drawer';

// Hooks
import { PageContext, usePage } from './hooks';

// Styles
import styles from './Page.module.scss';

// Types
import {RootStore} from "types";

// Types
export type PAGE_PROPS_TYPE = {
  children: any,
  className?: String,
  classes?: {
    root?: String,
    container?: String,
    header?: String,
    footer?: String
  },
  loading?: boolean,
};

export const Page = ({
  className,
  children,
  classes,
  loading,
}: PAGE_PROPS_TYPE): React.ReactElement<typeof Box> => {
  const usedPage = usePage();
  const token = useSelector<RootStore>((state) => state.session.token);
  return (
    <PageContext.Provider value={usedPage}>
      <Box className={classNames(className, classes?.root, styles.Root)}>
        <Header
          className={classNames(classes?.header)}
        />
        <Container className={classNames(classes?.container, styles.Container)} maxWidth="lg">
          { token && <Drawer /> }
          <Box className={styles?.Content}>
            {children}
            {loading && <Loader className={styles.CircularProgress} />}
          </Box>
        </Container>
        <Footer className={classNames(classes?.footer)} />
      </Box>
    </PageContext.Provider>
  )
};

export default Page;
