// Libraries
import React from 'react';
import classNames from 'classnames';

// Components
import Button from 'components/Button';
import { P } from 'components/Typography';

// Styles
import styles from './Fail.module.scss';

const Fail = (): React.ReactElement<typeof Button> => (
  <P className={styles.Paragraph}>
    Что-то пошло не так, повторите попытку позже.
    <Button
      className={classNames(
        styles.Root,
        styles.Button,
      )}
      href="/questionnaires/efo"
      icon="arrow"
    >
      Повторить еще раз
    </Button>
  </P>
);

// Exports
export default Fail;
