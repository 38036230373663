// Libraries
import React, { Fragment } from 'react';
import { v4 as uuidv4 } from 'uuid';

// Components
import {Paper, Table, TableBody, TableCell, TableHead, TableRow} from '@material-ui/core';
import {H5, Subtitle} from "components/Typography";
import {Input, Table as TableForm, DatePicker} from "components/Form";

// Styles
import styles from '../styles.module.scss';

type GOALS_PROPS_TYPE = {
  error: any,
};

const DATA = ({ error }: GOALS_PROPS_TYPE): React.ReactElement<typeof Fragment> => (
  <div className={styles.FormSegment}>
    <H5
      className={styles.H5}
    >
      ДОЛГОСРОЧНЫЕ ЦЕЛИ И ЗАДАЧИ, гарантирующие достижение наилучших результатов для ребенка вплоть до его совершеннолетия
    </H5>
    <Paper className={styles.Paper}>
      <Input
        className={styles.Input}
        name="goals.long_target"
        label="Долгосрочная цель"
        placeholder="Долгосрочная цель"
        multiline
        rowsMax={6}
        rows={4}
        error={error}
        fullWidth
      />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Потребности развития ребенка</TableCell>
            <TableCell>Задачи</TableCell>
            <TableCell>Ответственное лицо / Учреждение</TableCell>
            <TableCell>Сроки выполнения</TableCell>
            <TableCell>Цели и задачи выполнены /<br /> Достигнут прогресс /<br /> Согласованы изменения</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>Жилье, занятость, доход</TableCell>
            <TableCell>
              <Input
                multiline
                name="goals.long.housing_tasks"
                rowsMax={6}
                rows={4}
              />
            </TableCell>
            <TableCell>
              <Input
                multiline
                name="goals.long.housing_responsible_person"
                rowsMax={6}
                rows={4}
              />
            </TableCell>
            <TableCell>
              <Input
                multiline
                name="goals.long.housing_date"
                rowsMax={6}
                rows={4}
              />
            </TableCell>
            <TableCell>
              <Input
                multiline
                name="goals.long.housing_conclusion"
                rowsMax={6}
                rows={4}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Здоровье и безопасность</TableCell>
            <TableCell>
              <Input
                multiline
                name="goals.long.health_tasks"
                rowsMax={6}
                rows={4}
              />
            </TableCell>
            <TableCell>
              <Input
                multiline
                name="goals.long.health_responsible_person"
                rowsMax={6}
                rows={4}
              />
            </TableCell>
            <TableCell>
              <Input
                multiline
                name="goals.long.health_date"
                rowsMax={6}
                rows={4}
              />
            </TableCell>
            <TableCell>
              <Input
                multiline
                name="goals.long.health_conclusion"
                rowsMax={6}
                rows={4}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Навыки самообслуживания и социальные навыки</TableCell>
            <TableCell>
              <Input
                multiline
                name="goals.long.self_services_tasks"
                rowsMax={6}
                rows={4}
              />
            </TableCell>
            <TableCell>
              <Input
                multiline
                name="goals.long.self_services_responsible_person"
                rowsMax={6}
                rows={4}
              />
            </TableCell>
            <TableCell>
              <Input
                multiline
                name="goals.long.self_services_date"
                rowsMax={6}
                rows={4}
              />
            </TableCell>
            <TableCell>
              <Input
                multiline
                name="goals.long.self_services_conclusion"
                rowsMax={6}
                rows={4}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Образование</TableCell>
            <TableCell>
              <Input
                multiline
                name="goals.long.education_tasks"
                rowsMax={6}
                rows={4}
              />
            </TableCell>
            <TableCell>
              <Input
                multiline
                name="goals.long.education_responsible_person"
                rowsMax={6}
                rows={4}
              />
            </TableCell>
            <TableCell>
              <Input
                multiline
                name="goals.long.education_date"
                rowsMax={6}
                rows={4}
              />
            </TableCell>
            <TableCell>
              <Input
                multiline
                name="goals.long.education_conclusion"
                rowsMax={6}
                rows={4}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Идентичность</TableCell>
            <TableCell>
              <Input
                multiline
                name="goals.long.identity_tasks"
                rowsMax={6}
                rows={4}
              />
            </TableCell>
            <TableCell>
              <Input
                multiline
                name="goals.long.identity_person"
                rowsMax={6}
                rows={4}
              />
            </TableCell>
            <TableCell>
              <Input
                multiline
                name="goals.long.identity_date"
                rowsMax={6}
                rows={4}
              />
            </TableCell>
            <TableCell>
              <Input
                multiline
                name="goals.long.identity_conclusion"
                rowsMax={6}
                rows={4}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Эмоциональное развитие</TableCell>
            <TableCell>
              <Input
                multiline
                name="goals.long.emotional_tasks"
                rowsMax={6}
                rows={4}
              />
            </TableCell>
            <TableCell>
              <Input
                multiline
                name="goals.long.emotional_person"
                rowsMax={6}
                rows={4}
              />
            </TableCell>
            <TableCell>
              <Input
                multiline
                name="goals.long.emotional_date"
                rowsMax={6}
                rows={4}
              />
            </TableCell>
            <TableCell>
              <Input
                multiline
                name="goals.long.emotional_conclusion"
                rowsMax={6}
                rows={4}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Отношения в семье и обществе</TableCell>
            <TableCell>
              <Input
                multiline
                name="goals.long.family_tasks"
                rowsMax={6}
                rows={4}
              />
            </TableCell>
            <TableCell>
              <Input
                multiline
                name="goals.long.family_person"
                rowsMax={6}
                rows={4}
              />
            </TableCell>
            <TableCell>
              <Input
                multiline
                name="goals.long.family_date"
                rowsMax={6}
                rows={4}
              />
            </TableCell>
            <TableCell>
              <Input
                multiline
                name="goals.long.family_conclusion"
                rowsMax={6}
                rows={4}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Поведение</TableCell>
            <TableCell>
              <Input
                multiline
                name="goals.long.behavior_tasks"
                rowsMax={6}
                rows={4}
              />
            </TableCell>
            <TableCell>
              <Input
                multiline
                name="goals.long.behavior_person"
                rowsMax={6}
                rows={4}
              />
            </TableCell>
            <TableCell>
              <Input
                multiline
                name="goals.long.behavior_date"
                rowsMax={6}
                rows={4}
              />
            </TableCell>
            <TableCell>
              <Input
                multiline
                name="goals.long.behavior_conclusion"
                rowsMax={6}
                rows={4}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Paper>
    <H5
      className={styles.H5}
    >
      КРАТКОСРОЧНЫЕ ЦЕЛИ И ЗАДАЧИ ДЛЯ РЕБЕНКА, на 3 или 6 месяцев
    </H5>
    <Paper className={styles.Paper}>
      <Input
        className={styles.Input}
        name="goals.short_target_1"
        label="Краткосрочная цель 1"
        placeholder="Краткосрочная цель 1"
        multiline
        rowsMax={6}
        rows={4}
        error={error}
        fullWidth
      />
      <Input
        className={styles.Input}
        name="goals.short_target_2"
        label="Краткосрочная цель 2"
        placeholder="Краткосрочная цель 2"
        multiline
        rowsMax={6}
        rows={4}
        error={error}
        fullWidth
      />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Потребности развития ребенка</TableCell>
            <TableCell>Задачи</TableCell>
            <TableCell>Ответственное лицо / Учреждение</TableCell>
            <TableCell>Сроки выполнения</TableCell>
            <TableCell>Цели и задачи выполнены /<br /> Достигнут прогресс /<br /> Согласованы изменения</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>Жилье, занятость, доход</TableCell>
            <TableCell>
              <Input
                multiline
                name="goals.short.housing_tasks"
                rowsMax={6}
                rows={4}
              />
            </TableCell>
            <TableCell>
              <Input
                multiline
                name="goals.short.housing_responsible_person"
                rowsMax={6}
                rows={4}
              />
            </TableCell>
            <TableCell>
              <Input
                multiline
                name="goals.short.housing_date"
                rowsMax={6}
                rows={4}
              />
            </TableCell>
            <TableCell>
              <Input
                multiline
                name="goals.short.housing_conclusion"
                rowsMax={6}
                rows={4}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Здоровье и безопасность</TableCell>
            <TableCell>
              <Input
                multiline
                name="goals.short.health_tasks"
                rowsMax={6}
                rows={4}
              />
            </TableCell>
            <TableCell>
              <Input
                multiline
                name="goals.short.health_responsible_person"
                rowsMax={6}
                rows={4}
              />
            </TableCell>
            <TableCell>
              <Input
                multiline
                name="goals.short.health_date"
                rowsMax={6}
                rows={4}
              />
            </TableCell>
            <TableCell>
              <Input
                multiline
                name="goals.short.health_conclusion"
                rowsMax={6}
                rows={4}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Навыки самообслуживания и социальные навыки</TableCell>
            <TableCell>
              <Input
                multiline
                name="goals.short.self_services_tasks"
                rowsMax={6}
                rows={4}
              />
            </TableCell>
            <TableCell>
              <Input
                multiline
                name="goals.short.self_services_responsible_person"
                rowsMax={6}
                rows={4}
              />
            </TableCell>
            <TableCell>
              <Input
                multiline
                name="goals.short.self_services_date"
                rowsMax={6}
                rows={4}
              />
            </TableCell>
            <TableCell>
              <Input
                multiline
                name="goals.short.self_services_conclusion"
                rowsMax={6}
                rows={4}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Образование</TableCell>
            <TableCell>
              <Input
                multiline
                name="goals.short.education_tasks"
                rowsMax={6}
                rows={4}
              />
            </TableCell>
            <TableCell>
              <Input
                multiline
                name="goals.short.education_responsible_person"
                rowsMax={6}
                rows={4}
              />
            </TableCell>
            <TableCell>
              <Input
                multiline
                name="goals.short.education_date"
                rowsMax={6}
                rows={4}
              />
            </TableCell>
            <TableCell>
              <Input
                multiline
                name="goals.short.education_conclusion"
                rowsMax={6}
                rows={4}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Идентичность</TableCell>
            <TableCell>
              <Input
                multiline
                name="goals.short.identity_tasks"
                rowsMax={6}
                rows={4}
              />
            </TableCell>
            <TableCell>
              <Input
                multiline
                name="goals.short.identity_person"
                rowsMax={6}
                rows={4}
              />
            </TableCell>
            <TableCell>
              <Input
                multiline
                name="goals.short.identity_date"
                rowsMax={6}
                rows={4}
              />
            </TableCell>
            <TableCell>
              <Input
                multiline
                name="goals.short.identity_conclusion"
                rowsMax={6}
                rows={4}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Эмоциональное развитие</TableCell>
            <TableCell>
              <Input
                multiline
                name="goals.short.emotional_tasks"
                rowsMax={6}
                rows={4}
              />
            </TableCell>
            <TableCell>
              <Input
                multiline
                name="goals.short.emotional_person"
                rowsMax={6}
                rows={4}
              />
            </TableCell>
            <TableCell>
              <Input
                multiline
                name="goals.short.emotional_date"
                rowsMax={6}
                rows={4}
              />
            </TableCell>
            <TableCell>
              <Input
                multiline
                name="goals.short.emotional_conclusion"
                rowsMax={6}
                rows={4}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Отношения в семье и обществе</TableCell>
            <TableCell>
              <Input
                multiline
                name="goals.short.family_tasks"
                rowsMax={6}
                rows={4}
              />
            </TableCell>
            <TableCell>
              <Input
                multiline
                name="goals.short.family_person"
                rowsMax={6}
                rows={4}
              />
            </TableCell>
            <TableCell>
              <Input
                multiline
                name="goals.short.family_date"
                rowsMax={6}
                rows={4}
              />
            </TableCell>
            <TableCell>
              <Input
                multiline
                name="goals.short.family_conclusion"
                rowsMax={6}
                rows={4}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Поведение</TableCell>
            <TableCell>
              <Input
                multiline
                name="goals.short.behavior_tasks"
                rowsMax={6}
                rows={4}
              />
            </TableCell>
            <TableCell>
              <Input
                multiline
                name="goals.short.behavior_person"
                rowsMax={6}
                rows={4}
              />
            </TableCell>
            <TableCell>
              <Input
                multiline
                name="goals.short.behavior_date"
                rowsMax={6}
                rows={4}
              />
            </TableCell>
            <TableCell>
              <Input
                multiline
                name="goals.short.behavior_conclusion"
                rowsMax={6}
                rows={4}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Subtitle>Ребенок с целями и задачами ознакомлен (если применимо с учетом его возраста и уровня понимания)</Subtitle>
      <Input
        className={styles.Input}
        name="goals.signature_child"
        error={error}
        fullWidth
      />
      <Subtitle>ВАЖНО: обязательно должна быть подпись руководителя, который одобрил список целей и задач.</Subtitle>
    </Paper>
    <H5
      className={styles.H5}
    >
      Ф.И.О. специалиста (специалистов), заполнившего форму
    </H5>
    <Paper className={styles.Paper}>
      <TableForm
        className={styles.Table}
        key="goal.specialists"
        name="specialists"
        label="Ф.И.О. специалиста (специалистов), заполнившего форму"
        placeholder="Ф.И.О. специалиста (специалистов), заполнившего форму"
        creatable
        editable
        columns={[
          {
            Header: 'Ф.И.О',
            accessor: 'fullname',
            component: Input,
            input: {
              className: styles.Input,
              fullWidth: true
            }
          },
          {
            Header: 'Дата',
            accessor: 'date',
            component: DatePicker,
            input: {
              className: styles.Input,
              fullWidth: true
            }
          },
          {
            Header: 'Подпись',
            accessor: 'signature',
            component: Input,
            input: {
              className: styles.Input,
              fullWidth: true
            }
          },
        ]}
        error={error}
      />
    </Paper>
    <H5
      className={styles.H5}
    >
      Ф.И.О. руководителя, участвовавшего в составлении целей и задач для ребенка
    </H5>
    <Paper className={styles.Paper}>
      <TableForm
        className={styles.Table}
        key="goal.heads"
        name="heads"
        label="Ф.И.О. руководителя, участвовавшего в составлении целей и задач для ребенка"
        placeholder="Ф.И.О. руководителя, участвовавшего в составлении целей и задач для ребенка"
        creatable
        editable
        columns={[
          {
            Header: 'Ф.И.О',
            accessor: 'fullname',
            component: Input,
            input: {
              className: styles.Input,
              fullWidth: true
            }
          },
          {
            Header: 'Дата составления списка целей и задач',
            accessor: 'date',
            component: DatePicker,
            input: {
              className: styles.Input,
              fullWidth: true
            }
          },
          {
            Header: 'Подпись',
            accessor: 'signature',
            component: Input,
            input: {
              className: styles.Input,
              fullWidth: true
            }
          },
        ]}
        error={error}
      />
    </Paper>
    <H5
      className={styles.H5}
    >
      Ф.И.О. отца, матери/лиц, их заменяющих, ознакомившихся со списком целей и задач
    </H5>
    <Paper className={styles.Paper}>
      <TableForm
        className={styles.Table}
        key="goal.parents"
        name="parents"
        label="Ф.И.О. отца, матери/лиц, их заменяющих, ознакомившихся со списком целей и задач"
        placeholder="Ф.И.О. отца, матери/лиц, их заменяющих, ознакомившихся со списком целей и задач"
        creatable
        editable
        columns={[
          {
            Header: 'Ф.И.О',
            accessor: 'fullname',
            component: Input,
            input: {
              className: styles.Input,
              fullWidth: true
            }
          },
          {
            Header: 'Дата',
            accessor: 'date',
            component: DatePicker,
            input: {
              className: styles.Input,
              fullWidth: true
            }
          },
          {
            Header: 'Подпись',
            accessor: 'signature',
            component: Input,
            input: {
              className: styles.Input,
              fullWidth: true
            }
          },
        ]}
        error={error}
      />
    </Paper>
  </div>
);

const GOALS = {
  id: uuidv4(),
  name: 'Цели и задачи',
  data: DATA,
};

export default GOALS;
