// Libraries
import React from 'react';
import classNames from 'classnames';
import { Form as BaseForm } from 'redux-form';

// Styles
import styles from './Form.module.scss';

interface FORM_PROPS_TYPES {
  children: any,
  className?: string,
  name?: string,
  classes?: {
    root?: string,
    container?: string,
  },
  autoComplete?: string,
  onSubmit: ((event: React.FormEvent) => void),
};

const Form = ({
  children,
  className,
  classes,
  autoComplete = 'off',
  name,
  onSubmit
}: FORM_PROPS_TYPES): React.ReactElement<typeof BaseForm> => (
  <BaseForm
    className={classNames(
      className,
      classes?.root,
      styles.Root,
    )}
    autoComplete={autoComplete}
    name={name}
    onSubmit={onSubmit}
  >
    <div 
      className={classNames(
        className,
        classes?.container,
        styles.Container,
      )}
    >
      {children}
    </div>
  </BaseForm>
);

export default Form;
