// Libraries
import * as yup from 'yup';

import { asyncValidator } from 'components/Form';

// Config
import { ERROR_FIELD_REQUIRED, ERROR_PASSWORD_MIN } from 'config';

export const validate = (values: object) => ({});

export const asyncValidate = asyncValidator(
  yup.object().shape({
    login: yup.string().required(ERROR_FIELD_REQUIRED),
    password: yup
      .string()
      .min(3, ERROR_PASSWORD_MIN)
      .required(ERROR_FIELD_REQUIRED),
    // .max(32)
  })
);
