// Libraries
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Route, Switch } from 'react-router-dom';
import { get } from 'lodash';

// Components
import List from './List';
import User from './User';

// Services
import { ACTION_TYPE } from 'services/session';

// Views
import Errors from 'views/Errors';

type USERS_PROPS_TYPE = {
  role?: string,
  match: {
    url: string
  }
}


const CAN_USE = ['administrator', 'root'];

const Users = ({ role, match }: USERS_PROPS_TYPE): React.ReactElement<typeof Switch> => (
  <Switch>
    {
      role && CAN_USE.includes(role) && (
        <>
          <Route exact component={List} path={['/users', '/administrators', '/specialists', '/clients']} />
          <Route exact component={User} path={['/users/:id', '/administrators/:id', '/specialists/:id', '/clients/:id']} />
        </>
      )
    }
    <Route component={Errors} path={match.url} />
  </Switch>
);

const mapStateToProps = (state: object): object => ({
  role: get(state, 'session.roles[0].name')
});

const mapDispatchToProps = (dispatch: (action: ACTION_TYPE) => any) => ({
  // Services
});

// Exports
export default compose<USERS_PROPS_TYPE, any>(connect(mapStateToProps, mapDispatchToProps))(Users);
