interface Question {
  readonly statement: string;
}

export const questions: Question[] = [
  {
    statement: 'Я сразу замечаю, когда мой ребенок расстроен.'
  },
  {
    statement: 'Когда мой ребенок обижается, то невозможно понять, по какой причине.'
  },
  {
    statement: 'Когда моему ребенку больно, мне кажется, я тоже чувствую боль.'
  },
  {
    statement: 'Мне часто бывает стыдно за моего ребенка.'
  },
  {
    statement: 'Что бы ни сделал мой ребенок, я буду любить его.'
  },
  {
    statement: 'Воспитание ребенка – сложная проблема для меня.'
  },
  {
    statement: 'Я редко повышаю голос.'
  },
  {
    statement: 'Если часто обнимать и целовать ребенка, можно заласкать его и испортить его характер.'
  },
  {
    statement: 'Я часто даю понять моему ребенку, что верю в его силы.'
  },
  {
    statement: 'Плохое настроение моего ребенка не может быть оправданием его непослушания.'
  },
  {
    statement: 'Я легко могу успокоить моего ребенка.'
  },
  {
    statement: 'Чувства моего ребенка для меня загадка.'
  },
  {
    statement: 'Я понимаю, что мой ребенок может грубить, чтобы скрыть обиду.'
  },
  {
    statement: 'Часто я не могу разделить радость моего ребенка.'
  },
  {
    statement: 'Когда я смотрю на своего ребенка, то испытываю любовь и нежность, даже если он плохо себя ведет.'
  },
  {
    statement: 'Я многое хотел(а) бы изменить в своем ребенке.'
  },
  {
    statement: 'Мне нравится быть матерью (отцом).'
  },
  {
    statement: 'Мой ребенок редко спокойно реагирует на мои требования.'
  },
  {
    statement: 'Я часто глажу моего ребенка по голове.'
  },
  {
    statement: 'Если у ребенка что-то не получается, он должен справиться с этим самостоятельно.'
  },
  {
    statement: 'Я жду пока ребенок успокоится, чтобы объяснить ему, что он не прав.'
  },
  {
    statement: 'Если мой ребенок разбаловался, я уже не могу его остановить.'
  },
  {
    statement: 'Я чувствую отношение моего ребенка к другим людям.'
  },
  {
    statement: 'Часто я не могу понять, почему плачет мой ребенок.'
  },
  {
    statement: 'Мое настроение часто зависит от настроения моего ребенка.'
  },
  {
    statement: 'Мой ребенок часто делает вещи, которые сильно мне досаждают.'
  },
  {
    statement: 'Ничто не мешает мне любить своего ребенка.'
  },
  {
    statement: 'Я чувствую, что задача воспитания слишком сложна для меня.'
  },
  {
    statement: 'Мы с моим ребенком приятно проводим время.'
  },
  {
    statement: 'Я испытываю раздражение, когда ребенок буквально «липнет» ко мне.'
  },
  {
    statement: 'Я часто говорю ребенку, что высоко ценю его старание и достижения.'
  },
  {
    statement: 'Даже когда ребенок устал, он должен довести начатое дело до конца.'
  },
  {
    statement: 'У меня получается настроить ребенка на серьезные занятия.'
  },
  {
    statement: 'Часто мой ребенок кажется мне равнодушным, и я не могу понять, что он чувствует.'
  },
  {
    statement: 'Я всегда понимаю, почему мой ребенок расстроен.'
  },
  {
    statement: 'Когда у меня хорошее настроение, капризы ребенка не могут испортить его.'
  },
  {
    statement: 'Я чувствую, что мой ребенок любит меня.'
  },
  {
    statement: 'Меня удручает, что мой ребенок растет совсем не таким, каким мне хотелось бы.'
  },
  {
    statement: 'Я верю, что могу справиться с большинством ситуаций и проблем в воспитании моего ребенка.'
  },
  {
    statement: 'Часто у нас с ребенком возникает взаимное недовольство.'
  },
  {
    statement: 'Мне часто хочется взять ребенка на руки.'
  },
  {
    statement: 'Я не поощряю мелкие успехи ребенка. Это может избаловать его.'
  },
  {
    statement: 'Бесполезно требовать что-то от ребенка, когда он устал.'
  },
  {
    statement: 'Я не в силах изменить плохое настроение ребенка.'
  },
  {
    statement: 'Мне достаточно только посмотреть на ребенка, чтобы почувствовать его настроение.'
  },
  {
    statement: 'Часто радость ребенка кажется мне беспричинной.'
  },
  {
    statement: 'Я легко заражаюсь весельем моего ребенка.'
  },
  {
    statement: 'Я очень устаю от общения со своим ребенком.'
  },
  {
    statement: 'Я многое прощаю своему ребенку из-за любви к нему.'
  },
  {
    statement: 'Я нахожу, что я гораздо менее способна хорошо заботиться о своем ребенке, чем ожидал(а).'
  },
  {
    statement: 'У меня часто возникают спокойные, теплые отношения с моим ребенком.'
  },
  {
    statement: 'Я редко сажаю ребенка к себе на колени.'
  },
  {
    statement: 'Я часто хвалю своего ребенка.'
  },
  {
    statement: 'Я никогда не нарушаю распорядок дня ребенка.'
  },
  {
    statement: 'Когда мой ребенок устал, я могу переключить его на более спокойное занятие.'
  },
  {
    statement: 'Мне тяжело понять желания моего ребенка.'
  },
  {
    statement: 'Я легко могу догадаться, что беспокоит моего ребенка.'
  },
  {
    statement: 'Иногда я не понимаю, как ребенок может расстраиваться по таким пустякам.'
  },
  {
    statement: 'Я получаю удовольствие от общения с ребенком.'
  },
  {
    statement: 'Не всегда легко принять моего ребенка таким, какой он есть.'
  },
  {
    statement: 'Мне удается научить ребенка, что и как делать.'
  },
  {
    statement: 'Наши занятия с ребенком часто заканчиваются ссорой.'
  },
  {
    statement: 'Мой ребенок любит ко мне прижиматься.'
  },
  {
    statement: 'Я часто бываю строгой (строгим) со своим ребенком.'
  },
  {
    statement: 'Лучше отказаться от посещения гостей, когда ребенок «не в духе».'
  },
  {
    statement: 'Когда ребенок расстроен и ему трудно успокоиться, мне бывает трудно помочь ему.'
  },
];
