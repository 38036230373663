// Libraries
import React from 'react';
import { useLazyQuery } from 'react-apollo';
import { get, join, capitalize } from 'lodash';
import { useHistory } from "react-router-dom";
import { loader } from 'graphql.macro';

// Config
import {
  ERROR_CONNECTION,
  MESSAGES_MAP
} from 'config';

// Types
import { SESSION_TYPE } from 'services/session';

// Data
import { COLUMNS, GET_ACTIONS_CELL } from './data';

// GraphQL
const qbQuery = loader('src/graphql/queries/qb.gql');

export type LIST_PROPS_TYPE = {
  session?: SESSION_TYPE,
  url: string
};

export const useList = ({
  session,
  url
}: LIST_PROPS_TYPE) => {
  const history = useHistory();
  const [data, setData] = React.useState([]);

  const columns = React.useMemo(
    () => COLUMNS,
    []
  );

  const [error, setError] = React.useState<string | boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);

  const [
    handleQBQuery,
    {
      loading: qbQueryLoading,
      data: qbQueryData,
      error: qbQueryError
    }] = useLazyQuery(qbQuery, {
    fetchPolicy: 'no-cache'
  });

  const token = get(session, 'token');

  React.useEffect(() => {
    setLoading(!!qbQueryLoading);
  }, [qbQueryLoading]);

  React.useEffect((): any => {
    !!token && handleQBQuery();
  },[token]);

  React.useEffect((): any => {
    if (!qbQueryData || qbQueryLoading) {
      return;
    }
    const isError = get(qbQueryData, 'qb.__typename') === 'Error';
    const message = get(qbQueryData, 'qb.message');
    const normalizedData = get(qbQueryData, 'qb.data', []).map((qb: object) => ({
      ...qb,
      client: `${get(qb, 'client.surname', '')} ${capitalize(get(qb, 'client.name', '').substr(0,1))}. ${capitalize(get(qb, 'client.patronymic', '').substr(0,1))}.`,
      actions: GET_ACTIONS_CELL({
        ...qb,
        onEdit: () => history.push(`${url}/${get(qb, 'id')}`),
      })
    }));

    const dataError = isError && get(MESSAGES_MAP, `${message}`, message);
    const currentErrors = [
      ...(qbQueryError ? [ERROR_CONNECTION] : []),
      ...(dataError ? [dataError] : []),
    ];
    currentErrors.length > 0 ? setError(join(currentErrors, '\n')) : setData(normalizedData);

  }, [qbQueryData]);

  return {
    loading,
    error,
    data,
    columns,
  };
};

export default useList;
