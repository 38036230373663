// Libraries
import React, { PropsWithChildren } from 'react';
import { v4 as uuidv4 } from 'uuid';
import classNames from 'classnames';
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
  TableOptions,
  TableInstance,
  UseGlobalFiltersInstanceProps,
  UsePaginationInstanceProps,
} from 'react-table';
import { get, set, isFunction, omit } from 'lodash';

// Components
import Box from '@material-ui/core/Box';
import MaUTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';

import PaginationActions from './components/PaginationActions';
import Toolbar from './components/Toolbar';
import AddDialog from './components/AddDialog';

// Styles
import styles from './Table.module.scss';

export interface TABLE_PROPS_TYPES extends TableOptions<{}> {
  className?: string,
  classes?: {
    root?: string
  },
  additional?: {
    toolbar?: React.ReactNode,
    underToolbar?: React.ReactNode
  },
  value?: any,
  creatable?: boolean,
  editable?: boolean,
  onChange?: Function
};

const Table = ({
  className,
  classes,
  columns = [],
  data = [],
  value,
  additional,
  creatable,
  onChange,
  editable,
}: PropsWithChildren<TABLE_PROPS_TYPES>): React.ReactElement<HTMLAnchorElement> => {
  const [open, setOpen] = React.useState<boolean>(false);
  const [picked, setPicked] = React.useState<number>(data.length);

  const normalize = (data: object[]) => data.map((item: object) => omit(item, 'actions'));

  const handleChange = (item: object) => {
    const cloned = [...memoData];
    picked >= 0 ? set(cloned, `[${picked}]`, item) : cloned.push(item);
    const newData = normalize(cloned);
    isFunction(onChange) && onChange(newData);
  } 

  const add = () => {
    setPicked(memoData.length);
    setOpen(true);
  };

  const remove = (index: number) => {
    return () => {
      const newData = [...memoData];
      newData.splice(index, 1);
      isFunction(onChange) && onChange(normalize(newData));
    };
  };

  const pick = (index: number) => {
    return () => {
      setPicked(index);
      setOpen(true);
    };
  };

  const memoColumns = React.useMemo(() => (
    [
      ...(editable ?
        [
          ...columns,
          {
            Header: 'Действия',
            accessor: 'actions',
          }
        ] :
        columns
      )
    ]
  ), [columns]);

  const memoData = React.useMemo(() => (
    (editable ?
      (value || data).map((item: object, index: number) => ({
        ...item,
        actions: (
          <Box>
            <IconButton
              color="inherit"
              className={styles.IconButton}
              onClick={pick(index)} 
            >
              <EditIcon />
            </IconButton>
            <IconButton
              color="inherit"
              className={styles.IconButton}
              onClick={remove(index)}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        ),
      })) :
      (value || data)
    )
  ), [(value || data)]);

  const {
    getTableProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    preGlobalFilteredRows,
    setGlobalFilter,
    state,
  } = useTable(
    {
      columns: memoColumns,
      data: memoData,
      // autoResetPage,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    // useRowSelect,
    // hooks => {
    //   hooks.allColumns.push(columns => [
    //     ...columns,
    //   ])
    // }
  ) as TableInstance<{}> & UseGlobalFiltersInstanceProps<{}> & UsePaginationInstanceProps<{}>;
  const pageIndex = get(state, 'pageIndex');
  const globalFilter = get(state, 'globalFilter');

  const handleChangePage = (event: React.MouseEvent | null, page: number) => {
    gotoPage(page);
  };

  return (
    <TableContainer
      className={classNames(
        className,
        classes?.root,
        styles.Root
      )}
    >
      <Toolbar
        preGlobalFilteredRows={preGlobalFilteredRows}
        setGlobalFilter={setGlobalFilter}
        globalFilter={globalFilter}
      >
        <AddDialog
          open={open}
          onClose={() => setOpen(false)}
          onChange={handleChange}
          fields={memoColumns}
          data={get(memoData, `[${picked}]`)}
        />
        {additional?.toolbar}
      </Toolbar>
      {creatable && (
          <IconButton onClick={add}>
            <AddIcon />
          </IconButton>
      )}
      <div>
        {additional?.underToolbar}
      </div>
      <MaUTable {...getTableProps()}>
        <TableHead>
          {headerGroups.map(headerGroup => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => (
                <TableCell
                  key={uuidv4()}
                  {...(column.id === 'selection'
                    ? column.getHeaderProps()
                    : column.getHeaderProps(column.getSortByToggleProps()))}
                >
                  {column.render('Header')}
                  {column.id !== 'selection' ? (
                    <TableSortLabel
                      active={column.isSorted}
                      // react-table has a unsorted state which is not treated here
                      direction={column.isSortedDesc ? 'desc' : 'asc'}
                    />
                  ) : null}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody>
          {page.map((row: any) => {
            prepareRow(row);
            return (
              <TableRow {...row.getRowProps()}>
                {row.cells.map((cell: any) => {
                  return (
                    <TableCell {...cell.getCellProps()}>
                      {
                        get(cell, 'column.id') === 'gender' ?
                          (get(cell, 'value') === 'female' ? 'ж' : 'м') :
                          cell.render('Cell')
                      }
                    </TableCell>
                  )
                })}
              </TableRow>
            )
          })}
        </TableBody>

        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[]}
              colSpan={memoColumns.length}
              count={data.length}
              rowsPerPage={40}
              page={pageIndex}
              SelectProps={{
                native: true,
              }}
              labelRowsPerPage=''
              onChangePage={handleChangePage}
              ActionsComponent={PaginationActions}
              labelDisplayedRows={({ from, to, count }) => `${from}-${to} из ${count}`}
            />
          </TableRow>
        </TableFooter>
      </MaUTable>
    </TableContainer>
  );
};

export default Table;
