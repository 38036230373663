// Libraries
import React from 'react';

// Components
import { P } from 'components/Typography';
import classNames from 'classnames';

// Styles
import styles from './Informer.module.scss';

// Hooks
import { SignOutContext } from '../hooks';
import Link from "../../../../components/Link";

// Types
type INFORMER_PROPS_TYPE = {
  className?: string,
  classes?: {
    root?: string
  },
};

const Informer = ({
  className,
  classes
}: INFORMER_PROPS_TYPE): React.ReactElement<typeof P> => {
  const {
    error
  } = React.useContext(SignOutContext);

  return (
    <P
      className={classNames(
        className,
        classes?.root,
        styles.Root,
      )}
    >
      {error || 'Пользователь успешно вышел из системы'}
      <br />
      <Link
          className={styles.Link}
          href="/auth/sign-in"
      >
        Войти снова
      </Link>
    </P>
  );
};

// Exports
export default Informer;
