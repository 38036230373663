// Libraries
import React from 'react';
import classNames from 'classnames';
import { get } from 'lodash';

// Components
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import BaseTabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import Loader from 'components/Loader';

// Styles
import styles from './Tabs.module.scss';

// Hooks
import { useTabs } from './hooks';

// Types
import { TabElement as TabI } from 'types/index'

const a11yProps = (index: any) => {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
};

interface TAB_PROPS_TYPES {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const TabPanel = ({ children, value, index, ...other }: TAB_PROPS_TYPES): React.ReactElement<'div'> => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
};

export type TABS_PROPS_TYPES = {
  children?: React.ReactNode,
  classes?: {
    root?: string
  },
  className?: string,
  disabled?: boolean,
  loading?: boolean,
  data?: TabI[]
};

export const Tabs = ({
  children,
  classes = {},
  className,
  disabled,
  loading,
  data = []
}: TABS_PROPS_TYPES): React.ReactElement<typeof Box> => {
  const usedTabs = useTabs({ data });
  const { value, handleChange } = usedTabs;

  return (
    <Box
      className={classNames(
        className,
        classes.root,
        styles.Root,
        {
          [styles.RootIsDisabled]: disabled,
          [styles.RootIsLoading]: loading,
        },
      )}
    >
      <AppBar position="static" color="default">
        <BaseTabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          {
            data.map((item: TabI): JSX.Element =>
              <Tab
                key={get(item, 'id')}
                label={get(item, 'name')}
                disabled={get(item, 'disabled')}
                {...a11yProps(0)}
              />
            )
          }
        </BaseTabs>
      </AppBar>
      {
        data.map((item: object, index: number) =>
          <TabPanel
            key={`${get(item, 'id')}_panel`}
            value={value}
            index={index}
          >
            {get(item, 'data')}
          </TabPanel>
        )
      }
      {loading && <Loader size={24} className={styles.CircularProgress} />}
    </Box>
  );
};

export default Tabs;
