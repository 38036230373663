// Libraries
import React from 'react';
import classNames from 'classnames';
import { Field as BaseField } from 'redux-form';

// Components
// import BaseSelect from 'react-select';
import { Select as BaseSelect, FormControl } from '@material-ui/core';

import Autocomplete from 'components/Autocomplete';

// Styles
import styles from './Select.module.scss';

interface SELECT_PROPS_TYPE {
  className?: string,
  classes?: {
    root?: string,
    select?: string
  },
  input?: any,
  name?: string,
  label?: React.ReactNode,
  validate?: Function[],
  meta?: {
    touched?: boolean,
    error?: string,
    invalid?: boolean
  },
  required?: boolean,
  placeholder?: string,
  mask?: any,
  options?: any,
  fullWidth?: boolean,
  noOptionsText?: string,
  multiple?: boolean,
  src?: string,
  params?: object,
  autoSelect?: boolean,
  error?: string | boolean,
  limitTags?: number,
  normalize?: Function,
}

const Select = ({
  className,
  classes,
  input,
  label,
  meta,
  name,
  required,
  placeholder,
  options = [],
  fullWidth,
  noOptionsText,
  error,
  multiple,
  src,
  params,
  autoSelect,
  limitTags,
  ...props
}: SELECT_PROPS_TYPE): React.ReactElement<typeof BaseSelect> => {
  // console.log('input in select', input);
  return (
    <FormControl
      className={classNames(
        className,
        classes?.root,
        styles.Root,
        {
          [styles.RootIsRequired]: required,
          [styles.RootIsError]: error || (meta?.touched && meta?.invalid)
        }
      )}
    >
      <Autocomplete
        id={`select-${name}`}
        labelId={`select-${name}-label`}
        className={classNames(
          classes?.select,
          styles.Select
        )}
        placeholder={placeholder}
        error={error || (meta?.touched && meta?.invalid)}
        fullWidth={fullWidth}
        multiple={multiple}
        src={src}
        params={params}
        helperText={meta?.touched && meta?.error}
        label={label}
        options={options}
        noOptionsText={noOptionsText}
        autoSelect={autoSelect}
        limitTags={limitTags}
        // {...props}
        {...input}
      >
        {/* <MenuItem value="">
          <em>None</em>
        </MenuItem> */}
        {/* {
          options.map((item: MENU_ITEM) => (
            <MenuItem
              key={item?.id}
              value={item?.value}
            >
              {item?.name}
            </MenuItem>
          ))
        } */}
      </Autocomplete>
    </FormControl>
  );
};

const Field = ({
  name,
  label,
  validate,
  classes,
  ...props
}: SELECT_PROPS_TYPE): React.ReactElement<typeof BaseField> => (
  <BaseField
    name={name}
    component={Select}
    validate={validate}
    label={label}
    // normalize={(data: object) => get(data, 'value')}
    {...props}
  />
);

export default Field;
