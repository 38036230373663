// Libraries
import React from 'react';
import classNames from 'classnames';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

import Container from '@material-ui/core/Container';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import IconButton from '@material-ui/core/IconButton';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';

// Styles
import styles from './Footer.module.scss';

// Config
import { APP_NAME } from 'config';

// Data
const SOCIAL_NETWORKS = [
  {
    id: uuidv4(),
    name: 'Facebook',
    icon: FacebookIcon,
    link: ''
  },
  {
    id: uuidv4(),
    name: 'Twitter',
    icon: TwitterIcon,
    link: ''
  },
  {
    id: uuidv4(),
    name: 'Instagram',
    icon: InstagramIcon,
    link: ''
  }
];

// Types
type FooterTypes = {
  className: String,
};

type ContentTypes = {
}

const Content = (props: ContentTypes): React.ReactElement<typeof Container> => {
  return (
    <Container
      maxWidth="lg"
      classes={{
        root: classNames(styles.Container)
      }}
    >
      <div>
        <BottomNavigationAction
          classes={{
            root: classNames(styles.Item)
          }}
          onClick={(): void => {}}
          label={`© ${moment().year()} ${APP_NAME}`}
          {...props}
        />
      </div>
      <div>
        {
          SOCIAL_NETWORKS.map(item => (
            <IconButton key={item.id}>
              <item.icon />
            </IconButton>
          ))
        }
      </div>
    </Container>
  );
};

const Footer = ({ className }: FooterTypes): React.ReactElement<typeof BottomNavigation> => {
  return (
    <BottomNavigation
      // value={value}
      // onChange={(event, newValue) => {
      //   setValue(newValue);
      // }}
      showLabels
      classes={{
        root: classNames(className, styles.Root)
      }}
    >
      <Content/>
    </BottomNavigation>
  )
};

export default Footer;
