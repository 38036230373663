// Libraries
import React from 'react';

// Components
import FirstPageIcon from '@material-ui/icons/FirstPage';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';

// Styles
import styles from './PaginationActions.module.scss';
import { TablePaginationActionsProps } from '@material-ui/core/TablePagination/TablePaginationActions';

export type PAGINATION_ACTIONS_PROPS_TYPES = {
  count: number,
  page: number,
  rowsPerPage: number,
  onChangePage?: (event: React.MouseEvent, page: number) => void
};

const PaginationActions = ({
  count,
  page,
  rowsPerPage,
  onChangePage = () => {}
}: TablePaginationActionsProps) => {

  const handleFirstPageButton = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => onChangePage(event, 0);

  const handleBackButton = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => onChangePage(event, page - 1);

  const handleNextButton = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => onChangePage(event, page + 1);

  const handleLastPageButton = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));

  return (
    <div className={styles.Root}>
      <IconButton
        onClick={handleFirstPageButton}
        disabled={page === 0}
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton
        onClick={handleBackButton}
        disabled={page === 0}
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButton}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButton}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
      >
        <LastPageIcon />
      </IconButton>
    </div>
  );
};

export default PaginationActions;
