// Libraries
import React from 'react';
import classNames from 'classnames';

// Components
import { H1 } from 'components/Typography';

// Styles
import styles from './Title.module.scss';

// Hooks
import { EFOContext } from '../hooks';

type TITLE_PROPS_TYPE = {
  className?: string,
  classes?: {
    root?: string
  },
};

const Title = ({
  className,
  classes
}: TITLE_PROPS_TYPE): React.ReactElement<typeof H1> => {
  const {
    isNew,
    finished,
    fail,
  } = React.useContext(EFOContext);
  return (
    <H1
      className={classNames(
        className,
        classes?.root,
        styles.Root,
      )}
    > 
      {
        finished || fail ?
        fail ? (
          'Произошла ошибка'
        ) : (
          <>
            Данные обновлены!
          </>
        ):
        (
          <>
            {`${isNew ? 'Создание' : 'Редактирование'} анкеты`}
          </>
        )
      }
    </H1>
  );
};

// Exports
export default Title;
