export type SESSION_TYPE = {
  name?: string,
  surname?: string,
  patronymic?: string,
  email?: string,
  phone?: string,
  birthday?: string,
  gender?: string,
  token?: string,
  error?: string,
  expire?: number,
};

export type ACTION_TYPE = {
  type: string,
  payload?: object
};

export const SIGN_IN = 'SERVICES/SESSION/SIGN_IN';
export const SIGN_OUT = 'SERVICES/SESSION/SIGN_OUT';
export const UPDATE_USER = 'SERVICES/SESSION/UPDATE_USER';
