// Libraries
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { get } from 'lodash';
import classNames from 'classnames';

// Components
import Button from 'components/Button';
import { H1 } from 'components/Typography';
import Table from 'components/Table';

import Page from 'components/Page';

// Styles
import styles from './List.module.scss';

// Services
import { SESSION_TYPE, ACTION_TYPE } from 'services/session';

// Hooks
import { useList } from './hooks';

type LIST_PROPS_TYPE = {
  session?: SESSION_TYPE,
  match: {
    url: string
  }
};

const List = ({
  session,
  match,
  ...props
}: LIST_PROPS_TYPE): React.ReactElement<typeof Page> => {
  const { url } = match;
  const usedList = useList({ session, url });
  const { loading, data, columns } = usedList;
  return (
    <Page
      classes={{
        root: classNames(styles.Root)
      }}
      loading={loading}
    >
      <H1>
        Оценка развития ребенка раннего возраста
      </H1>
      <Table
        columns={columns}
        data={data}
        additional={{
          toolbar: (
            <Button className={styles.Button} href={`${url}/new`}>
              Создать
            </Button>
          )
        }}
      />
    </Page>
  );
};

// Exports
const mapStateToProps = (state: object): object => ({
  session: get(state, 'session')
});

const mapDispatchToProps = (dispatch: (action: ACTION_TYPE) => any) => ({
  // Services
});

// Exports
export default compose<LIST_PROPS_TYPE, any>(connect(mapStateToProps, mapDispatchToProps))(List);
