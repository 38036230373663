// Libraries
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Route, Switch } from 'react-router-dom';
import { get } from 'lodash';

// Components
import List from './List';
import ODREV from './ODREV/Form';

// Services
import { ACTION_TYPE } from 'services/session';

// Views
import Errors from 'views/Errors';

type ODREVS_PROPS_TYPE = {
  role?: string,
  match: {
    url: string
  }
}

const ODREVs = ({ role, match }: ODREVS_PROPS_TYPE): React.ReactElement<typeof Switch> => (
  <Switch>
    <Route exact component={ODREV} path="/questionnaires/odrev/:id" />
    <Route exact component={List} path="/questionnaires/odrev" />
    <Route component={Errors} path={match.url} />
  </Switch>
);

const mapStateToProps = (state: object): object => ({
  role: get(state, 'session.roles[0].name')
});

const mapDispatchToProps = (dispatch: (action: ACTION_TYPE) => any) => ({
  // Services
});

// Exports
export default compose<ODREVS_PROPS_TYPE, any>(connect(mapStateToProps, mapDispatchToProps))(ODREVs);
