// Libraries
import React, { Fragment } from 'react';
import { v4 as uuidv4 } from 'uuid';

// Components
import { Paper } from '@material-ui/core';
import {Input, Select} from "components/Form";
import {H1, H5, Subtitle} from 'components/Typography';
import AnalysisFields from "../../AnalysisFields/AnalysisFields";

// Styles
import styles from '../styles.module.scss';

type HOUSING_PROPS_TYPE = {
  error: any,
};

const DATA = ({ error }: HOUSING_PROPS_TYPE): React.ReactElement<typeof Fragment> => (
  <div className={styles.FormSegment}>
    <H1 className={styles.Title}>Жилье, занятость, доход</H1>
    <H5>Потребности ребёнка</H5>
    <Paper className={styles.Paper}>
      <div className={styles.QuestionContainer}>
        <Subtitle>Есть ли у ребенка стабильное место проживания?</Subtitle>
        <Select
          className={styles.QuestionContainerSelect}
          name="stable_place"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="stable_place_description"
          multiline
          rowsMax={4}
          label="Уточните: жилье собственное жилье, государственное, муниципальное, арендованное и др. детали"
          placeholder="Уточните: жилье собственное жилье, государственное, муниципальное, арендованное и др. детали"
        />
      </div>
      <div className={styles.QuestionContainer}>
        <Subtitle>Часто ли меняется место жительства ребенка?</Subtitle>
        <Select
          className={styles.QuestionContainerSelect}
          name="change_stable_place"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="change_stable_place_description"
          multiline
          rowsMax={4}
          label="Комментарий"
          placeholder="Комментарий"
        />
      </div>
      <div className={styles.QuestionContainer}>
        <Subtitle>Есть ли у ребенка свое место для игр, выполнения домашних заданий, сна?</Subtitle>
        <Select
          className={styles.QuestionContainerSelect}
          name="place_games"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="place_games_description"
          multiline
          rowsMax={4}
          label="В удовлетворительном ли состоянии?"
          placeholder="В удовлетворительном ли состоянии?"
        />
      </div>
      <div className={styles.QuestionContainer}>
        <Subtitle>Есть ли у ребенка личные вещи? (игрушки, книги и т. д.)?</Subtitle>
        <Select
          className={styles.QuestionContainerSelect}
          name="personal_things"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="personal_things_description"
          multiline
          rowsMax={4}
          label="Комментарий"
          placeholder="Комментарий"
        />
      </div>
      <div className={styles.QuestionContainer}>
        <Subtitle>Сбалансировано ли питание ребенка? (отвечает потребностям и возрасту)?</Subtitle>
        <Select
          className={styles.QuestionContainerSelect}
          name="eating"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="eating_description"
          multiline
          rowsMax={4}
          label="Комментарий"
          placeholder="Комментарий"
        />
      </div>
    </Paper>
    <H5>Возможности родителей/лиц, их заменяющих</H5>
    <Paper className={styles.Paper}>
      <div className={styles.QuestionContainer}>
        <Subtitle>Происходят ли регулярные изменения в составе семьи?</Subtitle>
        <Select
          className={styles.QuestionContainerSelect}
          name="changing_family"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="changing_family_description"
          multiline
          rowsMax={4}
          label="Комментарий"
          placeholder="Комментарий"
        />
      </div>
      <div className={styles.QuestionContainer}>
        <Subtitle>Удовлетворительно ли состояние жилья? (учитывайте аварийность, непригодность для проживания, соответствие санитарно-эпидем. нормам, и другое).</Subtitle>
        <Select
          className={styles.QuestionContainerSelect}
          name="housing_condition"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="housing_condition_description"
          multiline
          rowsMax={4}
          label="Какие меры предпринимают родители, чтобы улучшить состояние жилья?"
          placeholder="Какие меры предпринимают родители, чтобы улучшить состояние жилья?"
        />
      </div>
      <div className={styles.QuestionContainer}>
        <Subtitle>Развита ли в месте проживания семьи инфраструктура? (рядом ли образов. учреждение, поликлиника, парк и т. д.)</Subtitle>
        <Select
          className={styles.QuestionContainerSelect}
          name="infrastructure"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="infrastructure_description"
          multiline
          rowsMax={4}
          label="Комментарий"
          placeholder="Комментарий"
        />
      </div>
      <div className={styles.QuestionContainer}>
        <Subtitle>Есть ли работа у родителей/ лиц, их заменяющих? (официально или нет)</Subtitle>
        <Select
          className={styles.QuestionContainerSelect}
          name="working"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="working_description"
          multiline
          rowsMax={4}
          label="Если нет, активно ли они ищут работу?"
          placeholder="Если нет, активно ли они ищут работу?"
        />
      </div>
      <div className={styles.QuestionContainer}>
        <Subtitle>Позволяет ли режим работы заботиться о ребенке?</Subtitle>
        <Select
          className={styles.QuestionContainerSelect}
          name="working_mode"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="working_mode_description"
          multiline
          rowsMax={4}
          label="Если нет, кто в это время забоится о ребенке?"
          placeholder="Если нет, кто в это время забоится о ребенке?"
        />
      </div>
      <div className={styles.QuestionContainer}>
        <Subtitle>Есть ли у семьи другие источники дохода? Постоянны ли они?</Subtitle>
        <Select
          className={styles.QuestionContainerSelect}
          name="salary"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="salary_description"
          multiline
          rowsMax={4}
          label="Комментарий"
          placeholder="Комментарий"
        />
      </div>
      <div className={styles.QuestionContainer}>
        <Subtitle>Востребованы ли семьей льготы, пособия и пр.?</Subtitle>
        <Select
          className={styles.QuestionContainerSelect}
          name="benefit"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="benefit_description"
          multiline
          rowsMax={4}
          label="Комментарий"
          placeholder="Комментарий"
        />
      </div>
      <div className={styles.QuestionContainer}>
        <Subtitle>Есть ли долги и задолженности, почему? Производит ли семья какие-либо выплаты? (кредиты, алименты, др.)</Subtitle>
        <Select
          className={styles.QuestionContainerSelect}
          name="debt"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="debt_description"
          multiline
          rowsMax={4}
          label="Комментарий"
          placeholder="Комментарий"
        />
      </div>
      <div className={styles.QuestionContainer}>
        <Subtitle>Требуется ли семье помощь в получении необходимых документов?</Subtitle>
        <Select
          className={styles.QuestionContainerSelect}
          name="help_document"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="help_document_description"
          multiline
          rowsMax={4}
          label="Если да, укажите каких именно документов нет."
          placeholder="Если да, укажите каких именно документов нет."
        />
      </div>
    </Paper>
    <Paper className={styles.Paper}>
      <Subtitle>Информация о семье проживающей в квартире</Subtitle>
      <div className={styles.PaperContainer}>
        <Input
          className={styles.QuestionContainerInput}
          name="count_member_family"
          label="Кол-во человек в семье"
          placeholder="Кол-во человек в семье"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="housing_description"
          label="count_room_in_flat"
          placeholder="Кол-во комнат"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="total_area_flat"
          label="Общая площадь(кв.м)"
          placeholder="Общая площадь(кв.м)"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="total_area_life_flat"
          label="Жилая площадь(кв.м)"
          placeholder="Жилая площадь(кв.м)"
        />
      </div>
      <Subtitle className={styles.SubtitleMarginTop}>Информация о семье проживающей в комнате(-тах) в коммунальной квартире</Subtitle>
      <div className={styles.PaperContainer}>
        <Input
          className={styles.QuestionContainerInput}
          name="count_room_family"
          label="Кол-во комнат у семьи"
          placeholder="Кол-во комнат у семьи"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="area_common_flat"
          label="Площадь комнат(кв.м)"
          placeholder="Площадь комнат(кв.м)"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="total_area_common_flat"
          label="Общая площадь квартиры(кв.м)"
          placeholder="Общая площадь квартиры(кв.м)"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="count_neighbours"
          label="Кол-во соседей"
          placeholder="Кол-во соседей"
        />
      </div>
      <Subtitle className={styles.SubtitleMarginTop}>Информация о семье проживающей в доме</Subtitle>
      <div className={styles.PaperContainer}>
        <Input
          className={styles.QuestionContainerInput}
          name="total_area_house"
          label="Общая площадь(кв.м)"
          placeholder="Общая площадь(кв.м)"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="count_room_house"
          label="Кол-во комнат"
          placeholder="Кол-во комнат"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="other_details"
          label="Другие детали"
          placeholder="Другие детали"
        />
      </div>
      <Subtitle className={styles.SubtitleMarginTop}>Другое</Subtitle>
      <Input
        className={styles.QuestionContainerInput}
        name="description"
        multiline
        rowsMax={4}
        label="Описание"
        placeholder="Описание"
      />
    </Paper>
    <Paper className={styles.Paper}>
      <div className={styles.PaperContainer}>
        <Input
          className={styles.QuestionContainerInput}
          name="salary_family"
          label="Совокупный доход семьи"
          placeholder="Совокупный доход семьи"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="salary_person"
          label="Доход на человека"
          placeholder="Доход на человека"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="area_for_person"
          label="Площадь на человека(кв.м)"
          placeholder="Площадь на человека(кв.м)"
        />
      </div>
      <Input
        className={styles.QuestionContainerInput}
        name="total_people_in_single_room_with_baby"
        label="Кол-во человек проживающих в одной комнате с ребёнком"
        placeholder="Кол-во соседей"
      />
      <Input
        className={styles.QuestionContainerInput}
        name="who_is_for_baby"
        label="Кем приходятся ребёнку"
        placeholder="Кем приходятся ребёнку"
      />
    </Paper>
    <AnalysisFields title="Жилье, занятость, доход" name="housing" />
  </div>
);

const HOUSING = {
  id: uuidv4(),
  name: 'Жилье',
  data: DATA,
};

export default HOUSING;
