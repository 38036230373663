export const ERROR_CONNECTION = 'Ошибка соединения, попробуйте позже.';

export const ERROR_FIELD_REQUIRED = 'Обязательное поле.';
export const ERROR_FIELD_NUMBER = 'Допустимы только цифры.';

export const ERROR_LOGIN_FORMAT = 'Номер телефона или e-mail введен некорректно.';

export const ERROR_DATE_ADULT = 'Для доступа к сайту тебе должно быть больше 18 лет.';
export const ERROR_DATE_FORMAT = 'Дата должна быть в формате дд.мм.гггг.';

export const ERROR_EMAIL_FORMAT = 'E-mail должен быть в формате ivanov@example.ru.';

export const ERROR_PHONE_FORMAT = 'Номер телефона должен быть в формате +7XXXXXXXXXX.';

export const ERROR_PASSWORD_CHANGE = 'Ошибка при смене пароля.';
export const ERROR_PASSWORD_MIN = 'Пароль должен содержать минимум 3 символа.';
export const ERROR_PASSWORD_EQUAL = 'Пароли не совпадают.';

export const ERROR_PIN = 'Код указан неверно..';
export const ERROR_PIN_REFETCH = `Запросите код повторно.`;
export const ERROR_PIN_RETRY = 'Попробуйте ввести еще раз.';
export const ERROR_PIN_EXPIRED = 'Срок пин-кода истёк.';

export const ERROR_INCORRECT_DATA = 'Введены некорректные данные.';
export const ERROR_BAD_CREDENTIALS = ERROR_INCORRECT_DATA;
export const ERROR_NOT_REGISTERED = 'Такой e-mail не зарегистрирован.';
export const ERROR_NOT_REGISTERED_PHONE = 'Такой номер не зарегистрирован.';
export const ERROR_RESTORE_FAILED = 'Ошибка восстановления.';

export const ERROR_IS_VERIFIED = 'Уже верифицирован.';
export const ERROR_WRONG_TYPE_OR_STATE = 'Неверный тип или статус.';
export const ERROR_SOCIAL_IS_REQUIRED = 'Требуется логин в соц.сетях.';

export const ERROR_BAD_TOKEN = 'Неверный токен.';
export const ERROR_EMPTY_TOKEN = 'Пустой токен.';

export const ERROR_IS_REGISTERED = 'Такой e-mail уже зарегистрирован.';
export const ERROR_IS_REGISTERED_PHONE = 'Такой номер уже зарегистрирован.';

export const ERROR_MANY_ATTEMPTS = 'Превышен лимит запроса данных. Повторите попытку позже.';

export const ERROR_FILE_MAX_SIZE = 'Максимальный размер файла не должен превышать 5Мб.';
export const ERROR_FILE_INCORRECT = 'Необходимо указать корректный файл.';

export const ERROR_USER_BLOCKED = 'Пользователь заблокирован';

export const MESSAGES_MAP = {
  'Invalid verification token': ERROR_PIN,
  'User restore failed': ERROR_RESTORE_FAILED,
  'User not found': ERROR_INCORRECT_DATA,
  'User blocked': ERROR_USER_BLOCKED,
  'Pin code expired': ERROR_PIN_EXPIRED,
  validation_error: ERROR_INCORRECT_DATA,
  already_verified: ERROR_IS_VERIFIED,
  wrong_type_or_state: ERROR_WRONG_TYPE_OR_STATE,
  social_is_required: ERROR_SOCIAL_IS_REQUIRED,
  bad_token: ERROR_BAD_TOKEN,
  bad_user_token: ERROR_BAD_TOKEN,
  'Invalid username or password': ERROR_BAD_CREDENTIALS,
  'Invalid username or pin': ERROR_BAD_CREDENTIALS,
  bad_credentials: ERROR_BAD_CREDENTIALS,
  is_registered: ERROR_IS_REGISTERED,
  'The input.email has already been taken.': ERROR_IS_REGISTERED,
  'The input.phone has already been taken.': ERROR_IS_REGISTERED_PHONE,
  is_registered_phone: ERROR_IS_REGISTERED_PHONE,
  'token is empty': ERROR_EMPTY_TOKEN,
  too_many_attempts: ERROR_MANY_ATTEMPTS
};
