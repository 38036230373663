// Libraries
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { get } from 'lodash';
import classNames from 'classnames';

// Components
import Page from 'components/Page';
import Title from './components/Title';

import Informer from './components/Informer';
import Wizard from './components/Wizard';

// Styles
import styles from './Profile.module.scss';

// Services
import { SESSION_TYPE, ACTION_TYPE } from 'services/session';

// Hooks
import { useProfile, ProfileContext } from './hooks';

type SIGN_UP_PROPS_TYPE = {
  session?: SESSION_TYPE,
};

const Profile = ({
  session
}: SIGN_UP_PROPS_TYPE): React.ReactElement<typeof Page> => {
  const usedProfile = useProfile({ limit: 2, session });
  const { loading } = usedProfile;

  return (
    <>
      <Page
        classes={{
          root: classNames(styles.Root)
        }}
        loading={loading}
      >
        
        <div className={styles.Wrapper}>
          <ProfileContext.Provider value={usedProfile}>
            <Title className={styles.Title} />
            <div className={styles.Actions}>
              <Informer className={styles.Informer} />
              <Wizard />
            </div>
          </ProfileContext.Provider>
        </div>
      </Page>
    </>
  );
};

// Exports
const mapStateToProps = (state: object): object => ({
  session: get(state, 'session')
});

const mapDispatchToProps = (dispatch: (action: ACTION_TYPE) => any) => ({
  // Services
});

// Exports
export default compose<SIGN_UP_PROPS_TYPE, any>(connect(mapStateToProps, mapDispatchToProps))(Profile);
