// Libraries
import * as yup from 'yup';

import { asyncValidator } from 'components/Form';

// Config
import { ERROR_FIELD_REQUIRED, ERROR_FIELD_NUMBER } from 'config';

export const validate = (values: object) => ({});

export const asyncValidate = asyncValidator(
  yup.object().shape({
    pin: yup.number().typeError(ERROR_FIELD_NUMBER).required(ERROR_FIELD_REQUIRED)
  })
);
