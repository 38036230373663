// Types
import {Touchstone} from "types";

export const data: Touchstone[] = [
  {
    title: 'Ресурсы семьи формальные (супруг(-а), бабушки, дедушки,  дяди, тети)',
    description: '<b>Ресурсы семьи формальные</b> – наличие родственников, которые оказывают поддержку, ' +
      'помогают в воспитании ребенка'
  },
  {
    title: 'Ресурсы семьи неформальные (знакомые, друзья, соседи)',
    description: '<b>Ресурсы семьи неформальные</b> - наличие у родителей сети поддержки, отсутствие социальной исключённости'
  },
  {
    title: 'Ресурсы сообщества (образовательные, социальные, медицинские)',
    description: '<b>Ресурсы сообщества</b> – члены семьи знают и могут пользоваться услугами для детей и семей'
  },
  {
    title: 'Материальные ресурсы семьи  (пособия, зарплата, дополнительные траты на жилье, реабилитацию и т.д.)',
    description: '<b>Материальные ресурсы семьи</b>  - у членов семьи есть постоянный доход, который позволяет обеспечивать' +
      ' свои нужды и нужды ребенка, нет больших дополнительных расходов (кредиты, выплаты), есть постоянное местожительства'
  },
  {
    title: 'Эмоциональное состояние  ухаживающего взрослого (здоровье, физические возможности)',
    description: '<b>Эмоциональное состояние  ухаживающего взрослого</b> – наличие стресса, депрессии, подавленного ' +
      'эмоционального состояния у членов семьи, ухаживающий взрослый оценивает себя как уставшего, негативно оценивают свою жизнь'
  },
  {
    title: 'Физические состояние ребенка (здоровье,  возможность самообслуживания,  развития)',
    description: '<b>Физические состояние ребенка</b> – тяжесть заболевания ребенка, степень ограничения функционирования'
  },
  {
    title: 'Сложность ухода за ребенком',
    description: '<b>Сложность ухода за ребенком</b> - состояние требует постоянного внимания родителей, состояние ухудшается,' +
      ' частые приступы, отсутствует самообслуживание, передвижение, серьезные поведенческие нарушения, родитель не ' +
      'справляется с уходом'
  },
  {
    title: 'Дополнительные факторы (не один ребенок с инвалидностью,  второй ребенок до 1,5 лет,  многодетная семья)',
    description: '<b>Дополнительные факторы</b> – есть другие члены семьи, требующие, постоянного внимания, у семьи' +
      ' существуют социальные или другие сложности'
  },
];
