// Libraries
import React from "react";

// Components
import {Page} from "components/Page";
import {H1, H5, Subtitle} from "components/Typography";
import {List, ListItem, Paper} from "@material-ui/core";
import {Input, DatePicker} from "components/Form";
import { ListItemText } from "@material-ui/core";
import Questions from "../../Questions/Questions";

// Styles
import styles from "./styles.module.scss";

// Data
import { questions } from "./data";

const ODREV = (): JSX.Element => (
  <Page
    classes={{
      root: styles.Root
    }}
  >
    <H1 className={styles.Title}>Бланк опросника ОДРЭВ</H1>
    <Paper className={styles.Paper}>
      <Input
        className={styles.Input}
        name="odrev.fio"
        label="Ф.И.О"
        placeholder="Ф.И.О"
        fullWidth
      />
      <Input
        className={styles.Input}
        name="odrev.name_age_child"
        label="Имя и возраст ребенка (детей)"
        placeholder="Имя и возраст ребенка (детей)"
        fullWidth
      />
      <DatePicker
        className={styles.Input}
        name="odrev.date"
        label="Дата анкетирования"
        placeholder="Дата анкетирования"
        fullWidth
      />
    </Paper>
    <H5 className={styles.H5}>Уважаемые родители!</H5>
    <Subtitle className={styles.TextCenter}>
      Оцените справедливость следующих утверждений, используя пятибалльную шкалу:
    </Subtitle>
    <List
      dense
    >
      <ListItem>
        <ListItemText primary="5 – абсолютно верно;" />
        <ListItemText primary="4 – скорее всего это так;" />
        <ListItemText primary="3 – в некоторых случаях верно;" />
        <ListItemText primary="2 – не совсем верно;" />
        <ListItemText primary="1 – абсолютно неверно." />
      </ListItem>
    </List>
    <Questions questions={questions} name="odrev" />
  </Page>
)

export default ODREV;
