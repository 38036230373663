import { useEffect, useContext } from 'react';
import { useMutation } from 'react-apollo';
import validator from 'validator';
import { get, replace, capitalize, join } from 'lodash';
import { loader } from 'graphql.macro';
import moment from 'moment';

// Hooks
import { SignUpContext } from '../../../../hooks';

// Config
import { SERVER_ERRORS_HANDLER, ERROR_CONNECTION, MESSAGES_MAP } from 'config';

// Types
import { SIGN_IN_TYPE } from 'services/session';

// GraphQL
const signUpQuery = loader('src/graphql/mutations/signUp.gql');

type USE_STEP_PROPS_TYPE = {
  formValues?: object,
  signIn: SIGN_IN_TYPE,
};

export const useStep = ({
  formValues,
  signIn
}: USE_STEP_PROPS_TYPE) => {
  const {
    loading,
    step,
    values,
    finished,
    limit,
    error,
    fail,
    handleBack,
    handleNext,
    handleError,
    handleFail,
    handleLoading
  } = useContext(SignUpContext);

  const [
    handleSignUpQuery,
    signUpQueryResult
  ] = useMutation(
    signUpQuery,
    {
      fetchPolicy: 'no-cache',
      onError: (error: any) => SERVER_ERRORS_HANDLER(error, (error?: any) => handleFail(!!error))
    }
  );

  const { loading: signUpQueryLoading, data: signUpQueryData, error: signUpQueryError } = signUpQueryResult;

  useEffect(() => {
    handleLoading(!!signUpQueryLoading);
  }, [signUpQueryLoading]);

  useEffect(() => {

    if (!signUpQueryData || loading) {
      return;
    }
    const isError = get(signUpQueryData, 'signUp.__typename') === 'Error';
    const message = get(signUpQueryData, 'signUp.message');

    const dataError = isError && get(MESSAGES_MAP, `${message}`, message);
    const currentErrors = [
      ...(signUpQueryError ? [ERROR_CONNECTION] : []),
      ...(dataError ? [dataError] : []),
    ];
    currentErrors.length <= 0
      ? handleNext({ errors: false })
      : handleError(join(currentErrors, '\n'));
  }, [loading, signUpQueryData, signUpQueryError]);

  const handleSubmit = (event: React.SyntheticEvent) => {
    get(event, 'preventDefault') && event.preventDefault();
    const login = replace(
      get(formValues, 'login', get(values, 'login')),
      / /g,
      ''
    );
    const isPhone =
      login && validator.isMobilePhone(login, 'ru-RU', { strictMode: false });
    const name = capitalize(get(formValues, 'name', get(values, 'name', '')));
    const surname = capitalize(get(formValues, 'surname', get(values, 'surname', '')));
    const patronymic = capitalize(get(formValues, 'patronymic', get(values, 'patronymic', '')));
    const birthday = get(formValues, 'birthday', get(values, 'birthday', ''));
    const gender = get(formValues, 'gender', get(values, 'gender', ''));
    const password = get(formValues, 'password', '');
    const confirmation = get(formValues, 'confirmation', '');

    const stepValues = {
      [isPhone ? 'phone' : 'email'] : login,
      name,
      surname,
      patronymic,
      birthday: moment(birthday).format('YYYY-MM-DD'),
      gender,
      password,
      confirmation
    };
    return handleSignUpQuery({ variables: { ...stepValues } });
  };

  return {
    loading,
    step,
    finished,
    limit,
    error,
    fail,
    handleBack,
    handleNext,
    handleError,
    handleFail,
    handleLoading,
    handleSubmit
  };
};

export default useStep;
