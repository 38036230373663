// Libraries
import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { get } from 'lodash';
import { Route, Switch } from 'react-router-dom';

// Components
import SignIn from './SignIn';
import SignUp from './SignUp';
import SignOut from './SignOut';
import Reset from './Reset';
import Profile from './Profile';

// Views
import Errors from 'views/Errors';

// Services
import { SESSION_TYPE, ACTION_TYPE } from 'services/session';

type AUTH_PROPS_TYPE = {
  session?: SESSION_TYPE,
  location: {
    pathname: string
  },
  match: {
    url: string
  }
};

const Auth = ({ session, location, match }: AUTH_PROPS_TYPE): React.ReactElement<typeof Switch> => (
  <Switch>
    <Route exact component={SignIn} path="/auth/sign-in" />
    <Route exact component={SignUp} path="/auth/sign-up" />
    <Route exact component={SignOut} path="/auth/sign-out" />
    <Route exact component={Reset} path="/auth/reset" />
    { get(session, 'token') && <Route exact component={Profile} path="/auth/profile" /> }
    <Route component={Errors} path={match.url} />
  </Switch>
);

// Exports
const mapStateToProps = (state: object): object => ({
  session: get(state, 'session')
});

const mapDispatchToProps = (dispatch: (action: ACTION_TYPE) => any) => ({
  // Services
});

// Exports
export default compose<AUTH_PROPS_TYPE, any>(connect(mapStateToProps, mapDispatchToProps))(Auth);
