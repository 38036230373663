// Libraries
import React from 'react';
import classNames from 'classnames';
import { Field as BaseField } from 'redux-form';
import { get, isFunction } from 'lodash';

// Components
import BaseTable from 'components/Table';

// Styles
import styles from './Table.module.scss';

interface TABLE_PROPS_TYPE {
  className?: string,
  classes?: {
    root?: string,
    table?: string
  },
  input?: any,
  name?: string,
  label?: React.ReactNode,
  validate?: Function[],
  meta?: {
    touched?: boolean,
    error?: string,
    invalid?: boolean
  },
  required?: boolean,
  placeholder?: string,
  columns?: any,
  creatable?: boolean,
  error?: string | boolean,
  editable?: boolean,
  onCreate?: Function
}

const Table = ({
  className,
  classes,
  input,
  label,
  meta,
  name,
  required,
  placeholder,
  columns,
  error,
  creatable,
  onCreate,
  editable,
  ...props
}: TABLE_PROPS_TYPE): React.ReactElement<typeof BaseTable> => {
  const value = get(input, 'value');
  const onChange = get(input, 'onChange');
  const handleChange = (add: object) => {
    isFunction(onChange) && onChange(value);
    // isFunction(onCreate) && onCreate(add);
  };
  return (
    <BaseTable
      className={classNames(
        className,
        classes?.root,
        styles.Root,
        {
          [styles.RootIsRequired]: required,
          [styles.RootIsError]: error || (meta?.touched && meta?.invalid)
        }
      )}
      placeholder={placeholder}
      error={error || (meta?.touched && meta?.invalid)}
      columns={columns}
      creatable={creatable}
      onChange={handleChange}
      editable={editable}
      {...input}
    />
  );
};

const Field = ({
  name,
  label,
  validate,
  classes,
  ...props
}: TABLE_PROPS_TYPE): React.ReactElement<typeof BaseField> => (
  <BaseField
    name={name}
    component={Table}
    validate={validate}
    label={label}
    {...props}
  />
);

export default Field;
