// Libraries
import React, { Fragment } from 'react';
import { v4 as uuidv4 } from 'uuid';

// Components
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@material-ui/core';
import {Checkbox, Input} from "components/Form";
import {H5, Subtitle} from "components/Typography";

// Styles
import styles from '../styles.module.scss';

type CONCLUSIONS_PROPS_TYPE = {
  error: any,
};

const DATA = ({ error }: CONCLUSIONS_PROPS_TYPE): React.ReactElement<typeof Fragment> => (
  <div className={styles.FormSegment}>
    <TableContainer className={styles.Table} component={Paper}>
      <Table>
        <TableHead>
          <TableCell>Уровень функционирования семьи<br />Раздел ЕФО</TableCell>
          <TableCell align="center">Кризисный</TableCell>
          <TableCell align="center">Неблагополучный</TableCell>
          <TableCell align="center">Удовлетворительный</TableCell>
          <TableCell align="center">Благополучный</TableCell>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>1. Жилье, занятость, доход</TableCell>
            <TableCell align="center">
              <Checkbox
                className={styles.CheckBox}
                name="conclusion.housing_recessionary"
              />
            </TableCell>
            <TableCell align="center">
              <Checkbox
                className={styles.CheckBox}
                name="conclusion.housing_dysfunctional"
              />
            </TableCell>
            <TableCell align="center">
              <Checkbox
                className={styles.CheckBox}
                name="conclusion.housing_comfortable"
              />
            </TableCell>
            <TableCell align="center">
              <Checkbox
                className={styles.CheckBox}
                name="conclusion.housing_Prosperous"
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>2. Здоровье и безопасность</TableCell>
            <TableCell align="center">
              <Checkbox
                className={styles.CheckBox}
                name="conclusion.health_recessionary"
              />
            </TableCell>
            <TableCell align="center">
              <Checkbox
                className={styles.CheckBox}
                name="conclusion.health_dysfunctional"
              />
            </TableCell>
            <TableCell align="center">
              <Checkbox
                className={styles.CheckBox}
                name="conclusion.health_comfortable"
              />
            </TableCell>
            <TableCell align="center">
              <Checkbox
                className={styles.CheckBox}
                name="conclusion.health_Prosperous"
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>3. Навыки самообслуживания и социальные навыки</TableCell>
            <TableCell align="center">
              <Checkbox
                className={styles.CheckBox}
                name="conclusion.self_service_recessionary"
              />
            </TableCell>
            <TableCell align="center">
              <Checkbox
                className={styles.CheckBox}
                name="conclusion.self_service_dysfunctional"
              />
            </TableCell>
            <TableCell align="center">
              <Checkbox
                className={styles.CheckBox}
                name="conclusion.self_service_comfortable"
              />
            </TableCell>
            <TableCell align="center">
              <Checkbox
                className={styles.CheckBox}
                name="conclusion.self_service_Prosperous"
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>4. Образование</TableCell>
            <TableCell align="center">
              <Checkbox
                className={styles.CheckBox}
                name="conclusion.education_recessionary"
              />
            </TableCell>
            <TableCell align="center">
              <Checkbox
                className={styles.CheckBox}
                name="conclusion.education_dysfunctional"
              />
            </TableCell>
            <TableCell align="center">
              <Checkbox
                className={styles.CheckBox}
                name="conclusion.education_comfortable"
              />
            </TableCell>
            <TableCell align="center">
              <Checkbox
                className={styles.CheckBox}
                name="conclusion.education_Prosperous"
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>5. Идентичность</TableCell>
            <TableCell align="center">
              <Checkbox
                className={styles.CheckBox}
                name="conclusion.identity_recessionary"
              />
            </TableCell>
            <TableCell align="center">
              <Checkbox
                className={styles.CheckBox}
                name="conclusion.identity_dysfunctional"
              />
            </TableCell>
            <TableCell align="center">
              <Checkbox
                className={styles.CheckBox}
                name="conclusion.identity_comfortable"
              />
            </TableCell>
            <TableCell align="center">
              <Checkbox
                className={styles.CheckBox}
                name="conclusion.identity_Prosperous"
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>6. Эмоциональное развитие</TableCell>
            <TableCell align="center">
              <Checkbox
                className={styles.CheckBox}
                name="conclusion.emotional_recessionary"
              />
            </TableCell>
            <TableCell align="center">
              <Checkbox
                className={styles.CheckBox}
                name="conclusion.emotional_dysfunctional"
              />
            </TableCell>
            <TableCell align="center">
              <Checkbox
                className={styles.CheckBox}
                name="conclusion.emotional_comfortable"
              />
            </TableCell>
            <TableCell align="center">
              <Checkbox
                className={styles.CheckBox}
                name="conclusion.emotional_Prosperous"
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>7. Отношения в семье и обществе</TableCell>
            <TableCell align="center">
              <Checkbox
                className={styles.CheckBox}
                name="conclusion.family_recessionary"
              />
            </TableCell>
            <TableCell align="center">
              <Checkbox
                className={styles.CheckBox}
                name="conclusion.family_dysfunctional"
              />
            </TableCell>
            <TableCell align="center">
              <Checkbox
                className={styles.CheckBox}
                name="conclusion.family_comfortable"
              />
            </TableCell>
            <TableCell align="center">
              <Checkbox
                className={styles.CheckBox}
                name="conclusion.family_Prosperous"
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>8. Поведение</TableCell>
            <TableCell align="center">
              <Checkbox
                className={styles.CheckBox}
                name="conclusion.behavior_recessionary"
              />
            </TableCell>
            <TableCell align="center">
              <Checkbox
                className={styles.CheckBox}
                name="conclusion.behavior_dysfunctional"
              />
            </TableCell>
            <TableCell align="center">
              <Checkbox
                className={styles.CheckBox}
                name="conclusion.behavior_comfortable"
              />
            </TableCell>
            <TableCell align="center">
              <Checkbox
                className={styles.CheckBox}
                name="conclusion.behavior_Prosperous"
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
    <H5
      className={styles.H5}
    >
      Основные выводы по профилю уровня функционирования семьи
    </H5>
    <Paper className={styles.Paper}>
      <Subtitle>
        Основные выводы по профилю функционирования семьи должны включать выделение областей (по разделам оценки),
        в которых семья функционирует успешно (т. е. сильных сторон), и областей, где требуется вмешательство.
        Укажите, что необходимо предпринять для обеспечения благополучия ребенка; выделите, что нужно сделать
        в первую очередь, что может быть выполнено позже.
      </Subtitle>
      <Input
        className={styles.QuestionContainerInput}
        name="conclusion.main_conclusion"
        multiline
        rowsMax={6}
        rows={4}
        fullWidth
      />
    </Paper>
    <H5
      className={styles.H5}
    >
      Каково мнение ребенка о выводах, сделанных в результате оценки (с учетом возраста и уровня развития)?
    </H5>
    <Paper className={styles.Paper}>
      <Input
        className={styles.QuestionContainerInput}
        name="conclusion.conclusion_child"
        multiline
        rowsMax={6}
        rows={4}
        fullWidth
      />
    </Paper>
    <H5
      className={styles.H5}
    >
      Каково мнение родителя/родителей о выводах, сделанных в результате оценки?
    </H5>
    <Paper className={styles.Paper}>
      <Input
        className={styles.QuestionContainerInput}
        name="conclusion.conclusion_parent"
        multiline
        rowsMax={6}
        rows={4}
        fullWidth
      />
    </Paper>
    <H5
      className={styles.H5}
    >
      Каково мнение законных представителей ребенка (если не родители), о выводах, сделанных в результате оценки?
    </H5>
    <Paper className={styles.Paper}>
      <Input
        className={styles.QuestionContainerInput}
        name="conclusion.conclusion_legal_representative"
        multiline
        rowsMax={6}
        rows={4}
        fullWidth
      />
    </Paper>
    <H5
      className={styles.H5}
    >
      Другие комментарии
    </H5>
    <Paper className={styles.Paper}>
      <Input
        className={styles.QuestionContainerInput}
        name="conclusion.other_comments"
        multiline
        rowsMax={6}
        rows={4}
        fullWidth
      />
    </Paper>
  </div>
);

const CONCLUSIONS = {
  id: uuidv4(),
  name: 'Выводы',
  data: DATA,
};

export default CONCLUSIONS;
