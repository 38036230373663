// Libraries
import React from "react";
import {reduxForm} from "redux-form";
import {get} from "lodash";
import {compose} from "recompose";
import {connect} from "react-redux";

// Components
import BaseForm from "components/Form/Form";
import PSS from "./PSS";

// Services
import {ACTION_TYPE, SESSION_TYPE, signIn} from "services/session";

const Form = (): JSX.Element => {
  const onSubmit = (values: any) => {
    console.log("values", values);
  };

  return (
    <BaseForm
      onSubmit={onSubmit}
      name="pss"
      autoComplete="off"
    >
      <PSS />
    </BaseForm>
  )
};

const pssForm = reduxForm<object, any>({
  form: "pssForm",
  touchOnChange: true,
  enableReinitialize: true
})(Form);

const mapStateToProps = (state: object): object => ({
  session: get(state, 'session'),
  genders: get(state, 'options.genders'),
  roles: get(state, 'options.roles'),
  clientsStatuses: get(state, 'options.clients_statuses'),
  // formValues: get(state, 'form.user.values'),
  // initialValues: {
  //   actions: [null]
  // },
});

const mapDispatchToProps = (dispatch: (action: ACTION_TYPE) => any) => ({
  // Services
  signIn: (user: SESSION_TYPE) => dispatch(signIn(user)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(pssForm);
