// Libraries
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { get } from 'lodash';
import classNames from 'classnames';

// Components
import Page from 'components/Page';
import Title from './components/Title';

import Wizard from './components/Wizard';

// Styles
import styles from './User.module.scss';

// Services
import { SESSION_TYPE, ACTION_TYPE } from 'services/session';

// Data
import { PATHS_MAP } from './data';

// Hooks
import { useUser, UserContext } from './hooks';

type USER_PROPS_TYPE = {
  session?: SESSION_TYPE,
  match: {
    url: string,
    path: string,
  }
};

const User = ({
  session,
  match,
}: USER_PROPS_TYPE): React.ReactElement<typeof Page> => {
  const { path } = match;
  const { title } = get(PATHS_MAP, `['${path.split('/:id')[0]}']`, PATHS_MAP['/users']);
  const usedUser = useUser({ session, title });
  const { loading } = usedUser;

  return (
    <>
      <Page
        classes={{
          root: classNames(styles.Root)
        }}
        loading={loading}
      >
        <div className={styles.Wrapper}>
          <UserContext.Provider value={usedUser}>
            <Title className={styles.Title} />
            <div className={styles.Actions}>
              <Wizard />
            </div>
          </UserContext.Provider>
        </div>
      </Page>
    </>
  );
};

// Exports
const mapStateToProps = (state: object): object => ({
  session: get(state, 'session')
});

const mapDispatchToProps = (dispatch: (action: ACTION_TYPE) => any) => ({
  // Services
});

// Exports
export default compose<USER_PROPS_TYPE, any>(connect(mapStateToProps, mapDispatchToProps))(User);
