// Libraries
import React from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { get } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

// Components
import Link from 'components/Link';
import Button from 'components/Button';
import { P } from 'components/Typography';
import BaseForm, { DatePicker, Input, Checkbox, Radio } from 'components/Form';

// Styles
import styles from './styles.module.scss';

// Services
import { signIn, SESSION_TYPE, SIGN_IN_TYPE, ACTION_TYPE } from 'services/session';

// Hooks
import { useStep } from './hooks';

// Data
import { validate, asyncValidate } from './validators';

const GENDERS = [
  {
    id: uuidv4(),
    name: 'мужской',
    value: 'male'
  },
  {
    id: uuidv4(),
    name: 'женский',
    value: 'female'
  }
];

type FORM_PROPS_TYPE = {
  valid?: boolean,
  asyncValidating?: boolean | string,
  anyTouched?: boolean,
  formValues?: object,
  signIn: SIGN_IN_TYPE
  handleSubmit: Function
};

const Form = ({
  valid,
  anyTouched,
  asyncValidating,
  formValues,
  signIn,
  handleSubmit: formSubmit
}: FORM_PROPS_TYPE): React.ReactElement<typeof BaseForm> => {
  const { error, handleSubmit } = useStep({
    formValues,
    signIn,
  });
  return (
    <BaseForm
      className={styles.Form}
      onSubmit={formSubmit(handleSubmit)}
      name="signUp"
      autoComplete="off"
    >
      <Input
        className={styles.Input}
        key="name"
        label="Имя"
        name="name"
        placeholder="Имя"
        required
        error={error}
      />
      <Input
        className={styles.Input}
        key="surname"
        label="Фамилия"
        name="surname"
        placeholder="Фамилия"
        required
        error={error}
      />
      <Input
        className={styles.Input}
        key="patronymic"
        label="Отчество"
        name="patronymic"
        placeholder="Отчество"
        required
        error={error}
      />
      <DatePicker
        className={styles.Input}
        key="birthday"
        label="Дата рождения"
        name="birthday"
        placeholder="Дата рождения"
        disableFuture
        maxDate={moment().subtract('18', 'years').toDate()}
        required
      />
      <Input
        className={styles.Input}
        key="login"
        label="E-mail или телефон"
        name="login"
        placeholder="E-mail или телефон"
        required
        error={error}
      />
      <Radio 
        label="Пол"
        name="gender"
        options={GENDERS}
      />
      <Input
        className={styles.Input}
        key="password"
        label="Новый пароль"
        name="password"
        placeholder="Новый пароль"
        type="password"
        error={error}
        required
      />
      <Input
        className={styles.Input}
        key="confirmation"
        label="Повторить пароль"
        name="confirmation"
        placeholder="Повторить пароль"
        type="password"
        error={error}
        required
      />
      <Checkbox
        key="personalDataAccept"
        className={styles.Checkbox}
        name="personalDataAccept"
        required
        label={
          <P className={styles.Paragraph}>
            {'Я согласен с '}
            <Link
              className={styles.Link}
              href="/policy#personal"
            >
              {'обработкой моих персональных '}
              {'данных '}
            </Link>
            {'в соответствии с '}
            <Link
              className={styles.Link}
              href="/policy#confidential"
            >
              {'политикой '}
            </Link>
            {'и '}
            <Link
              className={styles.Link}
              href="/policy"
            >
              {'правилами '}
              {'использования сайта '}
            </Link>
          </P>
        }
      />
      <Button
        className={styles.Button}
        icon="arrow"
        type="submit"
        disabled={!(anyTouched && !asyncValidating && valid)}
      >
        Далее
      </Button>
    </BaseForm>
  );
};

// Exports
const signUpForm = reduxForm<object, any>({
  form: 'signUp',
  // initialValues: { pin: '' },
  // initialValues: { birthday: moment().format('DD.MM.YYYY') },
  destroyOnUnmount: false,
  asyncValidate,
  validate,
  touchOnChange: true,
  enableReinitialize: true,
})(Form);

const mapStateToProps = (state: object): object => ({
  formValues: get(state, 'form.signUp.values'),
});

const mapDispatchToProps = (dispatch: (action: ACTION_TYPE) => any) => ({
  // Services
  signIn: (profile: SESSION_TYPE) => dispatch(signIn(profile))
});

// Exports
export default compose(connect(mapStateToProps, mapDispatchToProps))(signUpForm);
