// Libraries
import * as yup from 'yup';

import { asyncValidator } from 'components/Form';

// Validations
// import {
//   VALIDATE_LOGIN,
//   VALIDATE_ADULT,
//   VALIDATE_DATE_FORMAT,
//   VALIDATE_PHONE,
// } from 'helpers/validations';

// Config
import {
  ERROR_FIELD_REQUIRED,
} from 'config';

export const validate = (values: object) => {
  return {};
};

export const asyncValidate = asyncValidator(
  yup.object().shape({
    organisation_name: yup.string().required(ERROR_FIELD_REQUIRED),
    start_evaluation: yup.mixed().required(ERROR_FIELD_REQUIRED),
    end_evaluation: yup.mixed().required(ERROR_FIELD_REQUIRED),
    specialists: yup.mixed().required(ERROR_FIELD_REQUIRED),
    client: yup.mixed().required(ERROR_FIELD_REQUIRED),
  })
);
