// Libraries
import React from 'react';
import { get, join, toUpper } from 'lodash';
import { useLazyQuery } from 'react-apollo';
import { loader } from 'graphql.macro';
import { isFunction } from 'lodash';

// Config
import {
  ERROR_CONNECTION,
  MESSAGES_MAP
} from 'config';

// GraphQL
const usersQuery = loader('src/graphql/queries/users.gql');

const SETS = {
  users: {
    query: usersQuery,
    store: 'users',
    normalizer: (data: object[]) => data.map((item: object) => ({
      ...item,
      value: get(item, 'id'),
      title: `${get(item, 'surname')} ${toUpper(get(item, 'name', '')).substr(0, 1)}. ${toUpper(get(item, 'patronymic', '')).substr(0, 1)}. (${get(item, 'roles[0].name', 'не указано')})`,
    })),
  },
  administrators: {
    query: usersQuery,
    store: 'users',
    normalizer: (data: object[]) => data.map((item: object) => ({
      ...item,
      value: get(item, 'id'),
      title: `${get(item, 'surname')} ${toUpper(get(item, 'name', '')).substr(0, 1)}. ${toUpper(get(item, 'patronymic', '')).substr(0, 1)}. (${get(item, 'position', 'не указано')})`,
    })),
    defaultParams: {
      hasRoles: {
        column: 'NAME',
        value: 'administrator'
      }
    }
  },
  specialists: {
    query: usersQuery,
    store: 'users',
    normalizer: (data: object[]) => data.map((item: object) => ({
      ...item,
      value: get(item, 'id'),
      title: `${get(item, 'surname')} ${toUpper(get(item, 'name', '')).substr(0, 1)}. ${toUpper(get(item, 'patronymic', '')).substr(0, 1)}. (${get(item, 'position', 'не указано')})`,
    })),
    defaultParams: {
      hasRoles: {
        column: 'NAME',
        value: 'specialist'
      }
    }
  },
  clients: {
    query: usersQuery,
    store: 'users',
    normalizer: (data: object[]) => data.map((item: object) => ({
      ...item,
      value: get(item, 'id'),
      title: `${get(item, 'surname')} ${toUpper(get(item, 'name', '')).substr(0, 1)}. ${toUpper(get(item, 'patronymic', '')).substr(0, 1)}.`,
    })),
    defaultParams: {
      hasRoles: {
        column: 'NAME',
        value: 'client'
      }
    }
  }
};

type USE_AUTOCOMPLETE_PROPS_TYPE = {
  options?: any,
  src?: string,
  error?: string | boolean,
  params?: object,
  value?: any,
  onChange?: any,
  onBlur?: any,
  onFocus?: any,
};

export const useAutocomplete = ({
  options: baseOptions,
  value,
  src,
  params,
  error: baseError,
  onChange,
  onBlur,
  onFocus,
}: USE_AUTOCOMPLETE_PROPS_TYPE) => {
  const set = get(SETS,`[${src}]`);
  const query = get(set,`query`);
  const store = get(set,`store`);
  const defaultParams = get(set,`defaultParams`);
  const normalizer = get(set,`normalizer`);
  const [options, setOptions] = React.useState(baseOptions);
  // const [value, setValue] = React.useState(baseValue);

  const [error, setError] = React.useState(baseError);
  const [loading, setLoading] = React.useState<boolean>(false);

  const handleChange = (event: React.ChangeEvent<{}>, item: any, reason: string) => {
    isFunction(onChange) && onChange(item);
  };

  const [handleQuery, { loading: queryLoading, data: queryData, error: queryError }] = useLazyQuery(query, {
    fetchPolicy: 'no-cache'
  });

  React.useEffect(() => {
    setLoading(!!queryLoading);
  }, [queryLoading]);

  React.useEffect(() => {
    query && handleQuery({
      variables: {
        ...defaultParams,
        ...params
      }
    });
  }, [query]);

  React.useEffect((): any => {
    if (!queryData || queryLoading) {
      return;
    }
    const isError = get(queryData, `${store}.__typename`) === 'Error';
    const message = get(queryData, `${store}.message`);
    const normalizedData = normalizer(get(queryData, `${store}.data`, []));

    const dataError = isError && get(MESSAGES_MAP, `${message}`, message);
    const currentErrors = [
      ...(queryError ? [ERROR_CONNECTION] : []),
      ...(dataError ? [dataError] : []),
    ];
    currentErrors.length > 0 ?
      setError(join(currentErrors, '\n')) :
      // setOptions(filter(normalizedData, (item: object) => get(item, 'id') !== get(value, 'id')));
      setOptions(normalizedData);

  }, [queryData]);


  return {
    options,
    value,
    handleChange,
    loading,
    error
  };
};

export default useAutocomplete;
