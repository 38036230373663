// Libraries
import React from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { get } from 'lodash';

// Components
import Button from 'components/Button';
import BaseForm, { Input } from 'components/Form';

// Styles
import styles from './styles.module.scss';

// Services
import { signIn, SESSION_TYPE, SIGN_IN_TYPE, ACTION_TYPE } from 'services/session';

// Hooks
import { useStep } from './hooks';

// Data
import { validate, asyncValidate } from './validators';

type FORM_PROPS_TYPE = {
  valid?: boolean,
  asyncValidating?: boolean | string,
  anyTouched?: boolean,
  formValues?: object,
  session?: SESSION_TYPE,
  signIn: SIGN_IN_TYPE,
  handleSubmit: Function,
};

const Form = ({
  valid,
  anyTouched,
  asyncValidating,
  formValues,
  signIn,
  handleSubmit: formSubmit,
  session,
}: FORM_PROPS_TYPE): React.ReactElement<typeof BaseForm> => {
  const { error, handleSubmit } = useStep({
    session,
    formValues,
    signIn,
  });
  return (
    <BaseForm
      className={styles.Form}
      onSubmit={formSubmit(handleSubmit)}
      name="profile"
      autoComplete="off"
    >
      <Input
        className={styles.Input}
        key="name"
        label="Имя"
        name="name"
        placeholder="Имя"
        required
        error={error}
      />
      <Input
        className={styles.Input}
        key="surname"
        label="Фамилия"
        name="surname"
        placeholder="Фамилия"
        required
        error={error}
      />
      <Input
        className={styles.Input}
        key="patronymic"
        label="Отчество"
        name="patronymic"
        placeholder="Отчество"
        required
        error={error}
      />
      <Input
        className={styles.Input}
        key="login"
        label="E-mail"
        name="login"
        placeholder="E-mail или телефон"
        required
        error={error}
      />
      <Input
        className={styles.Input}
        key="password"
        label="Новый пароль"
        name="password"
        placeholder="Новый пароль"
        type="password"
        error={error}
      />
      <Input
        className={styles.Input}
        key="confirmation"
        label="Повторить пароль"
        name="confirmation"
        placeholder="Повторить пароль"
        type="password"
        error={error}
      />
      <div className={styles.Actions}>
        <Button
          className={styles.Button}
          icon="arrow"
          type="submit"
          disabled={!(anyTouched && !asyncValidating && valid)}
        >
          Сохранить
        </Button>
        <Button
          className={styles.Button}
          icon="arrow"
          type="button"
          disabled={!(anyTouched && !asyncValidating && valid)}
        >
          Отмена
        </Button>
      </div>
    </BaseForm>
  );
};

// Exports
const profileForm = reduxForm<object, any>({
  form: 'profile',
  destroyOnUnmount: true,
  asyncValidate,
  validate,
  touchOnChange: true,
  enableReinitialize: true,
})(Form);

const mapStateToProps = (state: object): object => ({
  session: get(state, 'session'),
  initialValues: {
    ...get(state, 'session'),
    login: get(state, 'session.email', get(state, 'session.phone')),
  },
  formValues: get(state, 'form.profile.values')
});

const mapDispatchToProps = (dispatch: (action: ACTION_TYPE) => any) => ({
  // Services
  signIn: (profile: SESSION_TYPE) => dispatch(signIn(profile))
});

// Exports
export default compose(connect(mapStateToProps, mapDispatchToProps))(profileForm);
