// Libraries
import React from 'react';
import { get } from 'lodash';

// Styles
import styles from './Wizard.module.scss';

// Components
import Recovery from './components/Recovery';
import Restore from './components/Restore';

// Hooks
import { ResetContext } from '../../hooks';

const STEPS = [
  Recovery,
  Restore
];

const Wizard = () => {
  const { step } = React.useContext(ResetContext);
  const Step = get(STEPS, `[${step}]`);
  return (
    <div className={styles.Root}>
      {
        Step && <Step />
      }
    </div>
  );
};

// Exports
export default Wizard;
