// Libraries
import React from 'react';
import classNames from 'classnames';
import { Field as BaseField } from 'redux-form';
import { isArray, fill } from 'lodash';

// Components
import MaskedInput from 'react-text-mask';
import { TextField } from '@material-ui/core';

// Styles
import styles from './Input.module.scss';

export interface INPUT_PROPS_TYPE {
  input?: any,
  label?: string,
  placeholder?: string,
  type?: string,
  name?: string,
  validate?: Function[],
  meta?: {
    touched?: boolean,
    error?: string,
    invalid?: boolean
  },
  className?: string,
  classes?: {
    root?: string,
  },
  error?: string | boolean,
  autoComplete?: string,
  required?: boolean,
  mask?: any,
  maxLength?: number,
  multiline?: boolean,
  rowsMax?: number,
  rows?: number,
  fullWidth?: boolean,
  disabled?: boolean,
  normalize?: Function
};

export const Input = ({
  input,
  label,
  placeholder,
  type,
  meta,
  className,
  classes,
  autoComplete = "off",
  required,
  mask,
  maxLength = 32,
  multiline = false,
  rowsMax = 1,
  rows = 1,
  error,
  ...props
}: INPUT_PROPS_TYPE): React.ReactElement<typeof TextField> => {
  // console.log('input, props, meta', input, props, meta);
  return (
    <MaskedInput
      mask={
        mask
        ? isArray(mask)
          ? mask
          : fill(Array(maxLength), mask)
        : false
      }
      autoComplete={autoComplete}
      {...props}
      {...input}
      render={(ref, { defaultValue, ...inputProps }) => {
        // console.log('inputProps', inputProps);
        return (
          <TextField
            ref={ref}
            {...inputProps}
            // value={defaultValue}
            // {...input}
            // {...(defaultValue ? { defaultValue } : { value: defaultValue })}
            value={defaultValue}
            className={classNames(
              className,
              classes?.root,
              styles.Root,
              {
                [styles.RootIsRequired]: required,
                [styles.RootIsError]: meta?.touched && meta?.invalid
              }
            )}
            placeholder={placeholder}
            autoComplete={autoComplete}
            type={type}
            error={!!error || (meta?.touched && meta?.invalid)}
            helperText={error || (meta?.touched && meta?.error)}
            label={label}
            multiline={multiline}
            rowsMax={rowsMax}
            rows={rows}
          />
        )
      }}
    />
  )
};

const Field = ({
  name,
  validate,
  ...props
}: INPUT_PROPS_TYPE): React.ReactElement<typeof BaseField> => (
  <BaseField
    name={name}
    component={Input}
    validate={validate}
    {...props}
  />
);


export default Field;
