// Libraries
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { get } from 'lodash';
import classNames from 'classnames';
import { Link } from "react-router-dom";

// Components
import Button from 'components/Button';
import { H1 } from 'components/Typography';
import Table from 'components/Table';

import Page from 'components/Page';

// Styles
import styles from './List.module.scss';

// Services
import { SESSION_TYPE, ACTION_TYPE } from 'services/session';

// Hooks
import { useList } from './hooks';

type LIST_PROPS_TYPE = {
  session?: SESSION_TYPE,
  match: {
    url: string
  }
};

const WHITE_LIST_OF_ROLES = ['administrator', 'specialist'];


const List = ({
  session,
  match,
  ...props
}: LIST_PROPS_TYPE): React.ReactElement<typeof Page> => {
  const { url } = match;
  const usedList = useList({ session, url });
  const { loading, data, columns, title } = usedList;

  const filteredData = React.useMemo(() => data.filter(({ role }) => WHITE_LIST_OF_ROLES.includes(role)), [data]);

  return (
    <Page
      classes={{
        root: classNames(styles.Root)
      }}
      loading={loading}
    >
      <H1>
        {title}
      </H1>
      <Table
        columns={columns}
        data={filteredData}
        className={styles.Table}
        additional={{
          toolbar: (
            <Button component={Link} className={styles.Button} href={`${url}/new`}>
              Создать
            </Button>
          )
        }}
      />
    </Page>
  );
};

// Exports
const mapStateToProps = (state: object): object => ({
  session: get(state, 'session')
});

const mapDispatchToProps = (dispatch: (action: ACTION_TYPE) => any) => ({
  // Services
});

// Exports
export default compose<LIST_PROPS_TYPE, any>(connect(mapStateToProps, mapDispatchToProps))(List);
