// Libraries
import React from 'react';
import { get } from 'lodash';

// Components
import Link from 'components/Link';
import { P } from 'components/Typography';
import classNames from 'classnames';

// Styles
import styles from './Informer.module.scss';

// Hooks
import { ResetContext } from '../hooks';

// Types
type INFORMER_PROPS_TYPE = {
  className?: string,
  classes?: {
    root?: string
  },
};

const Informer = ({
  className,
  classes
}: INFORMER_PROPS_TYPE): React.ReactElement<typeof P> => {
  const { step, finished, values, handleBack, isPhone } = React.useContext(
    ResetContext
  );

  const data = [
    <>
      Куда прислать тебе код – <br data-mobile />
      на почту <br data-tablet data-desktop />
      или через смс?
    </>,
    <>
      Мы отправили код на {get(values, 'login')}
      <br />
      <Link
        className={styles.Link}
        // href="/auth/reset"
        onClick={handleBack}
      >
        Изменить {isPhone ? 'номер' : 'e-mail'}
      </Link>
    </>
  ][step];

  return (
    <P
      className={classNames(
        className,
        classes?.root,
        styles.Root,
      )}
    >
      {finished ? 'Теперь всё в порядке' : data}
    </P>
  );
};

// Exports
export default Informer;
