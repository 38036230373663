import { createContext, useReducer } from 'react';
import { get } from 'lodash';

export const ADD_STEP = 'ADD_STEP';
export const UPDATE = 'UPDATE';

type ACTION_TYPE = {
  type?: string,
  payload?: any
};

export type FORM_CONTEXT_TYPE = {
  loading: boolean,
  step: number,
  finished: boolean,
  limit: number,
  error?: string | boolean,
  fail?: boolean,
  values?: any,
  handleUpdate: (payload?: object) => void,
  handleValues: (addValues?: object) => void,
  handleBack: (payload?: object) => void,
  handleNext: (payload?: object) => void,
  handleError: (errors?: string) => void,
  handleFail: (fail?: boolean) => void,
  handleLoading: (loading?: boolean) => void
};

const INITIAL_STATE = {
  loading: false,
  step: 0,
  finished: false,
  limit: 2,
  error: false,
  fail: false,
  values: {},
  handleUpdate: () => {},
  handleValues: () => {},
  handleBack: () => {},
  handleNext: () => {},
  handleError: () => {},
  handleFail: () => {},
  handleLoading: () => {}
};

export const createFormContext = (extra?: any) => 
  createContext({
    ...INITIAL_STATE,
    ...extra
  });

const FORM_REDUCER = (state = INITIAL_STATE, action: ACTION_TYPE) => {
  const payload = get(action, 'payload');
  const step = get(payload, 'step', 0);
  const nextStep = state.step + step;
  const finished = nextStep >= state.limit;
  switch (action.type) {
    case UPDATE:
      return {
        ...state,
        ...payload
      };
    case ADD_STEP:
      return {
        ...state,
        ...payload,
        step: finished ? state.limit : nextStep > 0 ? nextStep : 0,
        finished
      };
    default:
      return state;
  }
};

export const useForm = (props: object) => {
  const [state, dispatch] = useReducer(FORM_REDUCER, {
    ...INITIAL_STATE,
    ...props
  });

  const handleUpdate = (payload?: object) => {
    dispatch({
      type: UPDATE,
      payload
    });
  };
  
  const handleValues = (addValues?: object) => {
    dispatch({
      type: UPDATE,
      payload: {
        values: { ...state.values, ...addValues }
      }
    });
  };

  const handleBack = (payload?: object) => {
    dispatch({
      type: ADD_STEP,
      payload: {
        step: -1,
        ...payload
      }
    });
  };

  const handleNext = (payload?: object) => {
    dispatch({
      type: ADD_STEP,
      payload: {
        step: 1,
        ...payload
      }
    });
  };

  const handleError = (error?: string) => {
    dispatch({
      type: UPDATE,
      payload: {
        error
      }
    });
  };

  const handleFail = (fail: boolean) => {
    dispatch({
      type: UPDATE,
      payload: {
        fail
      }
    });
  };

  const handleLoading = (loading: boolean) => {
    dispatch({
      type: UPDATE,
      payload: {
        loading
      }
    });
  };

  return {
    ...state,
    dispatch,
    handleUpdate,
    handleValues,
    handleBack,
    handleNext,
    handleError,
    handleFail,
    handleLoading
  };
};
