// Libraries
import React, {useMemo} from "react";
import classNames from "classnames";
import parse from 'html-react-parser';

// Components
import Page from "components/Page";
import {H1, H5, Subtitle} from "components/Typography";
import { Paper } from "@material-ui/core";
import {Input, Select, DatePicker} from "components/Form";
import {Button} from "components/Button/Button";

// Styles
import styles from "./styles.module.scss";

// Data
import { data as touchstones } from "./data";

const SNA = (): JSX.Element => {
  const renderTouchstones = useMemo((): JSX.Element[] => touchstones.map((touchstone, i): JSX.Element => (
    <div className={styles.Touchstones} key={touchstone.title}>
      <Subtitle className={styles.TouchstonesTitle}>{ touchstone.title }</Subtitle>
      <div className={styles.TouchstonesFields}>
        <Select
          className={styles.Input}
          name={`sna.touchstone_${i}`}
          required
        />
        <Input
          className={classNames(styles.Input, styles.TouchstonesInput)}
          name={`sna.comment_${i}`}
          label="Комментарий"
          placeholder="Комментарий"
          required
          multiline
          rowsMax={4}
        />
      </div>
    </div>
  )), []);

  const renderRuleForTouchstones = useMemo((): JSX.Element[] => touchstones.map((touchstone): JSX.Element => (
    <React.Fragment key={touchstone.description}>
      <Subtitle>{ parse(touchstone.description) }</Subtitle>
    </React.Fragment>
  )), []);

  return (
    <Page
      classes={{
        root: styles.Root
      }}
    >
      <H1 className={styles.Title}>Оценка потребности в услуге «Передышка»</H1>
      <Paper className={styles.Paper}>
        <div className={styles.AboutChild}>
          <Input
            className={styles.Input}
            name="sna.fio"
            label="Ф.И.О ребёнка"
            placeholder="Ф.И.О ребёнка"
          />
          <Input
            className={styles.Input}
            name="sna.age"
            label="Возраст ребёнка"
            placeholder="Возраст ребёнка"
            type="number"
          />
        </div>
      </Paper>
      <Paper className={styles.Paper}>
        { renderTouchstones }
      </Paper>
      <Paper className={styles.Paper}>
        <Input
          className={styles.Input}
          name="sna.conclusions"
          label="Выводы"
          placeholder="Выводы"
          multiline
          rowsMax={12}
          fullWidth
        />
        <Input
          className={styles.Input}
          name="sna.specialist_fio"
          label="Ф.И.О специалиста"
          placeholder="Ф.И.О специалиста"
          fullWidth
        />
        <DatePicker
          className={styles.Input}
          name="sna.created_at"
          label="Дата заполнения"
          placeholder="Дата заполнения"
        />
      </Paper>
      <Button
        className={styles.SubmitBtn}
        type="submit"
      >
        Сохранить
      </Button>
      <Paper className={styles.Paper}>
        <H5>Описание критериев и шкал</H5>
        { renderRuleForTouchstones }
      </Paper>
      <Paper className={styles.Paper}>
        <Subtitle>
          <b>Кризисный уровень</b> – отсутствие ресурсов, крайне сложное состояние, которое может привести к
          разлучению ребенка с семьей, наличие сразу нескольких дополнительных факторов риска.
        </Subtitle>
        <Subtitle>
          <b>Неблагополучный уровень</b> – ограниченные ресурсы или непостоянство ресурсов, постоянно сложное
          состояние или эпизодические периоды ухудшений, наличие хотя бы одного дополнительного фактора риска.
        </Subtitle>
        <Subtitle>
          <b>Удовлетворительный уровень</b> – есть ресурсы, хотя их может быть субъективно недостаточно, состояние
          с которым члены семьи могут справиться, если есть дополнительные факторы риска, то члены семьи адекватно
          с ними справляются.
        </Subtitle>
        <Subtitle>
          <b>Благополучный уровень</b> – достаточное количество ресурсов, члены семьи не испытывают
          сложности в связи с состоянием ребенка или своим состоянием, нет дополнительных факторов риска.
        </Subtitle>
      </Paper>
    </Page>
  )
};

export default SNA;
