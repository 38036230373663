// Libraries
import { v4 as uuidv4 } from 'uuid';

// Components
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';

export type ITEM_TYPE = {
  id: string,
  name: string,
  icon: any,
  link: string,
}

export const ITEMS = {
  users: {
    id: uuidv4(),
    name: 'Пользователи',
    icon: SupervisorAccountIcon,
    link: '/users'
  },
  cases: {
    id: uuidv4(),
    name: 'Случаи',
    icon: SupervisorAccountIcon,
    link: '/cases'
  },
  questionnaires: {
    id: uuidv4(),
    name: 'Анкеты',
    icon: RecentActorsIcon,
    link: '/questionnaires'
  },
  familyData: {
    id: uuidv4(),
    name: 'Карточки семей',
    icon: SupervisorAccountIcon,
    link: '/families'
  },
  otherPerson: {
    id: uuidv4(),
    name: 'Социальные помощники',
    icon: PeopleOutlineIcon,
    link: '/other-people'
  }
};

export const CLIENT = {
  id: uuidv4(),
  group: 'Special',
  items: [
  ]
};

export const CARDSANDQUESTIONNAIRES = {
  id: uuidv4(),
  group: 'CardsAndQuestionnaires',
  items: [
    ITEMS.users,
    ITEMS.familyData,
    ITEMS.questionnaires,
    ITEMS.otherPerson,
    ITEMS.cases
  ]
};

const MENU = {
  client: CLIENT,
  cardsAndQuestionnaires: CARDSANDQUESTIONNAIRES
};

export default MENU;
