// Libraries
import React from "react";

// Components
import {Subtitle} from "components/Typography";

// Styles
import styles from "../Step0/styles.module.scss";

const Legend = (): JSX.Element => (
  <>
    <Subtitle className={styles.Subtitle}>* В поле <b>«Выводы»</b> следует отразить действия, которые необходимо предпринять,
      относительно мер обеспечения потребностей ребенка, представленных в данном разделе.</Subtitle>
    <Subtitle className={styles.Subtitle}>** <b>Кризисный уровень</b>: Семья/ребенок в социально-опасном положении; требуется
      немедленное вмешательство для обеспечения безопасности и благополучия ребенка.</Subtitle>
    <Subtitle className={styles.Subtitle}><b>Неблагополучный уровень</b>: Существуют значительные трудности, с которыми семья
      сама не может справиться и которые влияют на ребенка; если не оказать поддержку/вмешательство,
      есть риск, что ребенок окажется в социально опасном положении.</Subtitle>
    <Subtitle className={styles.Subtitle}><b>Удовлетворительный уровень</b>: Существуют некоторые трудности, но семья в
      состоянии с ними справиться самостоятельно или с небольшой поддержкой; существующие трудности семьи не
      оказывают существенного влияния на ребенка; отсутствует риск того, что ребенок окажется в социально
      опасном положении.</Subtitle>
    <Subtitle className={styles.Subtitle}><b>Благополучный уровень</b>: Семья полностью справляется со своими задачами
      и предоставляет все для полноценного развития/воспитания ребенка.</Subtitle>
    <Subtitle className={styles.Subtitle}>
      Оценка уровня функционирования должна опираться на информацию, собранную вами по данному разделу,
      и соответствовать сделанным выводам. Так, например, если вы оцениваете семью как находящуюся на кризисном
      уровне, то в поле «Слабые стороны» должны быть перечислены факторы, подтверждающие опасность для ребенка,
      а в графе «Выводы» – срочные меры, которые следует предпринять. Если в графе «Выводы» вы пишете «Семья
      обеспечивает основные потребности ребенка в данной области, вмешательство не требуется», следовательно,
      уровень функционирования семьи благополучный.
    </Subtitle>
    <Subtitle className={styles.Subtitle}>
      Более подробную информацию см. в Руководстве по оформлению СПб ЕФО
    </Subtitle>
  </>
)

export default Legend;
