// Libraries
import React from 'react';
import { useLazyQuery } from 'react-apollo';
import { get, join, capitalize } from 'lodash';
import { useHistory } from "react-router-dom";
import { loader } from 'graphql.macro';

// Config
import {
  ERROR_CONNECTION,
  MESSAGES_MAP
} from 'config';

// Types
import { SESSION_TYPE } from 'services/session';

// Data
import { COLUMNS, GET_ACTIONS_CELL } from './data';

// GraphQL
const snaQuery = loader('src/graphql/queries/sna.gql');

export type LIST_PROPS_TYPE = {
  session?: SESSION_TYPE,
  url: string
};

export const useList = ({
  session,
  url
}: LIST_PROPS_TYPE) => {
  const history = useHistory();
  const [data, setData] = React.useState([]);

  const columns = React.useMemo(
    () => COLUMNS,
    []
  );

  const [error, setError] = React.useState<string | boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);

  const [
    handleSNAQuery,
    {
      loading: snaQueryLoading,
      data: snaQueryData,
      error: snaQueryError
    }] = useLazyQuery(snaQuery, {
    fetchPolicy: 'no-cache'
  });

  const token = get(session, 'token');

  React.useEffect(() => {
    setLoading(!!snaQueryLoading);
  }, [snaQueryLoading]);

  React.useEffect((): any => {
    !!token && handleSNAQuery();
  },[token]);

  React.useEffect((): any => {
    if (!snaQueryData || snaQueryLoading) {
      return;
    }
    const isError = get(snaQueryData, 'sna.__typename') === 'Error';
    const message = get(snaQueryData, 'sna.message');
    const normalizedData = get(snaQueryData, 'sna.data', []).map((sna: object) => ({
      ...sna,
      client: `${get(sna, 'client.surname', '')} ${capitalize(get(sna, 'client.name', '').substr(0,1))}. ${capitalize(get(sna, 'client.patronymic', '').substr(0,1))}.`,
      actions: GET_ACTIONS_CELL({
        ...sna,
        onEdit: () => history.push(`${url}/${get(sna, 'id')}`),
      })
    }));

    const dataError = isError && get(MESSAGES_MAP, `${message}`, message);
    const currentErrors = [
      ...(snaQueryError ? [ERROR_CONNECTION] : []),
      ...(dataError ? [dataError] : []),
    ];
    currentErrors.length > 0 ? setError(join(currentErrors, '\n')) : setData(normalizedData);

  }, [snaQueryData]);

  return {
    loading,
    error,
    data,
    columns,
  };
};

export default useList;
