// Libraries
import React from 'react';

// Components
import BaseToolbar from '@material-ui/core/Toolbar';

import GlobalFilter from './GlobalFilter';

// Styles
import styles from './Toolbar.module.scss';
import { Row } from 'react-table';

export type TOOLBAR_PROPS_TYPES = {
  children?: React.ReactNode,
  preGlobalFilteredRows: Row<object>[],
  setGlobalFilter: Function,
  globalFilter: string,
};

const Toolbar = ({
  children,
  preGlobalFilteredRows,
  setGlobalFilter,
  globalFilter,
}: TOOLBAR_PROPS_TYPES) => {

  return (
    <BaseToolbar className={styles.Root}>
      {children}
      <GlobalFilter
        preGlobalFilteredRows={preGlobalFilteredRows}
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
      />
    </BaseToolbar>
  );
};

export default Toolbar;
