import { get, set, join, forEach, toString } from 'lodash';
import Cookies from 'js-cookie';
import ApolloClient from 'apollo-boost';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { SubmissionError } from 'redux-form';
import { createBrowserHistory } from 'history';

// Config
import { API_URI, MESSAGES_MAP } from 'config';

// Create history
export const HISTORY = createBrowserHistory({
  basename: '/',
  forceRefresh: false
});

// Server errors hanndler
export const SERVER_ERRORS_HANDLER = (error?: any, exception?: Function) => {
  const messageErrors = get(error, 'message');
  const validationErrors = get(error, 'graphQLErrors.0.extensions.validation');
  const submission = {
    '_error': messageErrors
  };
  forEach(validationErrors, (value: string, key?: any) => {
    const newKey = key.replace('input.', '').replace(/(email|phone)/, 'login');
    const message = join(value, '\n');
    set(submission, newKey, get(MESSAGES_MAP, `${message}`, message));
  });
  if(validationErrors) {
    throw new SubmissionError(submission);
  } else {
    exception && exception();
  }
};

// Create apollo client
export const CLIENT: ApolloClient<InMemoryCache> = new ApolloClient({
  cache: new InMemoryCache(),
  onError: ({ networkError, graphQLErrors, ...other }) => {
    networkError && HISTORY.push('/500');
    get(graphQLErrors, '[0].message') === 'Unauthenticated.' && HISTORY.push('/auth/sign-out');
    toString(networkError) === 'TypeError: Failed to fetch' && HISTORY.push('/errors/network');
  },
  uri: API_URI,
  credentials: 'same-origin',
  request: (operation: any) => {
    const token = Cookies.get('token');
    operation.setContext({
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      }
    });
  }
});
