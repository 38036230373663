// Libraries
import React from 'react';
import { useLazyQuery } from 'react-apollo';
import { get, join, capitalize } from 'lodash';
import { useHistory } from "react-router-dom";
import { loader } from 'graphql.macro';

// Config
import {
  ERROR_CONNECTION,
  MESSAGES_MAP
} from 'config';

// Types
import { SESSION_TYPE } from 'services/session';

// Data
import { COLUMNS, GET_ACTIONS_CELL } from './data';

// GraphQL
const efoQuery = loader('src/graphql/queries/efo.gql');

export type LIST_PROPS_TYPE = {
  session?: SESSION_TYPE,
  url: string
};

export const useList = ({
  session,
  url
}: LIST_PROPS_TYPE) => {
  const history = useHistory();
  const [data, setData] = React.useState([]);

  const columns = React.useMemo(
    () => COLUMNS,
    []
  );

  const [error, setError] = React.useState<string | boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);

  const [
    handleEFOQuery,
    {
      loading: efoQueryLoading,
      data: efoQueryData,
      error: efoQueryError
    }] = useLazyQuery(efoQuery, {
    fetchPolicy: 'no-cache'
  });

  const token = get(session, 'token');

  React.useEffect(() => {
    setLoading(!!efoQueryLoading);
  }, [efoQueryLoading]);

  React.useEffect((): any => {
    !!token && handleEFOQuery();
  },[token]);

  React.useEffect((): any => {
    if (!efoQueryData || efoQueryLoading) {
      return;
    }
    const isError = get(efoQueryData, 'efo.__typename') === 'Error';
    const message = get(efoQueryData, 'efo.message');
    const normalizedData = get(efoQueryData, 'efo.data', []).map((efo: object) => ({
      ...efo,
      specialist: `${get(efo, 'specialists[0].surname', '')} ${capitalize(get(efo, 'specialists[0].name', '').substr(0,1))}. ${capitalize(get(efo, 'specialists[0].patronymic', '').substr(0,1))}.`,
      // secondary_specialist: `${get(efo, 'specialists[1].surname')} ${capitalize(get(efo, 'specialists[1].name').substr(0,1))}. ${capitalize(get(efo, 'specialists[1].patronymic').substr(0,1))}.`,
      client: `${get(efo, 'client.surname', '')} ${capitalize(get(efo, 'client.name', '').substr(0,1))}. ${capitalize(get(efo, 'client.patronymic', '').substr(0,1))}.`,
      actions: GET_ACTIONS_CELL({
        ...efo,
        onEdit: () => history.push(`${url}/${get(efo, 'id')}`),
      })
    }));

    const dataError = isError && get(MESSAGES_MAP, `${message}`, message);
    const currentErrors = [
      ...(efoQueryError ? [ERROR_CONNECTION] : []),
      ...(dataError ? [dataError] : []),
    ];
    currentErrors.length > 0 ? setError(join(currentErrors, '\n')) : setData(normalizedData);

  }, [efoQueryData]);

  return {
    loading,
    error,
    data,
    columns,
  };
};

export default useList;
