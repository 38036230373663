import { useEffect, useContext, useState } from 'react';
import { useMutation } from 'react-apollo';
import validator from 'validator';
import { get, replace, join } from 'lodash';
import { loader } from 'graphql.macro';

// Hooks
import { SignUpContext } from '../../../../hooks';

// Data
import { ATTEMPTS_LIMIT } from '../../../../../data';

// Config
import {
  SERVER_ERRORS_HANDLER,
  ERROR_CONNECTION,
  ERROR_PIN_REFETCH,
  ERROR_PIN_RETRY,
  MESSAGES_MAP
} from 'config';

// Types
import { SIGN_IN_TYPE } from 'services/session';

// GraphQL
const verifyQuery = loader('src/graphql/mutations/verify.gql');

type USE_STEP_PROPS_TYPE = {
  formValues?: object,
  signIn: SIGN_IN_TYPE,
};

export const useStep = ({
  formValues,
  signIn
}: USE_STEP_PROPS_TYPE) => {
  const [attempts, setAttempts] = useState(ATTEMPTS_LIMIT);

  const {
    loading,
    step,
    values,
    finished,
    limit,
    error,
    fail,
    handleValues,
    handleBack,
    handleNext,
    handleError,
    handleFail,
    handleLoading
  } = useContext(SignUpContext);

  const [handleVerifyQueryChange, verifyQueryResult] = useMutation(
    verifyQuery,
    {
      fetchPolicy: 'no-cache',
      onError: (error: any) => SERVER_ERRORS_HANDLER(error, (error?: any) => handleFail(!!error))
    }
  );
  const {
    loading: verifyQueryLoading,
    data: verifyQueryData,
    error: verifyQueryError,
  } = verifyQueryResult;

  useEffect(() => {
    handleLoading(!!verifyQueryLoading);
  }, [verifyQueryLoading]);

  useEffect(() => {
    if (!verifyQueryData) {
      return;
    }
    const user = get(verifyQueryData, 'verify.user');
    const token = get(verifyQueryData, 'verify.token');
    const isError = get(verifyQueryData, 'verify.__typename') === 'Error';
    const message = get(verifyQueryData, 'verify.message');

    if (token) { 
      signIn({ ...user, token });
    }

    const dataError = isError && get(MESSAGES_MAP, `${message}`, message);
    const currentErrors = [
      ...(verifyQueryError ? [ERROR_CONNECTION] : []),
      ...(dataError ? [dataError] : []),
      ...(!token && step === 1
        ? [attempts <= 1 ? ERROR_PIN_REFETCH : ERROR_PIN_RETRY]
        : [])
    ];
    currentErrors.length <= 0
      ? handleNext({ errors: false })
      : handleError(join(currentErrors, '\n'));

  }, [verifyQueryData, verifyQueryError]);

  const handleSubmit = (event: React.SyntheticEvent) => {
    // get(event, 'preventDefault') && event.preventDefault();
    const login = replace(
      get(formValues, 'login', get(values, 'login')),
      / /g,
      ''
    );
    const isPhone =
      login && validator.isMobilePhone(login, 'ru-RU', { strictMode: false });
    const pin = parseInt(get(formValues, 'pin', get(values, 'login')), 10);
    const stepValues = {
      [isPhone ? 'phone' : 'email'] : login,
      pin
    };
    handleValues(stepValues);
    return handleVerifyQueryChange({
      variables: { ...stepValues },
    });
  };

  const handleRefetch = () => {
    handleVerifyQueryChange({
      variables: { ...values },
    });
    setAttempts(ATTEMPTS_LIMIT);
  };

  return {
    loading,
    step,
    finished,
    limit,
    error,
    fail,
    handleBack,
    handleNext,
    handleError,
    handleFail,
    handleLoading,
    handleSubmit,
    handleRefetch,
    attempts
  };
};

export default useStep;
