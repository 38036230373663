import { useEffect, useContext, useState } from 'react';
import { useMutation, useLazyQuery } from 'react-apollo';
import { useParams } from "react-router-dom";
import validator from 'validator';
import {get, replace, capitalize, join, toLower, find, toUpper} from 'lodash';
import { loader } from 'graphql.macro';
import moment from 'moment';

// Hooks
import { UserContext } from '../../../../hooks';

// Config
import { SERVER_ERRORS_HANDLER, ERROR_CONNECTION, MESSAGES_MAP } from 'config';

// Types
import { SIGN_IN_TYPE, SESSION_TYPE } from 'services/session';
import { OPTION_TYPE } from 'services/options';
import json5 from "json5";
import {GENDERS} from "./index";
import {useSelector} from "react-redux";

// GraphQL
const signUpMutation = loader('src/graphql/mutations/signUp.gql');
const profileMutation = loader('src/graphql/mutations/profile.gql');
const profileQuery = loader('src/graphql/queries/profile.gql');

type USE_STEP_PROPS_TYPE = {
  session?: SESSION_TYPE,
  formValues?: object,
  signIn: SIGN_IN_TYPE,
  roles?: [OPTION_TYPE],
  reset: () => void,
  initialize: (data: object) => void,
};

export const useStep = (props: USE_STEP_PROPS_TYPE) => {
  const {
    formValues,
    reset,
    roles,
    initialize,
  } = props;
  const { id }: { id: string } = useParams();
  const isNew = toLower(id) === 'new';

  const [ editableData, setEditableData ] = useState<object>();

  const {
    loading,
    step,
    values,
    finished,
    limit,
    error,
    fail,
    handleValues,
    handleBack,
    handleNext,
    handleUpdate,
    handleError,
    handleFail,
    handleLoading,
    role,
  } = useContext(UserContext);

  const [
    handleProfileMutation,
    profileMutationResult
  ] = useMutation(
    signUpMutation,
    {
      fetchPolicy: 'no-cache',
      onError: (error: any) => SERVER_ERRORS_HANDLER(error, (error?: any) => handleFail(!!error))
    }
  );

  const [
    handleUpdateProfile,
    profileUpdateResult
  ] = useMutation(
    profileMutation,
    {
      fetchPolicy: 'no-cache',
      onError: (error: any) => SERVER_ERRORS_HANDLER(error, (error?: any) => handleFail(!!error))
    }
  );
  const { loading: profileUpdateLoading, data: profileUpdateData, error: profileUpdateError} = profileUpdateResult;

  useEffect(() => {
    if (!profileUpdateData) {
      return;
    }
    const isError = get(profileUpdateData, 'profile.__typename') === 'Error';
    const message = get(profileUpdateData, 'profile.message');

    const dataError = isError && get(MESSAGES_MAP, `${message}`, message);
    const currentErrors = [
      ...(profileUpdateError ? [ERROR_CONNECTION] : []),
      ...(dataError ? [dataError] : []),
    ];

    currentErrors.length <= 0
      ? handleUpdate({
        step: 2,
      })
      : handleError(join(currentErrors, '\n'));
  }, [profileUpdateLoading, profileUpdateData, profileUpdateError]);

  const { loading: profileMutationLoading, data: profileMutationData, error: profileMutationError } = profileMutationResult;

  const [
    handleProfileQuery,
    profileQueryResult
  ] = useLazyQuery(
    profileQuery,
    {
      fetchPolicy: 'no-cache',
      onError: (error: any) => SERVER_ERRORS_HANDLER(error, (error?: any) => handleFail(!!error))
    }
  );

  useEffect(() => {
    !isNew ? handleProfileQuery({ variables: { id } }) : reset();
  }, [id]);

  const { loading: profileQueryLoading, data: profileQueryData, error: profileQueryError } = profileQueryResult;

  useEffect(() => {
    if (!profileQueryData || loading) {
      return;
    }
    const user = get(profileQueryData, 'profile.user');
    const isError = get(profileQueryData, 'profile.__typename') === 'Error';
    const message = get(profileQueryData, 'profile.message');

    const dataError = isError && get(MESSAGES_MAP, `${message}`, message);
    const currentErrors = [
      ...(profileQueryError ? [ERROR_CONNECTION] : []),
      ...(dataError ? [dataError] : []),
    ];
    currentErrors.length <= 0
      ? setEditableData(user)
      : handleError(join(currentErrors, '\n'));
  }, [loading, profileQueryData, profileQueryError]);

  useEffect(() => {
    editableData && initialize({
      ...editableData,
      role: roles && roles.find(role => role.value === get(editableData, 'roles')[0].name),
      birthday: get(editableData, 'birthday') || moment().format('YYYY-MM-DD'),
      gender: GENDERS.find(gender => gender.value === get(editableData, 'gender'))
    });
  }, [editableData]);

  useEffect(() => {
    if (!profileMutationData || loading) {
      return;
    }
    const isError = get(profileMutationData, 'profile.__typename') === 'Error';
    const message = get(profileMutationData, 'profile.message');

    const dataError = isError && get(MESSAGES_MAP, `${message}`, message);
    const currentErrors = [
      ...(profileMutationError ? [ERROR_CONNECTION] : []),
      ...(dataError ? [dataError] : []),
    ];
    currentErrors.length <= 0
      ? handleNext({ errors: false })
      : handleError(join(currentErrors, '\n'));
  }, [loading, profileMutationData, profileMutationError]);

  const handleSubmit = (event: React.SyntheticEvent) => {
    // get(event, 'preventDefault') && event.preventDefault();
    const REQUIRED_FIELDS = ['name', 'email', 'birthday', 'role', 'password', 'confirmation'];
    const isEditUserEvent = !!editableData;

    const email = replace(
      get(formValues, 'email', get(values, 'email')),
      / /g,
      ''
    );
    const isPhone =
      email && validator.isMobilePhone(email, 'ru-RU', { strictMode: false });
    const name = capitalize(get(formValues, 'name', get(values, 'name', '')));
    const surname = capitalize(get(formValues, 'surname', get(values, 'surname', '')));
    const patronymic = capitalize(get(formValues, 'patronymic', get(values, 'patronymic', '')));
    const birthday = get(formValues, 'birthday', get(values, 'birthday', ''));
    const gender = get(formValues, 'gender', get(values, 'gender', ''))?.value;
    const password = get(formValues, 'password', '');
    const confirmation = get(formValues, 'confirmation', '');
    const role = get(formValues, 'role.value', get(values, 'role.value', ''));
    const blocked = get(formValues, 'blocked', get(values, 'blocked', false));

    const additional_phone = capitalize(get(formValues, 'additional_phone', get(values, 'additional_phone', '')));
    const position = capitalize(get(formValues, 'position', get(values, 'position', '')));
    const address = capitalize(get(formValues, 'address', get(values, 'address', '')));

    const isNewLogin = email !== get(editableData, 'email') && email !== get(editableData, 'phone');

    const stepValues = {
      ...(isNew ? { new: true } : { id }),
      ...(isNewLogin && {[ isPhone ? 'phone' : 'email']: email}),
      name,
      surname,
      patronymic,
      birthday: birthday ? moment(birthday, 'DD-MM-YYYY').format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
      gender,
      password,
      confirmation,
      role,
      blocked,
      additional_phone,
      position,
      address
    };

    // @ts-ignore
    const REQUIRED_ERRORS = Object.entries(stepValues).reduce((reducer, [field, value], i) => {
      const isRequired = REQUIRED_FIELDS
        // если это создание то оставляем все, иначе - оставляем только те, которые не пароль и подтверждение
        .filter(reqField => !isEditUserEvent || !['password', 'confirmation'].includes(reqField))
        .find(reqField => reqField === field);

      if (!value && isRequired) {
        return {
          ...reducer,
          [field]: 'Поле обязательно для заполнения'
        }
      }

      return reducer;
    }, {});

    if (Object.keys(REQUIRED_ERRORS).length > 0) {
      handleError(REQUIRED_ERRORS)
    } else {
      handleValues({
        ...stepValues,
      });

      const usedMutation = isNew ? handleProfileMutation : handleUpdateProfile;

      return usedMutation({ variables: { ...stepValues } });
    }
  };

  useEffect(() => {
    handleLoading(!!profileMutationLoading || !!profileQueryLoading || !!profileUpdateLoading);
  }, [profileMutationLoading, profileQueryLoading, profileUpdateLoading]);

  return {
    loading,
    step,
    finished,
    limit,
    error,
    fail,
    handleBack,
    handleNext,
    handleError,
    handleFail,
    handleLoading,
    handleSubmit,
    role,
  };
};

export default useStep;
