// Libraries
import React from "react";

// Components
import {Page} from "components/Page";
import {H1, Subtitle} from "components/Typography";
import {List, ListItem, ListItemText} from "@material-ui/core";
import Question from "../../Questions/Questions";

// Styles
import styles from "./styles.module.scss";

// Data
import {questions} from "./data";

const PSS = (): JSX.Element => (
  <Page
    classes={{
      root: styles.Root
    }}
  >
    <H1 className={styles.Title}>Шкала родительского стресса</H1>
    <Subtitle className={styles.TextCenter}>
      Ниже приведены утверждения, описывающие чувства и отношение к родительству. Подумайте
      о каждом утверждении в свете ваших отношений с ребенком (детьми). Отметьте, в какой
      степени Вы согласны или не согласны, поставив соответствующую цифру.
    </Subtitle>
    <List
      dense
    >
      <ListItem>
        <ListItemText primary="5 – полностью согласен;" />
        <ListItemText primary="4 – согласен;" />
        <ListItemText primary="3 – не уверен, согласен или нет;" />
        <ListItemText primary="2 – не согласен;" />
        <ListItemText primary="1 – полностью не согласен." />
      </ListItem>
    </List>
    <Question questions={questions} name="parental-stress-scale" />
  </Page>
)

export default PSS;
