import { useEffect, useContext } from 'react';
import { useMutation } from 'react-apollo';
import validator from 'validator';
import { get, replace, join, omit } from 'lodash';
import { loader } from 'graphql.macro';

// Services
import { SIGN_IN_TYPE } from 'services/session';

// Hooks
import { ResetContext } from '../../../../hooks';

// Config
import { SERVER_ERRORS_HANDLER, ERROR_CONNECTION, MESSAGES_MAP, ERROR_PIN_REFETCH, ERROR_PIN_RETRY } from 'config';

// GraphQL
const recoveryQuery = loader('src/graphql/mutations/recovery.gql');
const restoreQuery = loader('src/graphql/mutations/restore.gql');

type USE_STEP_PROPS_TYPE = {
  formValues?: object,
  signIn: SIGN_IN_TYPE,
};

export const useStep = ({
  formValues,
  signIn
}: USE_STEP_PROPS_TYPE) => {

  const usedResetContext = useContext(ResetContext);
  const {
    step,
    attempts,
    loading,
    values,
    handleUpdate,
    handleNext,
    handleError,
    handleFail,
    handleLoading,
    handleValues
  } = usedResetContext;

  const [
    handleRecoveryQuery,
    recoveryQueryResult
  ] = useMutation(
    recoveryQuery,
    {
      fetchPolicy: 'no-cache',
      onError: (error: any) => SERVER_ERRORS_HANDLER(error, (error?: any) => handleFail(!!error))
    }
  );

  const { loading: recoveryQueryLoading, data: recoveryQueryData, error: recoveryQueryError } = recoveryQueryResult;

  const [
    handleRestoreQuery,
    restoreQueryResult
  ] = useMutation(
    restoreQuery,
    {
      fetchPolicy: 'no-cache',
      onError: (error: any) => SERVER_ERRORS_HANDLER(error, (error?: any) => handleFail(!!error))
    }
  );

  const { loading: restoreQueryLoading, data: restoreQueryData, error: restoreQueryError } = restoreQueryResult;

  useEffect(() => {
    handleUpdate({
      handleRefetch: () => {
        handleRecoveryQuery({
          variables: { ...omit(values, ['login', 'pin', 'password', 'confirmation']) }
        });
      }
    });
  }, [values]);

  useEffect(() => {
    handleLoading(!!restoreQueryLoading || !!recoveryQueryLoading);
  }, [restoreQueryLoading, recoveryQueryLoading]);

  useEffect(() => {
    if (!recoveryQueryData || loading) {
      return;
    }
    const isError = get(recoveryQueryData, 'recovery.__typename') === 'Error';
    const message = get(recoveryQueryData, 'recovery.message');

    const dataError = isError && get(MESSAGES_MAP, `${message}`, message);
    const currentErrors = [
      ...(recoveryQueryError ? [ERROR_CONNECTION] : []),
      ...(dataError ? [dataError] : []),
    ];

    currentErrors.length <= 0 && handleError(join(currentErrors, '\n'));

  }, [loading, recoveryQueryData, recoveryQueryError]);

  useEffect(() => {
    if (!restoreQueryData || loading) {
      return;
    }
    const user = get(restoreQueryData, 'restore.user');
    const token = get(restoreQueryData, 'restore.token');
    const isError = get(restoreQueryData, 'restore.__typename') === 'Error';
    const message = get(restoreQueryData, 'restore.message');

    if (token) { 
      signIn({ ...user, token });
    }

    const dataError = isError && get(MESSAGES_MAP, `${message}`, message);
    const currentErrors = [
      ...(restoreQueryError ? [ERROR_CONNECTION] : []),
      ...(dataError ? [dataError] : []),
      ...(!token && step === 1
        ? [attempts <= 1 ? ERROR_PIN_REFETCH : ERROR_PIN_RETRY]
        : [])
    ];

    currentErrors.length <= 0
      ? handleNext({
          error: false
        })
      : handleUpdate({
          error: join(currentErrors, '\n'),
          attempts: attempts > 0 ? attempts - 1 : 0
        });

  }, [loading, restoreQueryData, restoreQueryError]);

  const handleSubmit = (event: React.SyntheticEvent) => {
    get(event, 'preventDefault') && event.preventDefault();
    const login = replace(
      get(formValues, 'login', get(values, 'login')),
      / /g,
      ''
    );
    const pin = parseInt(get(formValues, 'pin', get(values, 'pin', '')), 10);
    const password = get(formValues, 'password', '');
    const confirmation = get(formValues, 'confirmation', '');
    const isPhone =
      login && validator.isMobilePhone(login, 'ru-RU', { strictMode: false });
    
    const stepValues = {
      [isPhone ? 'phone' : 'email'] : login,
      password,
      confirmation,
      pin
    };
    handleValues({ ...stepValues, login });
    return handleRestoreQuery({ variables: { ...stepValues } });
  };

  return {
    ...usedResetContext,
    handleSubmit
  };
};

export default useStep;
