// Libraries
import React from 'react';
import { get } from 'lodash';

// Components
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Button from 'components/Button';

// Styles
import styles from './List.module.scss';

export const PATHS_MAP = {
  '/users': {
    role: 'user',
    title: 'Пользователи',
  },
  '/administrators': {
    role: 'administrator',
    title: 'Администраторы',
  },
  '/specialists': {
    role: 'specialist',
    title: 'Специалисты',
  },
  '/clients': {
    role: 'client',
    title: 'Клиенты',
  },
};

export const COLUMNS_VARIANTS = {
  user: [
    {
      Header: 'Имя',
      accessor: 'name',
    },
    {
      Header: 'Фамилия',
      accessor: 'surname',
    },
    {
      Header: 'Отчество',
      accessor: 'patronymic',
    },
    {
      Header: 'Email',
      accessor: 'email'
    },
    {
      Header: 'Роль',
      accessor: 'role',
    },
    {
      Header: 'Действия',
      accessor: 'actions',
    },
  ],
  administrator: [
    {
      Header: 'Имя',
      accessor: 'name',
    },
    {
      Header: 'Фамилия',
      accessor: 'surname',
    },
    {
      Header: 'Отчество',
      accessor: 'patronymic',
    },
    {
      Header: 'Email',
      accessor: 'email',
    },
    {
      Header: 'Действия',
      accessor: 'actions',
    },
  ],
  specialist: [
    {
      Header: 'Имя',
      accessor: 'name',
    },
    {
      Header: 'Фамилия',
      accessor: 'surname',
    },
    {
      Header: 'Отчество',
      accessor: 'patronymic',
    },
    {
      Header: 'Телефон',
      accessor: 'phone',
    },
    {
      Header: 'Действия',
      accessor: 'actions',
    },
  ],
  client: [
    {
      Header: 'Имя',
      accessor: 'name',
    },
    {
      Header: 'Фамилия',
      accessor: 'surname',
    },
    {
      Header: 'Отчество',
      accessor: 'patronymic',
    },
    {
      Header: 'Пол',
      accessor: 'gender',
    },
    {
      Header: 'Действия',
      accessor: 'actions',
    },
  ],
};

export const GET_ACTIONS_CELL = (user: object): React.ReactElement<typeof Box> => (
  <Box>
    <IconButton
      edge="start"
      color="inherit"
      className={styles.IconButton}
      onClick={get(user, 'onEdit')}
    >
      <EditIcon />
    </IconButton>
    <IconButton
      edge="start"
      color="inherit"
      className={styles.IconButton}
      onClick={get(user, 'onBlock')}
    >
      {get(user, 'blocked') ? <LockIcon /> : <LockOpenIcon />}
    </IconButton>
    <IconButton
      edge="start"
      color="inherit"
      className={styles.IconButton}
      onClick={get(user, 'onDelete')}
    >
      <DeleteForeverIcon />
    </IconButton>
  </Box>
);
