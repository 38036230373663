// Libraries
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { get } from 'lodash';
import { reduxForm } from 'redux-form';

// Services
import { signIn, SESSION_TYPE, SIGN_IN_TYPE, ACTION_TYPE } from 'services/session';

// Components
import Box from '@material-ui/core/Box';

import Link from 'components/Link';
import Button from 'components/Button';
import BaseForm, { Input, Checkbox } from 'components/Form';

// Styles
import styles from './Password.module.scss';

// Hooks
import { useStep } from './hooks';

// Data
import { validate, asyncValidate } from './validators';
import {P} from "../../../../../../../components/Typography";

// Types
type FORM_PROPS_TYPE = {
  valid?: boolean,
  asyncValidating?: boolean | string,
  anyTouched?: boolean,
  handleSubmit: Function,
  formValues?: object,
  signIn: SIGN_IN_TYPE
};

const Form = ({
  valid,
  handleSubmit: formSubmit,
  formValues,
  signIn,
}: FORM_PROPS_TYPE): React.ReactElement<typeof BaseForm> => {
  const { error, handleSubmit } = useStep({
    formValues,
    signIn
  });
  return (
    <BaseForm
      className={styles.Form}
      onSubmit={formSubmit(handleSubmit)}
      name="signIn"
      autoComplete="off"
    >
      <Input
        className={styles.Input}
        key="login"
        label="E-mail или телефон"
        name="login"
        placeholder="E-mail или телефон"
        error={error}
        required
      />
      <Input
        className={styles.Input}
        key="password"
        label="Пароль"
        name="password"
        placeholder="Пароль"
        type="password"
        error={error}
        required
      />
      <Box>
        <Checkbox
          key="remember"
          className={styles.Checkbox}
          name="remember"
          label="Запомнить меня"
        />
        <Link
          className={styles.Link}
          href="/auth/reset"
        >
          Забыли пароль?
        </Link>
        <br />
        <Link
          className={styles.Link}
          href="/auth/sign-up"
        >
          Зарегистрируйтесь
        </Link>
      </Box>
      <Button
        className={styles.Button}
        type="submit"
        disabled={!(valid)}
      >
        Войти
      </Button>
    </BaseForm>
  );
};

const signInForm = reduxForm<object, any>({
  form: 'signIn',
  // destroyOnUnmount: false,
  asyncValidate,
  validate,
  touchOnChange: true,
})(Form);

const mapStateToProps = (state: object): object => ({
  formValues: get(state, 'form.signIn.values'),
});

const mapDispatchToProps = (dispatch: (action: ACTION_TYPE) => any) => ({
  // Services
  signIn: (profile: SESSION_TYPE) => dispatch(signIn(profile))
});

// Exports
export default compose(connect(mapStateToProps, mapDispatchToProps))(signInForm);
