import { useEffect, useContext } from 'react';
import { useMutation } from 'react-apollo';
import validator from 'validator';
import { get, replace, join } from 'lodash';
import { loader } from 'graphql.macro';

// Hooks
import { ResetContext } from '../../../../hooks';

// Config
import { SERVER_ERRORS_HANDLER, ERROR_CONNECTION, MESSAGES_MAP } from 'config';

// GraphQL
const recoveryQuery = loader('src/graphql/mutations/recovery.gql');

type USE_STEP_PROPS_TYPE = {
  formValues?: object,
};

export const useStep = ({
  formValues,
}: USE_STEP_PROPS_TYPE) => {

  const usedResetContext = useContext(ResetContext);
  const {
    loading,
    values,
    handleNext,
    handleError,
    handleFail,
    handleLoading,
    handleValues
  } = usedResetContext;

  const [
    handleRecoveryQuery,
    recoveryQueryResult
  ] = useMutation(
    recoveryQuery,
    {
      fetchPolicy: 'no-cache',
      onError: (error: any) => SERVER_ERRORS_HANDLER(error, (error?: any) => handleFail(!!error))
    }
  );

  const { loading: recoveryQueryLoading, data: recoveryQueryData, error: recoveryQueryError } = recoveryQueryResult;

  useEffect(() => {
    handleLoading(!!recoveryQueryLoading);
  }, [recoveryQueryLoading]);

  useEffect(() => {
    if (!recoveryQueryData || loading) {
      return;
    }
    const isError = get(recoveryQueryData, 'signIn.__typename') === 'Error';
    const message = get(recoveryQueryData, 'signIn.message');

    const dataError = isError && get(MESSAGES_MAP, `${message}`, message);
    const currentErrors = [
      ...(recoveryQueryError ? [ERROR_CONNECTION] : []),
      ...(dataError ? [dataError] : []),
    ];

    currentErrors.length <= 0
      ? handleNext({ error: false })
      : handleError(join(currentErrors, '\n'));

  }, [loading, recoveryQueryData, recoveryQueryError]);

  const handleSubmit = (event: React.SyntheticEvent) => {
    get(event, 'preventDefault') && event.preventDefault();
    const login = replace(
      get(formValues, 'login', get(values, 'login')),
      / /g,
      ''
    );
    const isPhone =
      login && validator.isMobilePhone(login, 'ru-RU', { strictMode: false });
    
    const stepValues = {
      [isPhone ? 'phone' : 'email'] : login,
    };
    handleValues({ ...stepValues, login });
    return handleRecoveryQuery({ variables: { ...stepValues } });
  };

  return {
    ...usedResetContext,
    handleSubmit
  };
};

export default useStep;
