// Libraries
import React from 'react';
import classNames from 'classnames';

// Components
import { Checkbox as BaseCheckbox, FormControlLabel } from '@material-ui/core';
import { Field as BaseField } from 'redux-form';

// Styles
import styles from './Checkbox.module.scss';

interface CHECKBOX_PROPS_TYPE {
  className?: string,
  classes?: {
    root?: string,
    checkbox?: string
  },
  input?: any,
  name?: string,
  label?: React.ReactNode,
  validate?: Function[],
  meta?: {
    touched?: boolean,
    error?: string,
    invalid?: boolean
  },
  required?: boolean
}

const Checkbox = ({
  className,
  classes,
  input,
  label,
  meta,
}: CHECKBOX_PROPS_TYPE): React.ReactElement<typeof FormControlLabel> => (
  <FormControlLabel
    className={classNames(
      className,
      classes?.root,
      styles.Root,
      {
        [styles.RootIsError]: meta?.touched && meta?.invalid
      }
    )}
    label={label}
    control={(
      <BaseCheckbox
        className={classNames(
          classes?.checkbox,
          styles.Checkbox
        )}
        checked={!!input.value}
        onChange={input.onChange}
        onClick={() => input.onChange(!input.value)}
        data-checked={input.value}
        disableRipple
      />
    )}
  />
);

const Field = ({
  name,
  validate,
  classes,
  ...props
}: CHECKBOX_PROPS_TYPE): React.ReactElement<typeof BaseField> => (
  <BaseField
    name={name}
    type="checkbox"
    component={Checkbox}
    validate={validate}
    {...props}
  />
);

export default Field;
