
// Libraries
import React from 'react';

// Components
import Button from 'components/Button';
import Link from "../../../../../../../components/Link";


const Step1 = () => {
  return (
    <div>
      <div style={{margin: '30px 0', fontSize: 20}}>Создание пользователя прошло успешно!</div>
      <Link href='/users'>
        <Button>
          Вернуться к списку пользователей
        </Button>
      </Link>
    </div>
  )
};

export default Step1;