// Libraries
import React from 'react';
import classNames from 'classnames';

// Components
import { Box } from '@material-ui/core';
import InfoOutlined from '@material-ui/icons/InfoOutlined';

import { P } from 'components/Typography';
import Link from 'components/Link';

// Styles
import styles from './Additional.module.scss';

// Hooks
import { SignUpContext } from '../hooks';

type ADDITIONAL_PROPS_TYPE = {
  className?: string,
  classes?: {
    root?: string
  },
};

const Additional = ({
  className,
  classes
}: ADDITIONAL_PROPS_TYPE): React.ReactElement<typeof Box> | null => {
  const { step, finished, fail, handleBack } = React.useContext(SignUpContext);

  const data = [
    <>
      <span className={styles.Paragraph}>Уже зарегистрированы?</span>
      <br />
      <Link className={styles.Link} href="/auth/sign-in">
        Войдите
      </Link>
    </>
  ][step];

  return (
    !finished &&
    !fail ? (
      <Box
        className={classNames(
          className,
          classes?.root,
          styles.Root,
        )}
        data-step={step}
      >
        <P className={styles.Paragraph}>
          <br />
          {step === 0 ? (
            data
          ) : step !== 2 && (
            <Link
              className={styles.Link}
              onClick={handleBack}
            >
              Назад
            </Link>
          )}
          <span> или </span>
          <Link className={styles.Link} href="/feedback">
            Задайте вопрос
          </Link>
        </P>
      </Box>
    ):
    null
  );
};

// Exports
export default Additional;
