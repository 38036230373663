// Libraries
import React from 'react';

// Components
import Link from 'components/Link';
import { P } from 'components/Typography';
import classNames from 'classnames';
import { get } from 'lodash';

// Styles
import styles from './Informer.module.scss';

// Hooks
import { SignInContext, SIGN_TYPE_PASSWORD, SIGN_TYPE_PIN } from '../hooks';

// Data
import { ATTEMPTS_LIMIT } from '../../data';

// Types
type INFORMER_PROPS_TYPE = {
  className?: string,
  classes?: {
    root?: string
  },
};

const Informer = ({
  className,
  classes
}: INFORMER_PROPS_TYPE): React.ReactElement<typeof P> => {
  const {
    signType,
    step,
    login,
    handleBack,
    isPhone
  } = React.useContext(SignInContext);

  const data = get({
    [SIGN_TYPE_PASSWORD]: [],
    [SIGN_TYPE_PIN]: [
      <>
        Куда прислать код – на почту или через смс?
      </>,
      <>
        Мы отправили код на {login}
        <br />
        <Link
          className={styles.Link}
          onClick={() => handleBack({ attempts: ATTEMPTS_LIMIT, error: false })}
        >
          Изменить {isPhone ? 'номер' : 'e-mail'}
        </Link>
      </>
    ]
  }, `[${signType}][${step}]`);

  return (
    <P
      className={classNames(
        className,
        classes?.root,
        styles.Root,
      )}
    >
      {data}
    </P>
  );
};

// Exports
export default Informer;
