import main from './main';
import housing from './housing';
import health from './health';
import selfservice from './selfservice';
import education from './education';
import identity from './identity';
import emotional from './emotional';
import family from './family';
import behavior from './behavior';
import conclusions from './conclusions';
import goals from './goals';

export interface DATA_ITEM_TYPE {
  id: string,
  name: string,
  data: any,
};
export type DATA_TYPE = DATA_ITEM_TYPE[];

const DATA: DATA_TYPE = [
  main,
  housing,
  health,
  selfservice,
  education,
  identity,
  emotional,
  family,
  behavior,
  conclusions,
  goals
];

export default DATA;
