// Libraries
import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { get } from 'lodash';
import { Route, Switch } from 'react-router-dom';

// Components
import Network from './Network';
import Permission from './Permission';
import NotFound from './NotFound';

// Services
import { ACTION_TYPE } from 'services/session';

type ERRORS_PROPS_TYPE = {
  match: {
    url: string
  }
};

const Errors = ({ match }: ERRORS_PROPS_TYPE): React.ReactElement<typeof Switch> => (
  <Switch>
    <Route exact component={Network} path="/errors/network" />
    <Route exact component={Permission} path="/errors/permission" />
    <Route component={NotFound} path={match.url} />
  </Switch>
);

// Exports
const mapStateToProps = (state: object): object => ({
  session: get(state, 'session')
});

const mapDispatchToProps = (dispatch: (action: ACTION_TYPE) => any) => ({
  // Services
});

// Exports
export default compose<ERRORS_PROPS_TYPE, any>(connect(mapStateToProps, mapDispatchToProps))(Errors);
