// Libraries
import React from 'react';
import classNames from 'classnames';

// Components
import { Box } from '@material-ui/core';
import InfoOutlined from '@material-ui/icons/InfoOutlined';

import { P } from 'components/Typography';

import Timer from '../../components/Timer';

// Styles
import styles from './Additional.module.scss';

// Hooks
import { ResetContext } from '../hooks';

// Types
type ADDITIONAL_PROPS_TYPE = {
  className?: string,
  classes?: {
    root?: string
  },
};

const Additional = ({
  className,
  classes
}: ADDITIONAL_PROPS_TYPE): React.ReactElement<typeof Box> => {
  const { step, finished, handleRefetch, attempts } = React.useContext(
    ResetContext
  );
  const data = [null, attempts <= 0 && <Timer onRefetch={handleRefetch} />][
    step
  ];

  return (
    <Box
      className={classNames(
        className,
        classes?.root,
        styles.Root,
      )}
    >
      <P className={styles.Paragraph}>
        <InfoOutlined />
        <br />
        {!finished && data}
      </P>
    </Box>
  );
};

// Exports
export default Additional;
