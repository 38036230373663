// Libraries
import React from 'react';
import { get } from 'lodash';

// Styles
import styles from './Wizard.module.scss';

// Components
import Login from './components/Login';
import Pin from './components/Pin';
import Password from './components/Password';

// Hooks
import { SignInContext, SIGN_TYPE_PASSWORD, SIGN_TYPE_PIN } from '../../hooks';

const STEPS = [
  {
    [SIGN_TYPE_PASSWORD]: Password,
    [SIGN_TYPE_PIN]: Login
  },
  {
    [SIGN_TYPE_PASSWORD]: null,
    [SIGN_TYPE_PIN]: Pin
  },
];

const Wizard = () => {
  const { step, signType } = React.useContext(SignInContext);
  const Step = get(STEPS, `[${step}][${signType}]`);
  return (
    <div className={styles.Root}>
      {
        Step && <Step />
      }
    </div>
  );
};

// Exports
export default Wizard;
