// Libraries
import React from 'react';

export const PageContext = React.createContext({
  menuIsOpen: false,
  setMenuIsOpen: (menuIsOpen: boolean) => {}
});

export const usePage = () => {
  
  const [menuIsOpen, setMenuIsOpen] = React.useState(false);

  return {
    menuIsOpen,
    setMenuIsOpen
  };
};

export default usePage;
