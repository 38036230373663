// Libraries
import React, { Fragment } from 'react';
import { v4 as uuidv4 } from 'uuid';

// Components
import {H1, H5, Subtitle} from "components/Typography";
import { Paper } from '@material-ui/core';
import {Input, Select} from "components/Form";
import AnalysisFields from "../../AnalysisFields/AnalysisFields";

// Styles
import styles from '../styles.module.scss';

type SELFSERVICE_PROPS_TYPE = {
  error: any,
};

const DATA = ({ error }: SELFSERVICE_PROPS_TYPE): React.ReactElement<typeof Fragment> => (
  <div className={styles.FormSegment}>
    <H1 className={styles.Title}>Навыки самообслуживания и социальные навыки</H1>
    <H5>Потребности ребёнка</H5>
    <Paper className={styles.Paper}>
      <div className={styles.QuestionContainer}>
        <Subtitle>В норме ли уровень личной гигиены ребенка? (навыки опрятности, зубы, волосы, ногти и пр.)</Subtitle>
        <Select
          className={styles.QuestionContainerSelect}
          name="hygiene"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="hygiene_description"
          multiline
          rowsMax={4}
          label="Комментарий"
          placeholder="Комментарий"
        />
      </div>
      <div className={styles.QuestionContainer}>
        <Subtitle>Уверено ли ребенок владеет навыками самообслуживания соответственно возрасту?</Subtitle>
        <Select
          className={styles.QuestionContainerSelect}
          name="self_service"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="self_service_description"
          multiline
          rowsMax={4}
          label="Комментарий"
          placeholder="Комментарий"
        />
      </div>
      <div className={styles.QuestionContainer}>
        <Subtitle>Слушается ли ребенок родителей?</Subtitle>
        <Select
          className={styles.QuestionContainerSelect}
          name="behavior"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="behavior_desc"
          multiline
          rowsMax={4}
          label="Комментарий"
          placeholder="Комментарий"
        />
      </div>
      <div className={styles.QuestionContainer}>
        <Subtitle>Владеет ли ребенок социальными навыками? (поведение на улице, общение со сверстниками  и т.д.)</Subtitle>
        <Select
          className={styles.QuestionContainerSelect}
          name="social_skills"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="social_skills_desc"
          multiline
          rowsMax={4}
          label="Комментарий"
          placeholder="Комментарий"
        />
      </div>
      <div className={styles.QuestionContainer}>
        <Subtitle>Есть ли у ребенка адекватное чувство опасности?</Subtitle>
        <Select
          className={styles.QuestionContainerSelect}
          name="sense_danger"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="sense_danger_desc"
          multiline
          rowsMax={4}
          label="Комментарий"
          placeholder="Комментарий"
        />
      </div>
      <div className={styles.QuestionContainer}>
        <Subtitle>Может ли ребенок проводить время с другими детьми и их семьями?</Subtitle>
        <Select
          className={styles.QuestionContainerSelect}
          name="spend_time_with_children"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="spend_time_with_children_desc"
          multiline
          rowsMax={4}
          label="Комментарий"
          placeholder="Комментарий"
        />
      </div>
    </Paper>
    <H5>Возможности родителей / лиц, их заменяющих</H5>
    <Paper className={styles.Paper}>
      <div className={styles.QuestionContainer}>
        <Subtitle>В норме ли навыки самообслуживания родителей?</Subtitle>
        <Select
          className={styles.QuestionContainerSelect}
          name="self_service_parent"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="self_service_parent_description"
          multiline
          rowsMax={4}
          label="Комментарий"
          placeholder="Комментарий"
        />
      </div>
      <div className={styles.QuestionContainer}>
        <Subtitle>Ухаживают ли родители за ребенком должным образом в соответствии с возрастом и состоянием ребенка?</Subtitle>
        <Select
          className={styles.QuestionContainerSelect}
          name="courting_parent"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="courting_parent_description"
          multiline
          rowsMax={4}
          label="Комментарий"
          placeholder="Комментарий"
        />
      </div>
      <div className={styles.QuestionContainer}>
        <Subtitle>Умеют ли родители обращаться за помощью? Развиты ли у родителей коммуникативные навыки?</Subtitle>
        <Select
          className={styles.QuestionContainerSelect}
          name="communication_skills_parent"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="communication_skills_parent_description"
          multiline
          rowsMax={4}
          label="Комментарий"
          placeholder="Комментарий"
        />
      </div>
    </Paper>
    <AnalysisFields title="Навыки самообслуживания и социальные навыки" name="communication_skills" />
  </div>
);

const SELFSERVICE = {
  id: uuidv4(),
  name: 'Самообслуживание',
  data: DATA,
};

export default SELFSERVICE;
