// Libraries
import * as yup from 'yup';
import { get } from 'lodash';

import { asyncValidator } from 'components/Form';

// Validations
import {
  VALIDATE_LOGIN,
  VALIDATE_PHONE,
  VALIDATE_PASSWORDS
} from 'helpers/validations';

// Config
import {
  ERROR_PASSWORD_MIN,
  ERROR_PASSWORD_EQUAL
} from 'config';

export const validate = (values: object) => {
  const login = VALIDATE_LOGIN(get(values, 'login', ''));
  const additional_phone = VALIDATE_PHONE(get(values, 'additional_phone', ''));
  const passwords = VALIDATE_PASSWORDS({
    password: get(values, 'password', ''),
    confirmation: get(values, 'confirmation', ''),
  });

  return {
    ...(login && { login }),
    ...(additional_phone && { additional_phone }),
  };
};

export const asyncValidate = asyncValidator(
  yup.object().shape({})
);
