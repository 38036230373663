// Libraries
import React, {useMemo, memo} from "react";

// Components
import {Subtitle} from "components/Typography";
import {Select} from "components/Form";
import { Paper } from "@material-ui/core";
import {Button} from "components/Button/Button";

// Styles
import styles from "../ODREVs/ODREV/styles.module.scss";

// Types
import {Question} from "types";

interface QuestionsProps {
  readonly questions: Question[];
  readonly name: string;
}

const Questions = ({ questions, name }: QuestionsProps): JSX.Element => {
  const renderQuestions = useMemo((): JSX.Element[] => questions.map((question, i): JSX.Element => (
    <React.Fragment key={question.statement}>
      <Subtitle>{ `${i + 1}. ${question.statement}` }</Subtitle>
      <Select
        className={styles.Input}
        name={`statement_${name}_${i}`}
        fullWidth
      />
    </React.Fragment>
  )), []);

  return (
    <>
      <Paper className={styles.Paper}>
        { renderQuestions }
      </Paper>
      <Button
        className={styles.SubmitBtn}
        type="submit"
      >
        Сохранить
      </Button>
    </>
  )
};

export default memo(Questions);
