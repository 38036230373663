// Libraries
import React from 'react';
import classNames from 'classnames';

// Components
import { default as BaseButton } from '@material-ui/core/Button';

import Loader from 'components/Loader';

// Styles
import styles from './Button.module.scss';

const VARIANT_CONTAINED = 'contained';
const VARIANT_TEXT = 'text';
const VARIANT_OUTLINED = 'outlined';

export type BUTTON_PROPS_TYPES = {
  children?: React.ReactNode,
  classes?: {
    root?: string
  },
  style?: string,
  className?: string,
  disabled?: boolean,
  fullWidth?: boolean,
  loading?: boolean,
  onClick?: ((event: React.MouseEvent) => void),
  href?: string,
  disableRipple?: boolean,
  variant?: 'contained' | 'text' | 'outlined',
  type?: 'button' | 'submit' | 'reset',
  icon?: any,
  component?: any
};

export const Button = ({
  children,
  classes = {},
  style,
  className,
  disabled,
  fullWidth,
  loading,
  onClick,
  href = '',
  disableRipple = true,
  variant = VARIANT_CONTAINED,
  type = 'button',
  icon,
  component
}: BUTTON_PROPS_TYPES): React.ReactElement<typeof BaseButton> => {
  return (
    <BaseButton
      className={classNames(
        className,
        classes.root,
        styles.Root,
        {
          [styles.RootIsLoading]: loading,
          [styles.RootIsFullWidth]: fullWidth
        },
        {
          [styles.RootVariantContained]: variant === VARIANT_CONTAINED,
          [styles.RootVariantText]: variant === VARIANT_TEXT,
          [styles.RootVariantOutlined]: variant === VARIANT_OUTLINED,
        }
      )}
      disabled={disabled || loading}
      onClick={onClick}
      to={href}
      component={component ? component : 'button'}
      variant={variant}
      disableRipple={disableRipple}
      {
        ...{
          type,
          icon
        }
      }
    >
      {children}
      {loading && <Loader size={24} className={styles.CircularProgress} />}
    </BaseButton>
  );
};

export default Button;
