import { get } from 'lodash';
import Cookies from 'js-cookie';

import { SIGN_IN, SIGN_OUT, UPDATE_USER, SESSION_TYPE, ACTION_TYPE } from './types';

const initialState: SESSION_TYPE = {
  name: '',
  email: '',
  token: '',
  error: '',
  expire: 0,
};

export const reducer = (
  state: SESSION_TYPE = initialState,
  action: ACTION_TYPE
): SESSION_TYPE => {
  const expire = get(state, 'expire', 0);
  const isExpired = expire > 0 && expire < Date.now();
  const type = isExpired ? SIGN_OUT : action.type;
  const extended = { ...state, ...action.payload };

  switch (type) {
    case SIGN_IN:
      Cookies.set('token', get(action, 'payload.token'));
      return {
        ...extended,
        expire: Date.now() + 86400000,
      };
    case UPDATE_USER:
      return {
        ...extended
      };
    case SIGN_OUT:
      Cookies.remove('token');
      return initialState;
    default:
      return state;
  }
};

export default reducer;
