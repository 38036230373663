// Libraries
import React from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { get } from 'lodash';
import {v4 as uuidv4} from "uuid";

// Components
import Button from 'components/Button';
import BaseForm, {Input, Select, Checkbox, DatePicker} from 'components/Form';
import {Link} from 'react-router-dom';

// Styles
import styles from './styles.module.scss';

// Services
import { signIn, SESSION_TYPE, SIGN_IN_TYPE, ACTION_TYPE } from 'services/session';
import { OPTION_TYPE } from 'services/options';

// Hooks
import { useStep } from './hooks';

// Data
import { validate, asyncValidate } from './validators';

type FORM_PROPS_TYPE = {
  valid?: boolean,
  asyncValidating?: boolean | string,
  anyTouched?: boolean,
  formValues?: object,
  session?: SESSION_TYPE,
  genders?: [OPTION_TYPE],
  clientsStatuses?: [OPTION_TYPE],
  roles?: [OPTION_TYPE],
  signIn: SIGN_IN_TYPE,
  handleSubmit: Function,
  initialize: (data: object) => void,
  reset: () => void,
};

export const GENDERS = [
  {
    id: uuidv4(),
    title: 'Мужской',
    value: 'male'
  },
  {
    id: uuidv4(),
    title: 'Женский',
    value: 'female'
  }
];

const Form = ({
  formValues,
  session,
  roles,
  signIn,
  handleSubmit: formSubmit,
  initialize,
  reset,
}: FORM_PROPS_TYPE): React.ReactElement<typeof BaseForm> => {
  const USED_ROLES = ['administrator', 'specialist'];
  // @ts-ignore
  const filteredRoles = roles.filter(({ value }) => USED_ROLES.includes(value));

  const { error, handleSubmit, role } = useStep({
    session,
    formValues,
    signIn,
    reset,
    initialize,
    // @ts-ignore
    roles: filteredRoles
  });

  return (
    <BaseForm
      className={styles.Form}
      onSubmit={formSubmit(handleSubmit)}
      name="user"
      autoComplete="off"
    >
      <Input
        className={styles.Input}
        key="name"
        label="Имя"
        name="name"
        placeholder="Имя"
        required
        error={error?.name}
      />
      <Input
        className={styles.Input}
        key="surname"
        label="Фамилия"
        name="surname"
        placeholder="Фамилия"
      />
      <Input
        className={styles.Input}
        key="patronymic"
        label="Отчество"
        name="patronymic"
        placeholder="Отчество"
      />
      <Input
        className={styles.Input}
        key="email"
        name="email"
        label="E-mail"
        placeholder="E-mail"
        required
        autoComplete="off"
        error={error?.email}
      />
      <Select
        className={styles.Input}
        key="gender"
        name="gender"
        label="Пол"
        placeholder="Пол"
        options={GENDERS}
      />
      <DatePicker
        className={styles.Input}
        key={`birthday`}
        label="Дата рождения"
        name={`birthday`}
        placeholder="Дата рождения"
        error={error?.birthday}
      />
      <input name="gender" type="hidden" />
      <Select
        className={styles.Input}
        key="role"
        name="role"
        label="Роль"
        placeholder="Роль"
        required
        options={filteredRoles}
        error={error?.role}
        // autoSelect
      />
      <Input
        className={styles.Input}
        key="password"
        label="Новый пароль"
        name="password"
        placeholder="Новый пароль"
        type="password"
        autoComplete="new-password"
        error={error?.password}
        required
      />
      <Input
        className={styles.Input}
        key="confirmation"
        label="Повторить пароль"
        name="confirmation"
        placeholder="Повторить пароль"
        type="password"
        autoComplete="off"
        error={error?.confirmation}
        required
      />
      <Checkbox
        key="blocked"
        className={styles.Checkbox}
        name="blocked"
        label="Заблокирован"
      />
      <div className={styles.Actions}>
        <Button
          className={styles.Button}
          icon="arrow"
          type="submit"
        >
          Сохранить
        </Button>
        <Link to='/users' style={{textDecoration: 'none'}}>
          <Button
            className={styles.Button}
            icon="arrow"
            type="button"
          >
            Отмена
          </Button>
        </Link>
      </div>
    </BaseForm>
  );
};

// Exports
const userForm = reduxForm<object, any>({
  form: 'user',
  // destroyOnUnmount: false,
  asyncValidate,
  validate,
  touchOnChange: true,
  enableReinitialize: true,
})(Form);

const mapStateToProps = (state: object): object => ({
  session: get(state, 'session'),
  genders: get(state, 'options.genders'),
  roles: get(state, 'options.roles'),
  clientsStatuses: get(state, 'options.clients_statuses'),
  formValues: get(state, 'form.user.values')
});

const mapDispatchToProps = (dispatch: (action: ACTION_TYPE) => any) => ({
  // Services
  signIn: (user: SESSION_TYPE) => dispatch(signIn(user)),
});

// Exports
export default compose(connect(mapStateToProps, mapDispatchToProps))(userForm);
