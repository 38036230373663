// Libraries
import React from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { get } from 'lodash';

// Components
import Button from 'components/Button';
import Tabs from 'components/Tabs';
import BaseForm from 'components/Form';

// Styles
import styles from './styles.module.scss';

// Services
import { signIn, SESSION_TYPE, SIGN_IN_TYPE, ACTION_TYPE } from 'services/session';
import { OPTION_TYPE } from 'services/options';

// Hooks
import { useStep } from './hooks';

// Data
import DATA from './data';
import { validate, asyncValidate } from './validators';

// Types
import {TabElement} from "types/index";

type FORM_PROPS_TYPE = {
  valid?: boolean,
  asyncValidating?: boolean | string,
  anyTouched?: boolean,
  formValues?: object,
  session?: SESSION_TYPE,
  genders?: [OPTION_TYPE],
  clientsStatuses?: [OPTION_TYPE],
  roles?: [OPTION_TYPE],
  signIn: SIGN_IN_TYPE,
  handleSubmit: Function,
  initialize: (data: object) => void,
  reset: () => void,
};

const Form = ({
  valid,
  anyTouched,
  asyncValidating,
  formValues,
  session,
  genders,
  clientsStatuses,
  roles,
  signIn,
  handleSubmit: formSubmit,
  initialize,
  reset,
}: FORM_PROPS_TYPE): React.ReactElement<typeof BaseForm> => {
  const { error, handleSubmit } = useStep({
    session,
    formValues,
    signIn,
    reset,
    initialize,
  });

  return (
    <BaseForm
      className={styles.Form}
      onSubmit={formSubmit(handleSubmit)}
      name="EFO"
      autoComplete="off"
    >
      <Tabs
        data={
          DATA.map((item: TabElement) => (
            {
              ...item,
              data: item.data({ error, formValues }),
            }
          ))
        } />
      <Button
        className={styles.Button}
        icon="arrow"
        type="submit"
      >
        Сохранить
      </Button>
    </BaseForm>
  );
};

// Exports
const EFOForm = reduxForm<object, any>({
  form: 'EFO',
  // destroyOnUnmount: false,
  asyncValidate,
  validate,
  touchOnChange: true,
  // enableReinitialize: true,
})(Form);

const mapStateToProps = (state: object): object => ({
  session: get(state, 'session'),
  genders: get(state, 'options.genders'),
  roles: get(state, 'options.roles'),
  clientsStatuses: get(state, 'options.clients_statuses'),
  formValues: get(state, 'form.EFO.values'),
  
  // initialValues: get(state, 'temporary.initial'),
});

const mapDispatchToProps = (dispatch: (action: ACTION_TYPE) => any) => ({
  // Services
  signIn: (EFO: SESSION_TYPE) => dispatch(signIn(EFO)),
});

// Exports
export default compose(connect(mapStateToProps, mapDispatchToProps))(EFOForm);
