// Libraries
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { get } from 'lodash';
import { reduxForm } from 'redux-form';

// Services
import { ACTION_TYPE } from 'services/session';

// Components

import Button from 'components/Button';
import BaseForm, { Input } from 'components/Form';

// Styles
import styles from './Recovery.module.scss';

// Hooks
import { useStep } from './hooks';

// Data
import { validate, asyncValidate } from './validators';

// Types
type FORM_PROPS_TYPE = {
  valid?: boolean,
  asyncValidating?: boolean | string,
  anyTouched?: boolean,
  handleSubmit: Function,
  formValues?: object,
};

const Form = ({
  valid,
  handleSubmit: formSubmit,
  formValues,
}: FORM_PROPS_TYPE): React.ReactElement<typeof BaseForm> => {
  const { error, handleSubmit } = useStep({
    formValues,
  });
  return (
    <BaseForm
      className={styles.Form}
      onSubmit={formSubmit(handleSubmit)}
      name="reset"
      autoComplete="off"
    >
      <Input
        className={styles.Input}
        key="login"
        label="E-mail или телефон"
        name="login"
        placeholder="E-mail или телефон"
        error={error}
        required
      />
      <Button
        className={styles.Button}
        type="submit"
        disabled={!(valid)}
      >
        Получить код
      </Button>
    </BaseForm>
  );
};

const resetForm = reduxForm<object, any>({
  form: 'reset',
  // destroyOnUnmount: false,
  asyncValidate,
  validate,
  touchOnChange: true,
})(Form);

const mapStateToProps = (state: object): object => ({
  formValues: get(state, 'form.reset.values'),
});

const mapDispatchToProps = (dispatch: (action: ACTION_TYPE) => any) => ({
  // Services
});

// Exports
export default compose(connect(mapStateToProps, mapDispatchToProps))(resetForm);
