// Libraries
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { get } from 'lodash';
import validator from 'validator';
import classNames from 'classnames';

// Components
import Link from 'components/Link';
import { P } from 'components/Typography';

// Services
import { ACTION_TYPE } from 'services/session';

// Styles
import styles from './Informer.module.scss';

// Hooks
import { ProfileContext } from '../hooks';

type INFORMER_PROPS_TYPE = {
  className?: string,
  classes?: {
    root?: string
  },
  formValues?: object
};

const Informer = ({
  className,
  classes,
  formValues
}: INFORMER_PROPS_TYPE): React.ReactElement<typeof P> => {

  const { values, step, finished, fail, handleBack } = React.useContext(ProfileContext);

  const login = get(values, 'login', '');
  const name = get(values, 'name', '');
  const isPhone = validator.isMobilePhone(login, 'ru-RU', {
    strictMode: false
  });

  const data = [
    undefined,
    <>
      {name}, мы отправили код на {login}
      <br />
      <Link
        className={styles.Link}
        href="/auth/profile"
        onClick={handleBack}
      >
        Изменить {isPhone ? 'номер' : 'e-mail'}
      </Link>
    </>,
  ][step];

  return (
    <P
      className={classNames(
        className,
        classes?.root,
        styles.Root,
      )}
    >
      {fail ? (
        <>
          Но мы уже все исправляем. <br />
          Попробуйте вернуться позже.
        </>
      ) : finished ? (
        <>
          Профиль успешно обновлен.
        </>
      ) : (
        data
      )}
    </P>
  );
};

// Exports
const mapStateToProps = (state: object): object => ({
  formValues: get(state, 'form.profile.values', {})
});

const mapDispatchToProps = (dispatch: (action: ACTION_TYPE) => any) => ({
  // Services
});

// Exports
export default compose<INFORMER_PROPS_TYPE, any>(connect(mapStateToProps, mapDispatchToProps))(Informer);
