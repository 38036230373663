// Hooks
import { useForm, createFormContext } from '../../../hooks';

export const SIGN_TYPE_PASSWORD = 'password';
export const SIGN_TYPE_PIN = 'pin';

export const SignInContext = createFormContext();

export const useSignIn = useForm;
export default useSignIn;
