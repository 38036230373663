// Libraries
import React from 'react';
import classNames from 'classnames';

// Components
import { Caption } from 'components/Typography';

// Styles
import styles from './Finish.module.scss';

const Finish = () => (
  <Caption
    className={classNames(
      styles.Root,
      styles.Paragraph,
    )}
  >
    При возникновении проблем, обращайтесь в службу поддержки.
  </Caption>
);

// Exports
export default Finish;
