// Libraries
import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { get } from 'lodash';

// Services
import { ACTION_TYPE } from 'services/session';

// Components
import Page from 'components/Page';

import Title from './components/Title';
import Informer from './components/Informer';
import Wizard from './components/Wizard';
import Additional from './components/Additional';

// Styles
import styles from './SignIn.module.scss';

// Hooks
import {useSignIn, SignInContext, SIGN_TYPE_PIN, SIGN_TYPE_PASSWORD} from './hooks';

// Data
import { ATTEMPTS_LIMIT } from '../data';

type SIGN_IN_PROPS_TYPE = {
  token?: string,
};

const SignIn = ({
  token,
}: SIGN_IN_PROPS_TYPE): React.ReactElement<typeof Redirect | typeof Page> => {
  const usedSignIn = useSignIn({
    isPhone: false,
    signType: SIGN_TYPE_PASSWORD,
    limit: 2,
    attempts: ATTEMPTS_LIMIT,
  });
  const { loading } = usedSignIn;
  return (
    <>
      {
        token ? (
          <Redirect to="/" />
        ) : (
          <Page
            classes={{ root: styles.Root }}
            loading={loading}
          >
            <div className={styles.Wrapper}>
              <SignInContext.Provider value={usedSignIn}>
                <Title className={styles.Title}/>
                <div className={styles.Actions}>
                  <Informer className={styles.Informer} />
                  <Wizard />
                </div>
              </SignInContext.Provider>
            </div>
          </Page>
        )
      }
    </>
  );
};

const mapStateToProps = (state: object): object => ({
  token: get(state, 'session.token')
});

const mapDispatchToProps = (dispatch: (action: ACTION_TYPE) => any) => ({
  // Services
});

// Exports
export default compose<SIGN_IN_PROPS_TYPE, any>(connect(mapStateToProps, mapDispatchToProps))(SignIn);
