// Libraries
import React, { Fragment } from 'react';
import { v4 as uuidv4 } from 'uuid';

// Components
import {Caption, H1, H5, Subtitle} from "components/Typography";
import { Paper } from '@material-ui/core';
import {Input, Select} from "components/Form";
import AnalysisFields from "../../AnalysisFields/AnalysisFields";

// Styles
import styles from '../styles.module.scss';

type EDUCATION_PROPS_TYPE = {
  error: any,
};

const DATA = ({ error }: EDUCATION_PROPS_TYPE): React.ReactElement<typeof Fragment> => (
  <div className={styles.FormSegment}>
    <H1
      className={styles.Title}
    >
      Образование
    </H1>
    <Caption>
      (включая стимулирование развития для детей раннего возраста)
    </Caption>
    <H5
      className={styles.H5}
    >
      Потребности ребенка
    </H5>
    <Paper className={styles.Paper}>
      <div className={styles.QuestionContainer}>
        <Subtitle>Регулярно ли ребенок посещает образовательное учреждение? Имеет ли пропуски и опоздния? (садик, школа, и др.)</Subtitle>
        <Select
          className={styles.QuestionContainerSelect}
          name="attends_educational"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="attends_educational_description"
          multiline
          rowsMax={4}
          label="Если нет, укажите, как часто пропускает и причину"
          placeholder="Если нет, укажите, как часто пропускает и причину"
        />
      </div>
      <div className={styles.QuestionContainer}>
        <Subtitle>Есть ли у ребенка трудности с концентрацией внимания?</Subtitle>
        <Select
          className={styles.QuestionContainerSelect}
          name="concentration"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="concentration_description"
          multiline
          rowsMax={4}
          label="Коммент"
          placeholder="Коммент"
        />
      </div>
      <div className={styles.QuestionContainer}>
        <Subtitle>Требуется ли провести оценку образовательного уровня ребенка?</Subtitle>
        <Select
          className={styles.QuestionContainerSelect}
          name="assessment_educational_level"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="assessment_educational_level_description"
          multiline
          rowsMax={4}
          label="Коммент"
          placeholder="Коммент"
        />
      </div>
      <div className={styles.QuestionContainer}>
        <Subtitle>Получает ли ребенок дополнительное образование? (в т.ч. развивающие занятия)</Subtitle>
        <Select
          className={styles.QuestionContainerSelect}
          name="further_education"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="further_education_description"
          multiline
          rowsMax={4}
          label="Коммент"
          placeholder="Коммент"
        />
      </div>
    </Paper>
    <H5
      className={styles.H5}
    >
      Возможности родителей/лиц, их заменяющих
    </H5>
    <Paper className={styles.Paper}>
      <div className={styles.QuestionContainer}>
        <Subtitle>Положительно ли относятся взрослые к образованию ребенка и посещению образовательных учреждений?</Subtitle>
        <Select
          className={styles.QuestionContainerSelect}
          name="visiting_educational_institutions"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="visiting_educational_institutions_description"
          multiline
          rowsMax={4}
          label="Комментарий"
          placeholder="Комментарий"
        />
      </div>
      <div className={styles.QuestionContainer}>
        <Subtitle>Обеспечен ли ребенок всем необходимым для его развития?</Subtitle>
        <Select
          className={styles.QuestionContainerSelect}
          name="child_provided_everything"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="child_provided_everything_description"
          multiline
          rowsMax={4}
          label="Комментарий"
          placeholder="Комментарий"
        />
      </div>
      <div className={styles.QuestionContainer}>
        <Subtitle>Принимают ли взрослые меры по преодолению каких-либо трудностей с обучением ребенка?</Subtitle>
        <Select
          className={styles.QuestionContainerSelect}
          name="measures_overcome_difficulties"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="measures_overcome_difficulties_description"
          multiline
          rowsMax={4}
          label="Комментарий"
          placeholder="Комментарий"
        />
      </div>
      <div className={styles.QuestionContainer}>
        <Subtitle>Предоставляют ли взрослые стимулирование и общение, необходимые для развития ребенка, особенно для ребенка раннего возраста?</Subtitle>
        <Select
          className={styles.QuestionContainerSelect}
          name="stimulation_communication"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="stimulation_communication_description"
          multiline
          rowsMax={4}
          label="Комментарий"
          placeholder="Комментарий"
        />
      </div>
    </Paper>
    <AnalysisFields title="Образование" name="education" />
  </div>
);

const EDUCATION = {
  id: uuidv4(),
  name: 'Образование',
  data: DATA,
};

export default EDUCATION;
