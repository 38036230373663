import { useEffect, useContext } from 'react';
import { useMutation } from 'react-apollo';
import validator from 'validator';
import { get, replace, capitalize, join } from 'lodash';
import { loader } from 'graphql.macro';

// Hooks
import { ProfileContext } from '../../../../hooks';

// Config
import { SERVER_ERRORS_HANDLER, ERROR_CONNECTION, MESSAGES_MAP } from 'config';

// Types
import { SIGN_IN_TYPE, SESSION_TYPE } from 'services/session';

// GraphQL
const pinQuery = loader('src/graphql/mutations/pin.gql');

type USE_STEP_PROPS_TYPE = {
  session?: SESSION_TYPE,
  formValues?: object,
  signIn: SIGN_IN_TYPE,
};

export const useStep = ({
  session,
  formValues,
  signIn
}: USE_STEP_PROPS_TYPE) => {
  const {
    loading,
    step,
    values,
    finished,
    limit,
    error,
    fail,
    handleValues,
    handleBack,
    handleNext,
    handleError,
    handleFail,
    handleLoading
  } = useContext(ProfileContext);

  const [
    handlePinQuery,
    pinQueryResult
  ] = useMutation(
    pinQuery,
    {
      fetchPolicy: 'no-cache',
      onError: (error: any) => SERVER_ERRORS_HANDLER(error, (error?: any) => handleFail(!!error))
    }
  );

  const { loading: pinQueryLoading, data: pinQueryData, error: pinQueryError } = pinQueryResult;

  useEffect(() => {
    handleLoading(!!pinQueryLoading);
  }, [pinQueryLoading]);

  useEffect(() => {
    if (!pinQueryData || loading) {
      return;
    }
    const isError = get(pinQueryData, 'pin.__typename') === 'Error';
    const message = get(pinQueryData, 'pin.message');

    const dataError = isError && get(MESSAGES_MAP, `${message}`, message);
    const currentErrors = [
      ...(pinQueryError ? [ERROR_CONNECTION] : []),
      ...(dataError ? [dataError] : []),
    ];
    currentErrors.length <= 0
      ? handleNext({ errors: false })
      : handleError(join(currentErrors, '\n'));
  }, [loading, pinQueryData, pinQueryError]);

  const handleSubmit = (event: React.SyntheticEvent) => {
    console.log('be submitted');
    // get(event, 'preventDefault') && event.preventDefault();
    const login = replace(
      get(formValues, 'login', get(values, 'login')),
      / /g,
      ''
    );
    const isPhone =
      login && validator.isMobilePhone(login, 'ru-RU', { strictMode: false });
    const name = capitalize(get(formValues, 'name', get(values, 'name', '')));
    const surname = capitalize(get(formValues, 'surname', get(values, 'surname', '')));
    const patronymic = capitalize(get(formValues, 'patronymic', get(values, 'patronymic', '')));
    const password = get(formValues, 'password', '');
    const confirmation = get(formValues, 'confirmation', '');

    const isNewLogin = session?.email !== login && session?.phone !== login;

    const stepValues = {
      ...(isNewLogin && {[ isPhone ? 'phone' : 'email']: login}),
    };
    handleValues({
      ...stepValues,
      name,
      surname,
      patronymic,
      password,
      confirmation,
    });
    return handlePinQuery({ variables: { ...stepValues } });
  };

  return {
    loading,
    step,
    finished,
    limit,
    error,
    fail,
    handleBack,
    handleNext,
    handleError,
    handleFail,
    handleLoading,
    handleSubmit
  };
};

export default useStep;
