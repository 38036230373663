// Libraries
import React from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { get } from 'lodash';

// Components
import Button from 'components/Button';
import BaseForm, { Input } from 'components/Form';

import Timer from '../../../../../components/Timer';

// Styles
import styles from './styles.module.scss';

// Services
import { signIn, SESSION_TYPE, SIGN_IN_TYPE, ACTION_TYPE } from 'services/session';

// Hooks
import { useStep } from './hooks';

// Data
import { asyncValidate } from './validators';

type FORM_PROPS_TYPE = {
  valid?: boolean,
  asyncValidating?: boolean | string,
  anyTouched?: boolean,
  formValues?: object,
  signIn: SIGN_IN_TYPE,
  handleSubmit: Function
};

const Form = ({
  valid,
  anyTouched,
  asyncValidating,
  formValues,
  signIn,
  handleSubmit: formSubmit
}: FORM_PROPS_TYPE): React.ReactElement<typeof BaseForm> => {
  const { error, handleSubmit, handleRefetch, attempts } = useStep({
    formValues,
    signIn,
  });
  return (
    <BaseForm
      className={styles.Form}
      onSubmit={formSubmit(handleSubmit)}
      name="profile"
      autoComplete="off"
    >
      <Input
        className={styles.Input}
        key="pin"
        label="Код"
        name="pin"
        placeholder="Код"
        error={error}
        required
        autoComplete="one-time-code"
        mask={/^[\d]+$/}
      />
      <Button
        className={styles.Button}
        icon="arrow"
        type="submit"
        disabled={!(anyTouched && !asyncValidating && valid)}
      >
        Отправить
      </Button>
      {attempts <= 0 && (
        <Timer
          onRefetch={handleRefetch}
          className={styles.Timer}
          title="Запросить код повторно"
        />
      )}
    </BaseForm>
  );
};

// Exports
const profileForm = reduxForm<object, any>({
  form: 'profile',
  destroyOnUnmount: false,
  // validate,
  asyncValidate,
  touchOnChange: true,
})(Form);

const mapStateToProps = (state: object): object => ({
  formValues: get(state, 'form.profile.values')
});

const mapDispatchToProps = (dispatch: (action: ACTION_TYPE) => any) => ({
  // Services
  signIn: (profile: SESSION_TYPE) => dispatch(signIn(profile))
});

// Exports
export default compose(connect(mapStateToProps, mapDispatchToProps))(profileForm);
