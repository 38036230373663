// Libraries
import React from 'react';
import classNames from 'classnames';

// Components
import { H1 } from 'components/Typography';

// Styles
import styles from './Title.module.scss';

type TITLE_PROPS_TYPE = {
  className?: string,
  classes?: {
    root?: string
  },
};

const Title = ({
  className,
  classes
}: TITLE_PROPS_TYPE): React.ReactElement<typeof H1> => {
  return (
    <H1
      className={classNames(
        className,
        classes?.root,
        styles.Root,
      )}
    >
      Выход
    </H1>
  );
};

// Exports
export default Title;
