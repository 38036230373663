// Libraries
import React, { Fragment } from 'react';
import { v4 as uuidv4 } from 'uuid';

// Components
import {Caption, H1, H5, Subtitle} from "components/Typography";
import { Paper } from '@material-ui/core';
import {Input, Select} from "components/Form";
import AnalysisFields from "../../AnalysisFields/AnalysisFields";

// Styles
import styles from '../styles.module.scss';

type EMOTIONAL_PROPS_TYPE = {
  error: any,
};

const DATA = ({ error }: EMOTIONAL_PROPS_TYPE): React.ReactElement<typeof Fragment> => (
  <div className={styles.FormSegment}>
    <H1
      className={styles.Title}
    >
      Эмоциональное развитие
    </H1>
    <Caption>
      (включая теплые эмоциональные отношения)
    </Caption>
    <H5
      className={styles.H5}
    >
      Потребности ребенка
    </H5>
    <Paper className={styles.Paper}>
      <div className={styles.QuestionContainer}>
        <Subtitle>Эмоциональные реакции ребенка в пределах возрастной нормы?</Subtitle>
        <Select
          className={styles.QuestionContainerSelect}
          name="emotional_reactions"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="emotional_reactions_description"
          multiline
          rowsMax={4}
          label="Если нет, укажите подробности"
          placeholder="Если нет, укажите подробности"
        />
      </div>
      <div className={styles.QuestionContainer}>
        <Subtitle>Замыкается ли ребенок в себе?</Subtitle>
        <Select
          className={styles.QuestionContainerSelect}
          name="closure"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="closure_description"
          multiline
          rowsMax={4}
          label="Если да: как часто, в каких ситуациях?"
          placeholder="Если да: как часто, в каких ситуациях?"
        />
      </div>
      <div className={styles.QuestionContainer}>
        <Subtitle>Справляется ли ребенок с неудачами и гневом, как? Бывают ли у ребенка сильные вспышки гнева,
          выходящие за рамки нормального поведения? </Subtitle>
        <Select
          className={styles.QuestionContainerSelect}
          name="anger"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="anger_description"
          multiline
          rowsMax={4}
          label="Если да: как часто, в каких ситуациях?"
          placeholder="Если да: как часто, в каких ситуациях?"
        />
      </div>
      <div className={styles.QuestionContainer}>
        <Subtitle>Имеет ли место повышенный интерес к теме насилия и жестокости?</Subtitle>
        <Select
          className={styles.QuestionContainerSelect}
          name="interest_violence"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="interest_violence_description"
          multiline
          rowsMax={4}
          label="Комментарий"
          placeholder="Комментарий"
        />
      </div>
      <div className={styles.QuestionContainer}>
        <Subtitle>Наносит ли ребенок себе повреждения / увечья?</Subtitle>
        <Select
          className={styles.QuestionContainerSelect}
          name="damage"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="damage_description"
          multiline
          rowsMax={4}
          label="Комментарий"
          placeholder="Комментарий"
        />
      </div>
      <div className={styles.QuestionContainer}>
        <Subtitle>Требуется ли ребенку подробное психологическое обследование?</Subtitle>
        <Select
          className={styles.QuestionContainerSelect}
          name="psychological_examination"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="psychological_examination_description"
          multiline
          rowsMax={4}
          label="Комментарий"
          placeholder="Комментарий"
        />
      </div>
    </Paper>
    <H5
      className={styles.H5}
    >
      Возможности родителей/лиц, их заменяющих
    </H5>
    <Paper className={styles.Paper}>
      <div className={styles.QuestionContainer}>
        <Subtitle>Был ли ребенок желанным?</Subtitle>
        <Select
          className={styles.QuestionContainerSelect}
          name="welcome_child"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="welcome_child_description"
          multiline
          rowsMax={4}
          label="Комментарий"
          placeholder="Комментарий"
        />
      </div>
      <div className={styles.QuestionContainer}>
        <Subtitle>Знают ли родители об эмоциональных потребностях ребенка?</Subtitle>
        <Select
          className={styles.QuestionContainerSelect}
          name="emotional_needs"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="emotional_needs_description"
          multiline
          rowsMax={4}
          label="Комментарий"
          placeholder="Комментарий"
        />
      </div>
      <div className={styles.QuestionContainer}>
        <Subtitle>Предоставляют ли родители эмоциональную поддержку ребенку в любых жизненных ситуациях?
          Критикуют ли ребенка или проявляют по отношению к нему враждебность?
        </Subtitle>
        <Select
          className={styles.QuestionContainerSelect}
          name="emotional_support"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="emotional_support_description"
          multiline
          rowsMax={4}
          label="Комментарий"
          placeholder="Комментарий"
        />
      </div>
      <div className={styles.QuestionContainer}>
        <Subtitle>Стабильно ли психоэмоциональное состояние родителей?</Subtitle>
        <Select
          className={styles.QuestionContainerSelect}
          name="psycho_emotional_parents"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="psycho_emotional_parents_description"
          multiline
          rowsMax={4}
          label="Комментарий"
          placeholder="Комментарий"
        />
      </div>
      <div className={styles.QuestionContainer}>
        <Subtitle>Решаются ли семейные проблемы без насилия?</Subtitle>
        <Select
          className={styles.QuestionContainerSelect}
          name="family_problems"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="family_problems_description"
          multiline
          rowsMax={4}
          label="Комментарий"
          placeholder="Комментарий"
        />
      </div>
    </Paper>
    <AnalysisFields title="Эмоциональное развитие" name="emotional_development" />
  </div>
);

const EMOTIONAL = {
  id: uuidv4(),
  name: 'Эмоциональное развитие',
  data: DATA,
};

export default EMOTIONAL;
