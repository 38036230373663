// Libraries
import React from 'react';
import classNames from 'classnames';
import { Link as RouterLink } from 'react-router-dom';
import BaseLink from '@material-ui/core/Link';

// Styles
import styles from './Link.module.scss';

// Helpers
import { isExternal } from 'helpers/url';

export type LINK_PROPS_TYPES = {
  children?: React.ReactNode,
  className?: string,
  classes?: {
    root?: string,
    icon?: string
  },
  href?: string | undefined,
  onClick?: ((event: React.MouseEvent) => void)
};

const Link = React.forwardRef(({
  children,
  className,
  classes = {},
  href = undefined,
  onClick
}: LINK_PROPS_TYPES,
  ref: React.ForwardedRef<HTMLAnchorElement>
): React.ReactElement<HTMLAnchorElement> => {
  const externalProps = {
    ref,
    className: classNames(className, classes.root, styles.Root),
    onClick
  };

  return (
    !href || isExternal(href) ? (
      <a
        {...externalProps}
        href={href}
        rel="noopener noreferrer"
        target="_blank"
      >
        {children}
      </a>
    ) : (
      <BaseLink
        {...externalProps}
        to={href}
        component={RouterLink}
      >
        {children}
      </BaseLink>
    )
  );
});

export default Link;
