// Libraries
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { get, toLower } from 'lodash';
import classNames from 'classnames';
import { useParams } from "react-router-dom";

// Components
import Page from 'components/Page';
import Title from './components/Title';

import Wizard from './components/Wizard';

// Styles
import styles from './EFO.module.scss';

// Services
import { SESSION_TYPE, ACTION_TYPE } from 'services/session';

// Hooks
import { useEFO, EFOContext } from './hooks';

type EFO_PROPS_TYPE = {
  session?: SESSION_TYPE,
  match: {
    url: string,
    path: string,
  }
};

const EFO = ({
  session,
  match,
}: EFO_PROPS_TYPE): React.ReactElement<typeof Page> => {
  const { id }: { id: string } = useParams();
  const isNew = toLower(id) === 'new';
  const usedEFO = useEFO({ session, isNew, id });
  const { loading } = usedEFO;

  return (
    <Page
      classes={{
        root: classNames(styles.Root)
      }}
      loading={loading}
    >
      <div className={styles.Wrapper}>
        <EFOContext.Provider value={usedEFO}>
          <Title className={styles.Title} />
          <div className={styles.Actions}>
            <Wizard />
          </div>
        </EFOContext.Provider>
      </div>
    </Page>
  );
};

// Exports
const mapStateToProps = (state: object): object => ({
  session: get(state, 'session')
});

const mapDispatchToProps = (dispatch: (action: ACTION_TYPE) => any) => ({
  // Services
});

// Exports
export default compose<EFO_PROPS_TYPE, any>(connect(mapStateToProps, mapDispatchToProps))(EFO);
