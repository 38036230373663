// Libraries
import React from 'react';
import classNames from 'classnames';

// Components
import Button from 'components/Button';

// Styles
import styles from './Fail.module.scss';

const Fail = (): React.ReactElement<typeof Button> => (
  <Button
    className={classNames(
      styles.Root,
      styles.Button,
    )}
    href="/auth/sign-up"
    icon="arrow"
  >
    Регистрация
  </Button>
);

// Exports
export default Fail;
