// Libraries
import React, { useState, useEffect } from 'react';
import { get, isFunction, toString } from 'lodash';
import moment from 'moment';

// Components
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Button from 'components/Button';

// Styles
import styles from './AddDialog.module.scss';

export interface ADD_DIALOG_PROPS_TYPES {
  addDataHandler?: Function,
  fields?: any,
  data?: object,
  open: boolean,
  onClose?: Function,
  onChange?: Function,
};

const AddDialog = ({
  addDataHandler,
  fields = [],
  data,
  open = false,
  onClose,
  onChange
}: ADD_DIALOG_PROPS_TYPES): React.ReactElement<typeof Dialog> => {
  const [ediatableData, setEdiatableData] = useState<object | undefined>(data);

  useEffect(() => {
    setEdiatableData(data);
  }, [data]);

  const handleClose = () => {
    isFunction(onClose) && onClose();
  };

  const handleAdd = (event: React.MouseEvent) => {
    isFunction(onChange) && onChange(ediatableData);
    setEdiatableData({});
    handleClose();
  };

  const handleChange = (name: string) => (props: object) => {
    // const value = get(props, 'target.value') || moment(props, 'DD.MM.YYYY').format('DD.MM.YYYY');
    const value = get(props, 'target.value') || moment(props, 'DD.MM.YYYY').format('DD.MM.YYYY');
    setEdiatableData({ ...ediatableData, [name]: toString(value) });
  };

  return (
    <Dialog
      className={styles.Root}
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{data ? 'Редактировать' : 'Добавить'}</DialogTitle>
      <DialogContent className={styles.Content}>
        <DialogContentText>Заполните необходимые поля</DialogContentText>
        {
          fields.map((item: object, index: number) => {
            const Component = get(item, 'component');
            if (!Component) { return ''; }
            const name = get(item, 'accessor');
            const input = get(item, 'input');
            return <Component
              key={index}
              name={name}
              label={get(item, 'Header')}
              placeholder={get(item, 'Header')}
              input={{
                value: get(ediatableData, `[${name}]`),
                onChange: handleChange(name)
              }}
              {...input}
              // value={ediatableData[name]}
            />;
          })
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>
          Отмена
        </Button>
        <Button onClick={handleAdd}>
          Сохранить
        </Button>
      </DialogActions>
    </Dialog>
  )
};

export default AddDialog;
