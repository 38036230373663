// Libraries
import * as yup from 'yup';
import { get } from 'lodash';

import { asyncValidator } from 'components/Form';

// Validations
import {
  VALIDATE_LOGIN,
  VALIDATE_ADULT,
  VALIDATE_DATE_FORMAT,
  VALIDATE_PHONE
} from 'helpers/validations';

// Config
import {
  ERROR_FIELD_REQUIRED,
  ERROR_PASSWORD_MIN,
  ERROR_PASSWORD_EQUAL
} from 'config';

export const validate = (values: object) => {
  const login = VALIDATE_LOGIN(get(values, 'login', ''));
  const birthday = get(values, 'birthday', '');
  const additional_phone = VALIDATE_PHONE(get(values, 'additional_phone', ''));
  return {
    ...(login && { login }),
    ...({ birthday: VALIDATE_DATE_FORMAT(birthday) || VALIDATE_ADULT(birthday) }),
    ...(additional_phone && { additional_phone }),
  };
};

export const asyncValidate = asyncValidator(
  yup.object().shape({
    name: yup.string().required(ERROR_FIELD_REQUIRED),
    surname: yup.string().required(ERROR_FIELD_REQUIRED),
    patronymic: yup.string().required(ERROR_FIELD_REQUIRED),
    birthday: yup.mixed().required(ERROR_FIELD_REQUIRED),
    gender: yup.string().required(ERROR_FIELD_REQUIRED),
    login: yup.string().required(ERROR_FIELD_REQUIRED),
    password: yup
      .string()
      .min(3, ERROR_PASSWORD_MIN),
    // .max(32)
    confirmation: yup
      .string()
      .min(3, ERROR_PASSWORD_MIN)
      .oneOf([yup.ref('password'), null], ERROR_PASSWORD_EQUAL),
    // .max(32)
  })
);
