// Libraries
import React from 'react';
import { useLazyQuery, useMutation } from 'react-apollo';
import { get, join } from 'lodash';
import { useHistory } from "react-router-dom";
import { loader } from 'graphql.macro';

// Config
import {
  ERROR_CONNECTION,
  MESSAGES_MAP
} from 'config';

// Types
import { SESSION_TYPE } from 'services/session';

// Data
import { COLUMNS_VARIANTS, GET_ACTIONS_CELL, PATHS_MAP } from './data';

// GraphQL
const usersQuery = loader('src/graphql/queries/users.gql');
const profileMutation = loader('src/graphql/mutations/profile.gql');
const deleteProfileMutation = loader('src/graphql/mutations/deleteProfile.gql');

export type LIST_PROPS_TYPE = {
  session?: SESSION_TYPE,
  url: string
};

export const useList = ({
  session,
  url
}: LIST_PROPS_TYPE) => {
  const history = useHistory();
  const { role, title } = get(PATHS_MAP, `['${url}']`, PATHS_MAP['/users']);
  const [variant, setVariant] = React.useState(role);
  const [data, setData] = React.useState([]);

  const columns = React.useMemo(
    () => get(COLUMNS_VARIANTS, `[${variant}]`, []),
    [variant]
  );

  const [error, setError] = React.useState<string | boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);

  const [
    handleProfileMutation,
    profileMutationResult
  ] = useMutation(
    profileMutation,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const [
    handleDeleteProfileMutation,
    deleteProfileMutationResult
  ] = useMutation(
    deleteProfileMutation,
    {
      fetchPolicy: 'no-cache',
    }
  );


  const { loading: profileMutationLoading, data: profileMutationData, error: profileMutationError } = profileMutationResult;

  const [handleUsersQuery, { loading: usersQueryLoading, data: usersQueryData, error: usersQueryError }] = useLazyQuery(usersQuery, {
    fetchPolicy: 'no-cache'
  });

  const token = get(session, 'token');

  const loadUsers = () => {
    handleUsersQuery({
      variables: {
        ...(variant !== 'user' && {
            hasRoles: {
              column: 'NAME',
              value: variant
            }
          }
        )
      }
    });
  };

  React.useEffect(() => {
    setVariant(role);
  }, [role]);

  React.useEffect(() => {
    setLoading(!!profileMutationLoading || !!usersQueryLoading);
  }, [profileMutationLoading, usersQueryLoading]);

  React.useEffect((): any => {
    !!token && loadUsers();
  },[token, variant]);

  React.useEffect((): any => {
    if (!usersQueryData || usersQueryLoading) {
      return;
    }
    const isError = get(usersQueryData, 'users.__typename') === 'Error';
    const message = get(usersQueryData, 'users.message');
    const normalizedData = get(usersQueryData, 'users.data', []).map((user: object) => ({
      ...user,
      role: get(user, 'roles[0].name', 'client'),
      actions: GET_ACTIONS_CELL({
        ...user,
        onEdit: () => history.push(`${url}/${get(user, 'id')}`),
        onBlock: () => {
          handleProfileMutation({
            variables: {
              id: get(user, 'id'),
              blocked: !get(user, 'blocked', false)
            }
          });
        },
        onDelete: () => {
          handleDeleteProfileMutation({
            variables: {
              id: get(user, 'id')
            }
          })
            .then(() => loadUsers());
        }
      })
    }));

    const dataError = isError && get(MESSAGES_MAP, `${message}`, message);
    const currentErrors = [
      ...(usersQueryError ? [ERROR_CONNECTION] : []),
      ...(dataError ? [dataError] : []),
    ];
    currentErrors.length > 0 ? setError(join(currentErrors, '\n')) : setData(normalizedData);
  }, [usersQueryData]);

  React.useEffect(() => {
    if (!profileMutationData || profileMutationLoading) {
      return;
    }
    const isError = get(profileMutationData, 'profile.__typename') === 'Error';
    const message = get(profileMutationData, 'profile.message');

    const dataError = isError && get(MESSAGES_MAP, `${message}`, message);
    const currentErrors = [
      ...(profileMutationError ? [ERROR_CONNECTION] : []),
      ...(dataError ? [dataError] : []),
    ];
    currentErrors.length <= 0
      ? loadUsers()
      : setError(join(currentErrors, '\n'));
  }, [profileMutationData]);

  return {
    loading,
    error,
    data,
    columns,
    variant,
    title
  };
};

export default useList;
