// Libraries
import React from 'react';

type USE_TABS_PROPS_TYPE = {
  data?: any,
};

export const useTabs = ({ data }: USE_TABS_PROPS_TYPE) => {

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return {
    value,
    handleChange
  };
};

export default useTabs;
