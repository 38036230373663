// Libraries
import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { get } from 'lodash';

// Services
import { signOut, ACTION_TYPE } from 'services/session';

// Components
import Page from 'components/Page';

import Title from './components/Title';
import Informer from './components/Informer';

// Styles
import styles from './SignOut.module.scss';

// Hooks
import { useSignOut, SignOutContext } from './hooks';

type SIGN_OUT_PROPS_TYPE = {
  token?: string,
  signOut: () => ACTION_TYPE,
};

const SignOut = ({
  token,
  signOut,
}: SIGN_OUT_PROPS_TYPE): React.ReactElement<typeof Redirect | typeof Page> => {
  const usedSignOut = useSignOut({token, signOut});
  const { loading } = usedSignOut;
  return (
    <Page
      classes={{ root: styles.Root }}
      loading={loading}
    >
      <div className={styles.Wrapper}>
        <SignOutContext.Provider value={usedSignOut}>
          <Title className={styles.Title}/>
          <div className={styles.Actions}>
            <Informer className={styles.Informer} />
          </div>
        </SignOutContext.Provider>
      </div>
    </Page>
  );
};

const mapStateToProps = (state: object): object => ({
  token: get(state, 'session.token')
});

const mapDispatchToProps = (dispatch: (action: ACTION_TYPE) => any) => ({
  // Services
  signOut: () => dispatch(signOut())
});

// Exports
export default compose<SIGN_OUT_PROPS_TYPE, any>(connect(mapStateToProps, mapDispatchToProps))(SignOut);
