// Libraries
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Route, Switch } from 'react-router-dom';
import { get } from 'lodash';

// Components
import List from './List';
import PSS from './PSS/Form';

// Services
import { ACTION_TYPE } from 'services/session';

// Views
import Errors from 'views/Errors';

type PSSS_PROPS_TYPE = {
  role?: string,
  match: {
    url: string
  }
}

const PSSs = ({ role, match }: PSSS_PROPS_TYPE): React.ReactElement<typeof Switch> => (
  <Switch>
    <Route exact component={PSS} path="/questionnaires/pss/:id" />
    <Route exact component={List} path="/questionnaires/pss" />
    <Route component={Errors} path={match.url} />
  </Switch>
);

const mapStateToProps = (state: object): object => ({
  role: get(state, 'session.roles[0].name')
});

const mapDispatchToProps = (dispatch: (action: ACTION_TYPE) => any) => ({
  // Services
});

// Exports
export default compose<PSSS_PROPS_TYPE, any>(connect(mapStateToProps, mapDispatchToProps))(PSSs);
