// Libraries
import React, { Fragment } from 'react';
import { v4 as uuidv4 } from 'uuid';
import classNames from "classnames";

// Components
import {H1, H5, Subtitle} from "components/Typography";
import {Input, Select} from "components/Form";
import {Paper} from "@material-ui/core";
import AnalysisFields from "../../AnalysisFields/AnalysisFields";

// Styles
import styles from '../styles.module.scss';

type HEALTH_PROPS_TYPE = {
  error: any,
};

const DATA = ({ error }: HEALTH_PROPS_TYPE): React.ReactElement<typeof Fragment> => (
  <div className={styles.FormSegment}>
    <H1 className={styles.Title}>Здоровье и безопасность</H1>
    <H5>Потребности ребёнка</H5>
    <Paper className={styles.Paper}>
      <div className={styles.QuestionContainer}>
        <Subtitle>Соответствует ли физическое развитие ребенка его возрасту? (рост, вес, двигательное развитие и др.)</Subtitle>
        <Select
          className={styles.QuestionContainerSelect}
          name="physical_development"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="physical_development_description"
          multiline
          rowsMax={4}
          label="Комментарий"
          placeholder="Комментарий"
        />
      </div>
      <div className={styles.QuestionContainer}>
        <Subtitle>Есть ли у ребенка проблемы со здоровьем? (нарушения слуха, зрения, физические, психические нарушения)</Subtitle>
        <Select
          className={styles.QuestionContainerSelect}
          name="problem_with_health"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="problem_with_health_description"
          multiline
          rowsMax={4}
          label="Комментарий"
          placeholder="Комментарий"
        />
      </div>
      <div className={styles.QuestionContainer}>
        <Subtitle>Состоит ли ребенок на учете у каких-либо специалистов?</Subtitle>
        <Select
          className={styles.QuestionContainerSelect}
          name="accounting_specialists"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="accounting_specialists_description"
          multiline
          rowsMax={4}
          label="Комментарий"
          placeholder="Комментарий"
        />
      </div>
      <div className={styles.QuestionContainer}>
        <Subtitle>Требуется ли ребенку медицинское обследование?</Subtitle>
        <Select
          className={styles.QuestionContainerSelect}
          name="medical_exam"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="accounting_specialists_description"
          multiline
          rowsMax={4}
          label="Если да, уточните причину"
          placeholder="Если да, уточните причину"
        />
      </div>
      <div className={styles.QuestionContainer}>
        <Subtitle>Есть ли признаки жестокого обращения по отношению к ребенку? (физического, эмоционального, сексуального насилия, пренебрежения нуждами).</Subtitle>
        <Select
          className={styles.QuestionContainerSelect}
          name="abuse"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="abuse_description"
          multiline
          rowsMax={4}
          label="Если да, укажите что именно"
          placeholder="Если да, укажите что именно"
        />
      </div>
      <div className={styles.QuestionContainer}>
        <Subtitle>Если да, необходимо ли принять какие-то срочные меры для защиты ребенка и сохранения его здоровья?</Subtitle>
        <Select
          className={styles.QuestionContainerSelect}
          name="safety"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="safety_description"
          multiline
          rowsMax={4}
          label="Укажите какие меры следует предпринять"
          placeholder="Укажите какие меры следует предпринять"
        />
      </div>
      <div className={styles.QuestionContainer}>
        <Subtitle>Представляет ли состояние жилья и ближайшее окружение угрозу для здоровья ребенка?</Subtitle>
        <Select
          className={styles.QuestionContainerSelect}
          name="threat"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="threat_description"
          multiline
          rowsMax={4}
          label="Комментарий"
          placeholder="Комментарий"
        />
      </div>
    </Paper>
    <H5>Возможности родителей / лиц, их заменяющих</H5>
    <Paper className={styles.Paper}>
      <div className={styles.QuestionContainer}>
        <Subtitle>Создаются ли условия для здорового развития ребенка?</Subtitle>
        <Select
          className={styles.QuestionContainerSelect}
          name="living"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="living_description"
          multiline
          rowsMax={4}
          label="Комментарий"
          placeholder="Комментарий"
        />
      </div>
      <div className={styles.QuestionContainer}>
        <Subtitle>Могут ли родители* сообщить детали медицинской истории биологической семьи?</Subtitle>
        <Select
          className={styles.QuestionContainerSelect}
          name="details_medical_history"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="details_medical_history_description"
          multiline
          rowsMax={4}
          label="Комментарий"
          placeholder="Комментарий"
        />
      </div>
      <div className={styles.QuestionContainer}>
        <Subtitle>Есть ли медицинская страховка (полис) у семьи/ребенка?</Subtitle>
        <Select
          className={styles.QuestionContainerSelect}
          name="medical_insurance"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="medical_insurance_description"
          multiline
          rowsMax={4}
          label="Комментарий"
          placeholder="Комментарий"
        />
      </div>
      <div className={styles.QuestionContainer}>
        <Subtitle>Проходит ли ребенок необходимые медицинские осмотры, сделаны ли все необходимые прививки?</Subtitle>
        <Select
          className={styles.QuestionContainerSelect}
          name="medical_examinations"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="medical_examinations_description"
          multiline
          rowsMax={4}
          label="Комментарий"
          placeholder="Комментарий"
        />
      </div>
      <Subtitle className={classNames(styles.Subtitle, styles.SubtitleSmall)}>* Здесь и далее: под «родителем», «родителями» имеются в виду биологические родители ребенка,
        либо иные лица, осуществляющие уход за ребенком, в случае, если ребенок
        признан оставшимся без попечения родителей.</Subtitle>
      <div className={styles.QuestionContainer}>
        <Subtitle>В случае необходимости, оформлена ли у ребенка инвалидность и индивидуальная программа реабилитации?</Subtitle>
        <Select
          className={styles.QuestionContainerSelect}
          name="disability"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="disability_description"
          multiline
          rowsMax={4}
          label="Если не требуется, отметьте «не применимо»"
          placeholder="Если не требуется, отметьте «не применимо»"
        />
      </div>
      <div className={styles.QuestionContainer}>
        <Subtitle>Могут ли родители должным образом реагировать на экстренную мед. ситуацию?</Subtitle>
        <Select
          className={styles.QuestionContainerSelect}
          name="emergency_honey"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="emergency_honey_description"
          multiline
          rowsMax={4}
          label="Комментарий"
          placeholder="Комментарий"
        />
      </div>
      <div className={styles.QuestionContainer}>
        <Subtitle>Оставляли или оставляют ли ребенка дома без присмотра или с чужими людьми?</Subtitle>
        <Select
          className={styles.QuestionContainerSelect}
          name="alien_people"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="alien_people_description"
          multiline
          rowsMax={4}
          label="Если да, укажите детали: как часто, на какое время, как обеспечивается безопасность ребенка на это время, и пр."
          placeholder="Если да, укажите детали: как часто, на какое время, как обеспечивается безопасность ребенка на это время, и пр."
        />
      </div>
      <div className={styles.QuestionContainer}>
        <Subtitle>Есть ли у членов семьи проблемы с физическим и умственным здоровьем, поведением, употреблением алкоголя или наркотиков?</Subtitle>
        <Select
          className={styles.QuestionContainerSelect}
          name="problem_with_health"
        />
        <Input
          className={styles.QuestionContainerInput}
          name="problem_with_health_description"
          multiline
          rowsMax={4}
          label="Комментарий"
          placeholder="Комментарий"
        />
      </div>
    </Paper>
    <AnalysisFields title="Здоровье и безопасность" name="health" />
  </div>
);

const HEALTH = {
  id: uuidv4(),
  name: 'Здоровье',
  data: DATA,
};

export default HEALTH;
