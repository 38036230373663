// Libraries
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { get } from 'lodash';

// Components
import Page from 'components/Page';

import Title from './components/Title';
import Informer from './components/Informer';
import Wizard from './components/Wizard';
import Additional from './components/Additional';

// Services
import { ACTION_TYPE } from 'services/session';

// Styles
import styles from './Reset.module.scss';

// Hooks
import { useReset, ResetContext } from './hooks';

type RESET_PROPS_TYPE = {
  token?: string,
};

const Reset = ({
  token
}: RESET_PROPS_TYPE): React.ReactElement<typeof Page> => {
  const usedReset = useReset({
    token,
    limit: 2
  });
  const { loading } = usedReset;

  return (
    <Page
      classes={{ root: styles.Root }}
      loading={loading}
    >
      <div className={styles.Wrapper}>
        <ResetContext.Provider value={usedReset}>
          <Title className={styles.Title} />
          <div className={styles.Actions}>
            <Informer className={styles.Informer} />
            <Wizard />
          </div>
          <Additional className={styles.Additional} />
        </ResetContext.Provider>
      </div>
    </Page>
  );
};

const mapStateToProps = (state: object): object => ({
  token: get(state, 'session.token')
});

const mapDispatchToProps = (dispatch: (action: ACTION_TYPE) => any) => ({
  // Services
});

// Exports
export default compose<RESET_PROPS_TYPE, any>(connect(mapStateToProps, mapDispatchToProps))(Reset);
