// Libraries
import validator from 'validator';
import { replace } from 'lodash';
import moment from 'moment';

// Config
import {
  ERROR_FILE_MAX_SIZE,
  ERROR_FILE_INCORRECT,
  ERROR_EMAIL_FORMAT,
  ERROR_PHONE_FORMAT,
  ERROR_LOGIN_FORMAT,
  ERROR_DATE_ADULT,
  ERROR_DATE_FORMAT, ERROR_PASSWORD_EQUAL, ERROR_PASSWORD_MIN,
} from 'config';

// todo yup throw error Asynchronous validation promise was rejected without errors test joi
// yup.addMethod(yup.mixed, 'login', function (message = 'Номер телефона или e-mail введен некорректно') {
//   // eslint-disable-next-line
//   return this.test('login', message, function(value) {
//     const isPhone = validator.isMobilePhone(value);
//     const isEmail = validator.isEmail(value);
//     return isPhone || isEmail;
//     // eslint-disable-next-line
//   });
// });

export const VALIDATE_FILE_SIZE = (value: number): string | void => {
  if (value > 5242880) {
    return ERROR_FILE_MAX_SIZE;
  }
  if (value <= 0) {
    return ERROR_FILE_INCORRECT;
  }
};

export const VALIDATE_PHONE = (value: string): string | void => {
  const formatted = replace(value, / /g, '');
  const isStrictedPhone = validator.isMobilePhone(formatted, 'ru-RU', {
    strictMode: false
  });
  if (!!formatted && !isStrictedPhone) {
    return ERROR_PHONE_FORMAT;
  }
};

export const VALIDATE_LOGIN = (value: string): string | void => {
  const formatted = replace(value, / /g, '');
  const isPhone = validator.isMobilePhone(formatted);
  const isStrictedPhone = validator.isMobilePhone(formatted, 'ru-RU', {
    strictMode: false
  });
  const isEmail = validator.isEmail(value);
  if (!isPhone && !isEmail) {
    return parseInt(value, 10) > 0 ? ERROR_LOGIN_FORMAT : ERROR_EMAIL_FORMAT;
  }
  if (isPhone && !isStrictedPhone) {
    return ERROR_PHONE_FORMAT;
  }
};

export const VALIDATE_PASSWORDS = (values: any) => {
  const {
    password,
    confirmation
  } = {
    password: replace(values.password, / /g, ''),
    confirmation: replace(values.confirmation, / /g, ''),
  };

  const isEqual = password === confirmation;
  const isPasswordsMin = password.length > 3 && confirmation.length > 3;

  if (!isEqual) {
    return ERROR_PASSWORD_EQUAL
  }

  if (!isPasswordsMin) {
    return ERROR_PASSWORD_MIN
  }
};

export const VALIDATE_ADULT = (value: string): string | void => {
  const ISO = moment(value, 'YYYY-MM-DD');
  const RU = moment(value, 'DD.MM.YYYY');

  const usedDate = ISO.isValid() ? ISO : RU;

  if (usedDate.valueOf() < 568080000000) {
    return ERROR_DATE_ADULT;
  }
};

export const VALIDATE_DATE_FORMAT = (value: string | Date): string | void => {
  const ISO = moment(value, 'YYYY-MM-DD');
  const RU = moment(value, 'DD.MM.YYYY');

  if (!value || (!ISO.isValid() && !RU.isValid())) {
    return ERROR_DATE_FORMAT;
  }
};