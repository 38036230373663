// Libraries
import React from 'react';

// Components
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import { Row } from 'react-table';

// Styles
import styles from './GlobalFilter.module.scss';

export type GLOBAL_FILTER_PROPS_TYPES = {
  numSelected?: number,
  preGlobalFilteredRows: Row<object>[],
  setGlobalFilter: Function,
  globalFilter: string,
  onChangePage?: (event: React.MouseEvent, page: number) => void
};

const GlobalFilter = ({
  globalFilter,
  setGlobalFilter,
}: GLOBAL_FILTER_PROPS_TYPES) => {
  return (
    <div className={styles.Root}>
      <SearchIcon />
      <InputBase
        value={globalFilter || ''}
        onChange={e => {
          setGlobalFilter(e.target.value || undefined)
        }}
        placeholder='Поиск...'
        className={styles.Input}
      />
    </div>
  );
};

export default GlobalFilter;
