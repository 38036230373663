// Libraries
import React from 'react';
import { v4 as uuidv4 } from 'uuid';

// Components
import Paper from '@material-ui/core/Paper';

import { Input as BaseInput } from 'components/Form/components/Input';
import { DatePicker as BaseDatePicker } from 'components/Form/components/DatePicker';
import { DatePicker, Input, Select, Radio, Table } from 'components/Form';
import { H5, Subtitle, Caption } from 'components/Typography';

// Styles
import styles from '../styles.module.scss';

// Data
import { GENDERS } from 'services/options/initial';

type MAIN_PROPS_TYPE = {
  error: any,
  formValues: any
};

const DATA = ({ formValues, error }: MAIN_PROPS_TYPE): React.ReactElement<'div'> => (
  <div className={styles.FormSegment}>
    <Input
      className={styles.Input}
      key="organisation_name"
      label="Название организации"
      name="organisation_name"
      placeholder="Название организации"
      fullWidth
      required
      error={error}
    />
    <DatePicker
      className={styles.Input}
      key="start_evaluation"
      label="Дата начала оценки"
      name="start_evaluation"
      placeholder="Дата начала оценки"
      required
      error={error}
    />
    <DatePicker
      className={styles.Input}
      key="end_evaluation"
      label="Дата окончания оценки"
      name="end_evaluation"
      placeholder="Дата окончания оценки"
      required
      error={error}
    />
    <Select
      className={styles.Input}
      key="specialists"
      label="Специалисты, заполняющие ЕФО"
      name="specialists"
      placeholder="Специалисты, заполняющие ЕФО"
      required
      src="specialists"
      multiple
      limitTags={2}
      error={error}
    />
    <Select
      className={styles.Input}
      key="client"
      label="Клиент"
      name="client"
      placeholder="Клиент"
      required
      src="clients"
      error={error}
    />
    <H5
      className={styles.H5}
    >
      Информация о ребенке
    </H5>
    <Paper
      className={styles.Paper}
      variant="outlined"
    >
      <Input
        className={styles.Input}
        key="client.surname"
        label="Фамилия"
        name="client.surname"
        placeholder="Ф.И.О"
        disabled
      />
      <Input
        className={styles.Input}
        key="client.name"
        label="Имя"
        name="client.name"
        placeholder="Ф.И.О"
        disabled
      />
      <Input
        className={styles.Input}
        key="client.patronymic"
        label="Отчество"
        name="client.patronymic"
        placeholder="Ф.И.О"
        disabled
      />
      <Radio 
        name="client.gender"
        label="Пол"
        options={GENDERS}
        disabled
      />
      <DatePicker
        className={styles.Input}
        key="client.birthday"
        label="Дата рождения"
        name="client.birthday"
        placeholder="Дата рождения"
        disabled
      />
      <Input
        className={styles.Input}
        key="client.address"
        label="Адрес фактического проживания"
        name="client.address"
        placeholder="Адрес фактического проживания"
        disabled
      />
      <Input
        className={styles.Input}
        key="client.phone"
        label="Телефон"
        name="client.phone"
        placeholder="Телефон"
        disabled
      />
      <Input
        className={styles.Input}
        key="client.registration_address"
        label="Адрес регистрации"
        name="client.registration_address"
        placeholder="Адрес регистрации"
        disabled
      />
    </Paper>
    <H5
      className={styles.H5}
    >
      Информация о родителях
    </H5>
    <Paper
      className={styles.Paper}
      variant="outlined"
    >
      <Subtitle
        className={styles.Subtitle}
      >
        Родитель №1
      </Subtitle>
      <Input
        className={styles.Input}
        key="first_parent_fullname"
        label="Ф.И.О"
        name="first_parent_fullname"
        placeholder="Ф.И.О"
        error={error}
      />
      <DatePicker
        className={styles.Input}
        key="first_parent_birthday"
        label="Дата рождения"
        name="first_parent_birthday"
        placeholder="Дата рождения"
        error={error}
      />
      <Input
        className={styles.Input}
        key="first_parent_address"
        label="Адрес фактического проживания"
        name="first_parent_address"
        placeholder="Адрес фактического проживания"
        error={error}
      />
      <Input
        className={styles.Input}
        key="first_parent_phone"
        label="Телефон"
        name="first_parent_phone"
        placeholder="Телефон"
        error={error}
      />
      <Input
        className={styles.Input}
        key="first_parent_registration_address"
        label="Адрес регистрации"
        name="first_parent_registration_address"
        placeholder="Адрес регистрации"
        error={error}
      />
    </Paper>
    <Paper
      className={styles.Paper}
      variant="outlined"
    >
      <Subtitle
        className={styles.Subtitle}
      >
        Родитель №2
      </Subtitle>
      <Input
        className={styles.Input}
        key="second_parent_fullname"
        label="Ф.И.О"
        name="second_parent_fullname"
        placeholder="Ф.И.О"
        error={error}
      />
      <DatePicker
        className={styles.Input}
        key="second_parent_birthday"
        label="Дата рождения"
        name="second_parent_birthday"
        placeholder="Дата рождения"
        error={error}
      />
      <Input
        className={styles.Input}
        key="second_parent_address"
        label="Адрес фактического проживания"
        name="second_parent_address"
        placeholder="Адрес фактического проживания"
        error={error}
      />
      <Input
        className={styles.Input}
        key="second_parent_phone"
        label="Телефон"
        name="second_parent_phone"
        placeholder="Телефон"
        error={error}
      />
      <Input
        className={styles.Input}
        key="second_parent_registration_address"
        label="Адрес регистрации"
        name="second_parent_registration_address"
        placeholder="Адрес регистрации"
        error={error}
      />
    </Paper>
    <H5
      className={styles.H5}
    >
      Информация о законных представителях ребенка
    </H5>
    <Paper
      className={styles.Paper}
      variant="outlined"
    >
      <Subtitle
        className={styles.Subtitle}
      >
        Представитель №1
      </Subtitle>
      <Input
        className={styles.Input}
        key="first_legal_fullname"
        label="Ф.И.О"
        name="first_legal_fullname"
        placeholder="Ф.И.О"
        error={error}
      />
      <DatePicker
        className={styles.Input}
        key="first_legal_birthday"
        label="Дата рождения"
        name="first_legal_birthday"
        placeholder="Дата рождения"
        error={error}
      />
      <Input
        className={styles.Input}
        key="first_legal_position"
        label="Кем является"
        name="first_legal_position"
        placeholder="Кем является"
        error={error}
      />
      <Input
        className={styles.Input}
        key="first_legal_address"
        label="Адрес фактического проживания"
        name="first_legal_address"
        placeholder="Адрес фактического проживания"
        error={error}
      />
      <Input
        className={styles.Input}
        key="first_legal_phone"
        label="Телефон"
        name="first_legal_phone"
        placeholder="Телефон"
        error={error}
      />
      <Input
        className={styles.Input}
        key="first_legal_registration_address"
        label="Адрес регистрации"
        name="first_legal_registration_address"
        placeholder="Адрес регистрации"
        error={error}
      />
    </Paper>
    <Paper
      className={styles.Paper}
      variant="outlined"
    >
      <Subtitle
        className={styles.Subtitle}
      >
        Представитель №2
      </Subtitle>
      <Input
        className={styles.Input}
        key="second_legal_fullname"
        label="Ф.И.О"
        name="second_legal_fullname"
        placeholder="Ф.И.О"
        error={error}
      />
      <DatePicker
        className={styles.Input}
        key="second_legal_birthday"
        label="Дата рождения"
        name="second_legal_birthday"
        placeholder="Дата рождения"
        error={error}
      />
      <Input
        className={styles.Input}
        key="second_legal_position"
        label="Кем является"
        name="second_legal_position"
        placeholder="Кем является"
        error={error}
      />
      <Input
        className={styles.Input}
        key="second_legal_address"
        label="Адрес фактического проживания"
        name="second_legal_address"
        placeholder="Адрес фактического проживания"
        error={error}
      />
      <Input
        className={styles.Input}
        key="second_legal_phone"
        label="Телефон"
        name="second_legal_phone"
        placeholder="Телефон"
        error={error}
      />
      <Input
        className={styles.Input}
        key="second_legal_registration_address"
        label="Адрес регистрации"
        name="second_legal_registration_address"
        placeholder="Адрес регистрации"
        error={error}
      />
    </Paper>
    <H5
      className={styles.H5}
    >
      Значимые для ребенка люди
    </H5>
    <Paper
      className={styles.Paper}
      variant="outlined"
    >
      <Subtitle
        className={styles.Subtitle}
      >
        Данные о других детях в семье
      </Subtitle>
      <Table
        className={styles.Table}
        key="other_children"
        name="other_children"
        label="Дети"
        placeholder="Дети"
        creatable
        editable
        columns={[
          {
            Header: 'ФИО',
            accessor: 'fullname',
            component: BaseInput,
            input: {
              className: styles.Input,
            }
          },
          {
            Header: 'Дата рождения',
            accessor: 'birthday',
            component: BaseDatePicker,
            input: {
              className: styles.Input,
            }
          },
          {
            Header: 'Адрес фактического проживания',
            accessor: 'address',
            component: BaseInput,
            input: {
              className: styles.Input,
              multiline: true,
              rowsMax: 6,
              rows: 4,
              fullWidth: true
            }
          },
        ]}
        error={error}
      />
    </Paper>
    <Paper
      className={styles.Paper}
      variant="outlined"
    >
      <Subtitle
        className={styles.Subtitle}
      >
        Другие значимые для ребенка люди: (родственники, соседи, друзья семьи и т. д.)
      </Subtitle>
      <Table
        className={styles.Table}
        key="other_significant_people"
        name="other_significant_people"
        label="Родственники, соседи, друзья семьи и т. д."
        placeholder="Родственники, соседи, друзья семьи и т. д."
        creatable
        editable
        columns={[
          {
            Header: 'ФИО',
            accessor: 'fullname',
            component: BaseInput,
            input: {
              className: styles.Input,
            }
          },
          {
            Header: 'Кем приходится',
            accessor: 'status',
            component: BaseInput,
            input: {
              className: styles.Input,
            }
          },
          {
            Header: 'Адрес проживания ',
            accessor: 'address',
            component: BaseInput,
            input: {
              className: styles.Input,
              multiline: true,
              rowsMax: 6,
              rows: 4,
              fullWidth: true
            }
          },
          {
            Header: 'Контакты',
            accessor: 'contacts',
            component: BaseInput,
            input: {
              className: styles.Input,
              fullWidth: true
            }
          },
        ]}
        error={error}
      />
    </Paper>
    <H5
      className={styles.H5}
    >
      Причины проведения оценки ребенка вашей организацией
    </H5>
    <Paper
      className={styles.Paper}
      variant="outlined"
    >
      <Subtitle
        className={styles.Subtitle}
      >
        1. Направляющий орган / учреждение, причина направления и ожидаемые результаты (помощь)
      </Subtitle>
      <Caption>
        (укажите, откуда получено направление / информация, какова причина обращения и запрос):
      </Caption>
      <Input
        className={styles.Input}
        key="assessment_reasons[0]"
        name="assessment_reasons[0]"
        multiline
        rows={6}
        fullWidth
        error={error}
      />
      <Subtitle
        className={styles.Subtitle}
      >
        2. Точка зрения ребенка и родителей по поводу данного направления:
      </Subtitle>
      <Input
        className={styles.Input}
        key="assessment_reasons[1]"
        name="assessment_reasons[1]"
        multiline
        rows={6}
        fullWidth
        error={error}
      />
      <Subtitle
        className={styles.Subtitle}
      >
        3. Причины проведения оценки вашей организацией:
      </Subtitle>
      <Input
        className={styles.Input}
        key="assessment_reasons[2]"
        name="assessment_reasons[2]"
        multiline
        rows={6}
        fullWidth
        error={error}
      />
    </Paper>
    <H5
      className={styles.H5}
    >
      Образовательные учреждения, которые посещает ребенок
    </H5>
    <Paper
      className={styles.Paper}
      variant="outlined"
    >
      <Table
        className={styles.Table}
        key="educational_institutions"
        name="educational_institutions"
        label="Образовательные учреждения"
        placeholder="Образовательные учреждения"
        creatable
        editable
        columns={[
          {
            Header: 'Тип и номер учреждения',
            accessor: 'type',
            component: BaseInput,
            input: {
              className: styles.Input,
              fullWidth: true
            }
          },
          {
            Header: 'Адрес',
            accessor: 'address',
            component: BaseInput,
            input: {
              className: styles.Input,
              multiline: true,
              rowsMax: 6,
              rows: 4,
              fullWidth: true
            }
          },
          {
            Header: 'Контактное лицо (Ф.И.О., должность)',
            accessor: 'fullname',
            component: BaseInput,
            input: {
              className: styles.Input,
              fullWidth: true
            }
          },
          {
            Header: 'Контакты',
            accessor: 'contacts',
            component: BaseInput,
            input: {
              className: styles.Input,
              fullWidth: true
            }
          },
        ]}
        error={error}
      />
    </Paper>
    <H5
      className={styles.H5}
    >
      Даты контактов специалистов с семьей
    </H5>
    <Paper
      className={styles.Paper}
      variant="outlined"
    >
      <Table
        className={styles.Table}
        key="contacts_log"
        name="contacts_log"
        label="Даты"
        placeholder="Даты"
        creatable
        editable
        columns={[
          {
            Header: 'Дата',
            accessor: 'date',
            component: BaseDatePicker,
            input: {
              className: styles.Input,
              fullWidth: true
            }
          },
          {
            Header: 'Член семьи, с которым произошел контакт',
            accessor: 'person',
            component: BaseInput,
            input: {
              className: styles.Input,
              multiline: true,
              rowsMax: 6,
              rows: 4,
              fullWidth: true
            }
          },
        ]}
        error={error}
      />
    </Paper>
    <H5
      className={styles.H5}
    >
      Другие специалисты, от которых получена информация о ребенке (медицинские работники, специалисты по социальной работе, воспитатели, учителя, родственники, соседи, друзья семьи и т. д.)
    </H5>
    <Paper
      className={styles.Paper}
      variant="outlined"
    >
      <Table
        className={styles.Table}
        key="other_specialists"
        name="other_specialists"
        label="Другие специалисты"
        placeholder="Другие специалисты"
        creatable
        editable
        columns={[
          {
            Header: 'Организация',
            accessor: 'organization',
            component: BaseInput,
            input: {
              className: styles.Input,
              fullWidth: true
            }
          },
          {
            Header: 'Ф.И.О.',
            accessor: 'fullname',
            component: BaseInput,
            input: {
              className: styles.Input,
              fullWidth: true
            }
          },
          {
            Header: 'Контактная информация',
            accessor: 'contacts',
            component: BaseInput,
            input: {
              className: styles.Input,
              fullWidth: true
            }
          },
        ]}
        error={error}
      />
    </Paper>
    <H5
      className={styles.H5}
    >
      Производилась ли оценка / диагностика ребенка другими специалистами или организациями (СПб ЕФО или другая)
    </H5>
    <Paper
      className={styles.Paper}
      variant="outlined"
    >
      <Input
        className={styles.Input}
        key="other_organisations_evaluation_pages"
        name="other_organisations_evaluation_pages"
        label="Приложено листов"
        placeholder="Приложено листов"
        error={error}
      />
      <Table
        className={styles.Table}
        key="other_organisations_evaluation_review"
        name="other_organisations_evaluation_review"
        label="Дата оценки/краткий обзор информации, полученной от других специалистов/организаций"
        placeholder="Дата оценки/краткий обзор информации, полученной от других специалистов/организаций"
        creatable
        editable
        columns={[
          {
            Header: 'Дата оценки',
            accessor: 'data',
            component: BaseDatePicker,
            input: {
              className: styles.Input,
              fullWidth: true
            }
          },
          {
            Header: 'Краткий обзор информации, полученной от других специалистов/организаций',
            accessor: 'description',
            component: BaseInput,
            input: {
              className: styles.Input,
              multiline: true,
              rowsMax: 6,
              rows: 4,
              fullWidth: true
            }
          },
        ]}
        error={error}
      />
    </Paper>
    <H5
      className={styles.H5}
    >
      Изложите кратко все известные вам обстоятельства жизни ребенка, начиная с рождения до настоящего времени
    </H5>
    <Paper
      className={styles.Paper}
      variant="outlined"
    >
      <Input
        className={styles.Input}
        key="life_circumstances"
        name="life_circumstances"
        label="Обстоятельства жизни ребенка"
        placeholder="Обстоятельства жизни ребенка"
        multiline
        rowsMax={6}
        rows={4}
        error={error}
        fullWidth
      />
    </Paper>
  </div>
);

const MAIN = {
  id: uuidv4(),
  name: 'Основная информация',
  data: DATA,
};

export default MAIN;
